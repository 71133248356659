import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: []
})

export class LoadingSpinnerComponent {
  @Input()
  loadingMessage: string;

  @Input()
  spinnerSize: string;
}