<div class="alert-container">
  <uitk-page-notification uitkId="error-msg-example" [(visible)]="isErrorVisible" pageNotificationType="error"
    triggerElementId="error-msg-trigger" (visibleChange)="onClose($event)" animationType="fadeAnimation">
    <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
  </uitk-page-notification>
  <uitk-page-notification uitkId="warning-msg-example" [(visible)]="isWarningVisible" pageNotificationType="warning"
    triggerElementId="warning-msg-trigger" (visibleChange)="onClose($event)" animationType="fadeAnimation">
    <ng-container *ngIf="isSelfRegistration === true; else notificationTemplate">
      <app-self-registration-form></app-self-registration-form>
    </ng-container>
  </uitk-page-notification>
  <uitk-page-notification 
    uitkId="warning-msg-example" 
    [(visible)]="isWarningVisible" 
    pageNotificationType="warning" 
    triggerElementId="warning-msg-trigger" 
    (visibleChange)="onClose($event)" 
    animationType="fadeAnimation"
    >
    </uitk-page-notification>
  <ng-template #notificationTemplate>
    <span [innerHTML]="notificationContent"></span>
  </ng-template>
</div>