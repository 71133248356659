import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

import { routeTransitionAnimations } from './route-transition-animations';
import { GlobalConstants } from './shared/constants/GlobalConstants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})

export class AppComponent {
  animationStateName: string;
  
  constructor (private readonly router: Router) {
    this.animationStateName = GlobalConstants.ANIMATION_STATE_NAME;
  }

  prepareRoute(outlet: RouterOutlet): void {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[this.animationStateName];
  }
}