import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UITKLoadingIndicatorModule, UITKNotificationModule, UITKPageNotificationModule, UITKDialogModule, UITKDatePickerModule, UITKInputModule, UITKFormFieldService, UITKFileUploadModule} from '@uitk/angular';
import { ReactiveFormsModule } from '@angular/forms';

import { UserIdleModule } from 'angular-user-idle';
import { AlertComponent } from './alert/alert.component';
import { BannerComponent } from './banner/banner.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SelfRegistrationFormComponent } from './self-registration-form/self-registration-form.component';
import { RecordUploadComponent } from './record-upload/record-upload.component';

@NgModule({
  declarations: [AlertComponent, BannerComponent, LoadingSpinnerComponent, SelfRegistrationFormComponent, RecordUploadComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    UITKLoadingIndicatorModule,
    UITKFileUploadModule,
    UITKNotificationModule,
    UITKPageNotificationModule,
    UITKDatePickerModule,
    UITKInputModule,
    UITKDialogModule,
    UserIdleModule.forRoot({ idle: 1800, timeout: 1800 })
  ],
  providers: [UITKFormFieldService],
  exports: [AlertComponent, BannerComponent, LoadingSpinnerComponent, SelfRegistrationFormComponent, RecordUploadComponent]
})
export class SharedModule { }