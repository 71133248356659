import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import SendEmailData from '../models/SendEmailData';
import VerifyOTPData from '../models/VerifyOTPData';
import { HttpAuthService } from './http.auth.service';

@Injectable({
  providedIn: 'root'
})
export class SendEmailOtpService {
  emailUrl: string;

  constructor(
    private readonly authService: HttpAuthService,
    private readonly httpClient: HttpClient
  ) {
    this.emailUrl = environment.consentMgmt.preferenceUrl;
  }

  async sendEmailWithOtp(
    sendEmailData: SendEmailData,
    next: (value: HttpResponse<HttpResponse<string>>) => void,
    error?: (err: any) => void
  ): Promise<void> {
    const accessToken = await this.authService.getUserToken();

    this.httpClient
      .post<HttpResponse<string>>(`${this.emailUrl}/SendEmailWithOTP`, sendEmailData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          Authorization: `Bearer ${accessToken}`
        },
        observe: 'response',
        reportProgress: true
      })
      .pipe(
        map(res => res),
        catchError(err => throwError(err))
      )
      .subscribe({ next, error });
  }

  verifyOtp(verifyOtpData: VerifyOTPData): Observable<string> {
    return from(new Promise<string>(async (resolve, reject) => {
      const accessToken = await this.authService.getUserToken();

      const headers = new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
        Authorization: `Bearer ${accessToken}`
      });
      const requestOptions = { headers };
      this.httpClient
        .post<string>(`${this.emailUrl}/verifyEmailWithOTP`, verifyOtpData, requestOptions)
        .pipe(
          map(otpVerifiedMessage => otpVerifiedMessage),
          catchError(error => throwError(error))
        )
        .subscribe({
          next: resolve,
          error: reject
        });

    }));
  }
}
