import {
  UITKHeadingLevel,
  IUITKPaginationConfig,
  IUITKPaginationEntriesPerPage
} from '@uitk/angular';

import { SearchCriteria } from './SearchCriteria';
import SearchClaimPrepay from './SearchClaimPrepay';

export class ClaimListPaginationConfig {
  public static paginationConfig: IUITKPaginationConfig = {
    id: 'pagination-example',
    description: 'Pagination Example',
    currentPageNumber: 1,
    displayingPagesTextHeadingLevel: UITKHeadingLevel.h2
  };

  // pagination entries per page configuration
  public static entriesPerPage: IUITKPaginationEntriesPerPage = {
    pageSizeOptions: [{ value: 25, selected: true }, { value: 50 }, { value: 100 }]
  };

  public static claimNumber = 'Claim Number';

  public static patientAccountNumber = 'Patient Account Number';
  public static patientAccountNumberClassName = 'patient-account-number';

  public static recipientName = 'Patient Name';
  public static recipientNameClassName = 'patient-name';

  public static tin = 'TIN';
  public static medicalRecordRequestDate = 'Medical Record Request Date';
  public static patientDOB = 'Patient DOB';

  public static startDateOfService = 'Start Date of Service';
  public static startDateOfServiceClassName = 'start-date-of-service';

  public static actions = 'Actions';
  public static actionsClassName = 'actions';

  public static providerName = 'Provider Name';
  public static providerNameClassName = 'provider-name';

  public static documentInfo = 'Document Details';
  public static documentInfoClassName = 'document-info';

  public static tableHeader: Array<Object> = [
    { name: ClaimListPaginationConfig.claimNumber, id: 'claimNumber' },
    {
      name: ClaimListPaginationConfig.patientAccountNumber,
      id: 'patientAccountNumber',
      className: ClaimListPaginationConfig.patientAccountNumberClassName
    },
    { 
      name: ClaimListPaginationConfig.recipientName, 
      id: 'recipientLastName', //we want to keep this as Last name to make the filter still work
      className: ClaimListPaginationConfig.recipientNameClassName
    },
    { name: ClaimListPaginationConfig.tin, id: 'tin' },
    {
      name: ClaimListPaginationConfig.medicalRecordRequestDate,
      id: 'medicalRecordRequestDate',
      className: 'med-rec-request-date'
    },
    { name: ClaimListPaginationConfig.patientDOB, id: 'patientDoB' },
    {
      name: ClaimListPaginationConfig.startDateOfService,
      id: 'startDateOfService',
      className: ClaimListPaginationConfig.startDateOfServiceClassName
    },
    {
      name: ClaimListPaginationConfig.providerName,
      id: 'providerName',
      className: ClaimListPaginationConfig.providerNameClassName
    },
    { 
      name: ClaimListPaginationConfig.actions,
      id: 'actions',
      className: ClaimListPaginationConfig.actionsClassName
    },
    { 
      name: ClaimListPaginationConfig.documentInfo,
      id: 'documentInfo', 
      className: ClaimListPaginationConfig.documentInfoClassName
    }
  ];

  static defaultSearchCriteria: SearchCriteria = {
    pageNumber: '1',
    paginationSize: '25',
    clientName: '',
    tins: [],
    letterType: "I",
    mrStatus: [0]
  };

  static defaultSearchRequestData: SearchClaimPrepay = {
    clientName: '',
    pageNumber: 1,
    paginationSize: 25,
    tins: [],
    mrStatus: [0]
  };

  static defaultAllClaimsSearchCriteria: SearchCriteria = {
    pageNumber: '1',
    paginationSize: '25',
    clientName: '',
    tins: [],
    mrStatus: [0,1]
  };
}