import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertService } from './abstracts/alert.service';
import { Alert } from '../models/Alert';
import { MessageType } from '../models/MessageType';

@Injectable()
export class CpAlertService implements AlertService {
  private readonly subject: Subject<Alert> = new Subject<Alert>();
  public alertSubject$ = this.subject.asObservable();
  
  private broadcast(severity: MessageType, summary: string, detail: string): void {
    this.subject.next({ severity, summary, detail });
  }

  showError(summary: string, detail: string): void {
    this.broadcast(MessageType.Error, summary, detail);
  }

  showWarning(summary: string, detail: string): void {
    this.broadcast(MessageType.Warning, summary, detail);
  }
}