import { GlobalConstants } from "../constants/GlobalConstants";
import FileContents from "../models/FileContents";

/**
 * Converts a base64-encoded string to a Blob.
 *
 * @param base64Data The base64-encoded string to convert.
 * @param contentType The content type of the resulting Blob. Default is 'application/octet-stream'.
 * @returns A Blob containing the decoded binary data.
 */
export function base64toBlob(base64Data: string, contentType: string = 'application/octet-stream'): Blob {
    // const sliceSize = 512;
    const byteCharacters = atob(base64Data.replace(/\s/g, ''));

    const buffer = new ArrayBuffer(byteCharacters.length);
    const byteArray = new Uint8Array(buffer);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
    }
  
    return new Blob([byteArray], { type: contentType });
}

/**
 * Triggers the download of a Blob by creating a temporary link element.
 *
 * @param blob The Blob to download.
 * @param fileName The desired file name for the download.
 */
export function downloadBlob(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.style.display = 'none';
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
    window.URL.revokeObjectURL(url);
}

/**
 * Combines an array of chunks into a single base64 string.
 *
 * @param chunks Array of chunks containing 'chunkFileData' properties.
 * @returns A combined base64 string.
 */
export function combineChunks(chunks: FileContents[]): string {
  if (chunks && chunks.length > 0) {
    return chunks.map(chunk => chunk.chunkFileData).join('');
  }

  return '';
}

/**
 * Formats the given number of bytes into a human-readable string with specified decimals.
 *
 * @param bytes The number of bytes to format.
 * @param decimals The number of decimal places in the result (default is 2).
 * @return A formatted string representing the file size.
 */
export function formatBytes(bytes: number | string, decimals: number = 2): string {
    if (typeof bytes === 'string' || Number.isNaN(Number(bytes)) || bytes === undefined) {
      return GlobalConstants.FILE_SIZE_PLACEHOLDER;
    }
    if (bytes === 0) {
      return GlobalConstants.ZERO_BYTES_TEXT;
    }
    const k: number = 1024;
    const dm: number = decimals <= 0 ? 0 : decimals;
    const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i: number = Math.floor(Math.log(Number(bytes)) / Math.log(k));
    return `${parseFloat((Number(bytes) / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}