import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { GuardianService } from "../services/abstracts/guardian.service";

@Injectable({
    providedIn: 'root'
})
export class RegisteredGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        protected readonly guardianService: GuardianService
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot
    ) {
        let isRegisteredUser: boolean = false;
        let isRegisteredRoute = route.data.isRegisteredRoute;
        await this.guardianService.getGuardianData().then((res) => {
            if(Array.isArray(res)) {
                isRegisteredUser = true;
            }
        }
        ).catch(error => {
            throw (error);
        });
        // If user is unregistered and route in registered, redirect to unregistered route
        if(!isRegisteredUser && isRegisteredRoute) {
            if(this.router.url === '/upload') {
                this.router.navigate(['unregistered/record-upload']);
            } else {
                this.router.navigate(['unregistered']);
            }
            return false;
        }

        // If user is registered and route is unregistered, redirect to registered route
        if(isRegisteredUser && !isRegisteredRoute) {
            if(this.router.url === '/upload') {
                this.router.navigate(['registered/record-upload']);
            } else {
                this.router.navigate(['registered']);
            }
            return false;
        }
        // If user is registered and route is registered, allow access
        // If user is unregistered and route is unregistered, allow access
        return true;
    }
}