import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Route, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export function isFeatureEnabled(env: typeof environment, featureName: string): boolean {
  return featureName in env.feature_flags && env.feature_flags[featureName] === true;
}

export function FeatureGuard(featureName: string): CanActivateFn {
  return (ars: ActivatedRouteSnapshot, rss: RouterStateSnapshot) => {
    return isFeatureEnabled(environment, featureName);
  } 
}