<div class="banner-container">
  <uitk-banner-notification *ngIf="isIdleTimeOut && isLoggedIn"
    uitkId="bannerId"
    [variant]="notificationType"
    [show]="true">
      <h1 uitkBannerHeading>{{ inactiveMessage }}</h1>
  </uitk-banner-notification>
  <uitk-banner-notification *ngIf="isClaimsHome && isLoggedIn"
    uitkId="bannerId"
    [variant]="notificationType"
    [show]="isNotificationVisible"
    [displayButton]="true"
    [buttonText]="closeButtonText"
    (onButtonClick)="handleButtonClick($event)">
      <p class="obfuscate">
        <span>{{ helpLineMsg }}
          <span>{{ emailUserName }}</span>
          <span>{{ emailSymbol }}</span>
          <span>{{ emailDomainName }}</span>
          <span>{{ emailDomain }}</span>
        </span>
      </p>
      <h1 uitkBannerHeading>{{ pdfNotificationMess }}
        <a class="databank-hyperlink" [href]="dataBankUrl" target="_blank">{{ hyperLinkText }}</a>
      </h1>  
  </uitk-banner-notification>
  <uitk-banner-notification *ngIf="isSuccessBanner"
  [show]="isNotificationVisible"
  uitkId="demoId"
  variant="success"
  [displayButton]="true"
  [buttonText]="closeButtonText"
  (onButtonClick)="handleButtonClick($event)"
  >
    <h1 uitkBannerHeading>{{ message }}</h1>
  </uitk-banner-notification>
  <uitk-banner-notification *ngIf="isErrorBanner"
  [show]="isNotificationVisible"
  uitkId="demoId"
  variant="error"
  [displayButton]="true"
  [buttonText]="closeButtonText"
  (onButtonClick)="handleButtonClick($event)"
  >
    <h1 uitkBannerHeading>{{ message }}</h1>
  </uitk-banner-notification>
  <uitk-banner-notification *ngIf="isInfoBanner"
  [show]="isNotificationVisible"
  uitkId="demoId"
  variant="info"
  [displayButton]="true"
  [buttonText]="closeButtonText"
  (onButtonClick)="handleButtonClick($event)"
  >
    <h1 uitkBannerHeading>{{ message }}</h1>
  </uitk-banner-notification>
  <uitk-banner-notification *ngIf="isWarningBanner"
  [show]="isNotificationVisible"
  uitkId="demoId"
  variant="warning"
  [displayButton]="true"
  [buttonText]="closeButtonText"
  (onButtonClick)="handleButtonClick($event)"
  >
    <h1 uitkBannerHeading>{{ message }}</h1>
  </uitk-banner-notification>
</div>