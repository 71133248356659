<uitk-navigation-primary-horizontal *ngIf="displayNavBar">
  <uitk-navigation-panel>
    <uitk-navigation-panel-item>
      <a class="primary-navigation-item" uitkNavRoute routerLink="registered/home"> {{ homeNavName }} </a>
    </uitk-navigation-panel-item>
    <uitk-navigation-panel-item>
      <a uitkNavRoute *ngIf="isTabEnabled('upload-document')" routerLink="/verify-files-info"
        class="primary-navigation-item"> {{ uploadDocumentNavName }} </a>
    </uitk-navigation-panel-item>
    <uitk-navigation-panel-item>
      <a uitkNavRoute *ngIf="isTabEnabled('faqs')" routerLink="/faqs" class="primary-navigation-item"> {{ faqsNavName }}
      </a>
    </uitk-navigation-panel-item>
    <uitk-navigation-panel-item>
      <a uitkNavRoute *ngIf="isTabEnabled('contact')" routerLink="/contact" class="primary-navigation-item">
        {{ contactNavName }} </a>
    </uitk-navigation-panel-item>
  </uitk-navigation-panel>
</uitk-navigation-primary-horizontal>