import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UITKTableFeaturesModule, 
  UITKTableModule, 
  UITKPaginationModule, 
  UITKDialogModule, 
  UITKFieldsetModule, 
  UITKNotificationModule, 
  UITKUtilitiesModule,
  UITKDropdownModule,
  UITKInputModule,
  UITKLoadingIndicatorModule,
  UITKTooltipModule} from '@uitk/angular';

import { ClaimListComponent } from './claim-list/claim-list.component';
import { FilesModule } from '../files/files.module';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../shared/guards/auth.guard';
import { DocumentInfoModule } from '../document-info/document-info.module';

const routes: Routes = [
  {
    path: 'home',
    pathMatch: 'full',
    component: ClaimListComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [ClaimListComponent],
  imports: [
    HttpClientModule,
    FormsModule,
    CommonModule,
    UITKTableModule,
    ReactiveFormsModule,
    UITKNotificationModule,
    UITKFieldsetModule,
    UITKTableFeaturesModule,
    UITKPaginationModule,
    UITKDialogModule,
    UITKUtilitiesModule,
    RouterModule.forChild(routes),
    SharedModule,
    FilesModule,
    UITKTooltipModule,
    UITKDropdownModule,
    UITKInputModule,
    DocumentInfoModule
  ],
  exports: [RouterModule]
})
export class ClaimsModule { }