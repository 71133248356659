import { Component } from '@angular/core';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: []
})
export class ClientViewComponent {

}
