import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalConstants } from '../constants/GlobalConstants';
import { AlertService } from '../services/abstracts/alert.service';
import { AuthService } from '../services/abstracts/auth.service';
import { MessageType } from '../models/MessageType';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: []
})

export class AlertComponent implements OnInit {
  notificationContent: string;
  isWarningVisible: boolean;
  isErrorVisible: boolean;
  isSelfRegistration: boolean;
  subscription: Subscription;

  constructor(
    private readonly alertService: AlertService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
    this.isErrorVisible = false;
    this.isWarningVisible = false;
  }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
    this.subscription = this.alertService.alertSubject$.subscribe(alert => {
      this.formatDetail(alert.detail);
      this.isSelfRegistration = alert.detail === GlobalConstants.SELF_REGISTRATION_REDIRECT ? true : false;
      switch (alert.severity) {
        case MessageType.Warning:
          this.isWarningVisible = true;
          break;
        case MessageType.Error:
          this.isErrorVisible = true;
          break;
        default:
          this.isErrorVisible = false;
          this.isWarningVisible = false;
      }
      this.changeDetector.detectChanges();
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/logout') {
          this.isErrorVisible = false;
          this.isWarningVisible = false;
        }
      }
    });
    this.changeDetector.detectChanges();
  }

  formatDetail(content: string): void {
    this.notificationContent = `${content}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onClose(event: Event): Promise<void> {
    if (await this.authService.isAuthenticated() === true) {
      this.authService.logout();
    } else {
      this.router.navigate(['/']);
    }
  }
}