import { IUITKPaginationConfig, UITKHeadingLevel, IUITKPaginationEntriesPerPage } from '@uitk/angular';

import { GlobalConstants } from '../constants/GlobalConstants';
import { SearchCriteriaDocumentsInfo } from "../models/SearchCriteriaDocumentsInfo";

export class DocumentInfoConfig {
    public static paginationConfig: IUITKPaginationConfig = {
        id: 'pagination-example',
        description: 'Pagination Example',
        currentPageNumber: 1,
        displayingPagesTextHeadingLevel: UITKHeadingLevel.h2
    };

    // pagination entries per page configuration
    public static entriesPerPage: IUITKPaginationEntriesPerPage = {
        pageSizeOptions: [{ value: 25, selected: true }, { value: 50 }, { value: 100 }]
    };

    public static tableHeader: Array<Object> = [
        { 
            name: GlobalConstants.DOCUMENT_INFO_TABLE_HEADING_CLASSIFIER,
            id: 'classifier',
            className: 'document-classifier'
        },
        { 
            name: GlobalConstants.DOCUMENT_INFO_TABLE_HEADING_FILE_NAME,
            id: 'documentName',
            className: 'document-name'
        },
        { 
            name: GlobalConstants.DOCUMENT_INFO_TABLE_HEADING_DATE_ADDED,
            id: 'documentDateAdded',
            className: 'document-date'
        },
        { 
            name: GlobalConstants.DOCUMENT_INFO_TABLE_HEADING_DOWNLOAD,
            id: 'download',
            className: 'download'
        }
    ];

    static defaultSearchCriteriaDocumentInfo: SearchCriteriaDocumentsInfo = {
        clientName: '',
        claimId: '',
        uploadId: []
    };
}