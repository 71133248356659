import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UITKPanelModule, UITKRadioGroupModule, UITKDropdownModule, UITKFieldsetModule, UITKInputModule, UITKCheckboxModule,
  UITKWizardModule, UITKTabbedPanelsModule, UITKNavigationModule, UITKBreadcrumbModule, UITKFormsModule, 
  UITKNotificationModule} from '@uitk/angular';

import { ClientSelectionComponent } from './client-selection/client-selection.component';
import { LogoutComponent } from './logout/logout.component';
import { ProviderPreferenceComponent } from './provider-preference/provider-preference.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { RolesGuard } from '../shared/guards/roles.guard';
import { SharedModule } from '../shared/shared.module';
import { EditPreferenceComponent } from './edit-preference/edit-preference.component';
import { ClientViewComponent } from './client-view/client-view.component';
import { UnregisteredComponent } from './unregistered/unregistered.component';
import { RecordUploadComponent } from '../shared/record-upload/record-upload.component';
import { HomeComponent } from './authorized/home/home.component';
import { FaqComponent } from './authorized/faq/faq.component';
import { SelfRegistrationFormComponent } from '../shared/self-registration-form/self-registration-form.component';
import { RegisteredComponent } from './registered/registered.component';
import { ClaimListComponent } from '../claims/claim-list/claim-list.component';
import { FeatureGuard } from '../shared/guards/feature.guard';
import { RegisteredGuard } from '../shared/guards/registered.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: WelcomeComponent,
    data: { animationState: 'One' }
  },
  { path: 'logout', pathMatch: 'full', component: LogoutComponent },
  { path: 'registered', component: RegisteredComponent, children: [
    {
      path: '',
      pathMatch: 'full',
      component: WelcomeComponent,
      data: { animationState: 'One' }
    },
    {
      path: 'client-selection',
      pathMatch: 'full',
      component: ClientSelectionComponent,
      canActivate: [AuthGuard],
      data: { animationState: 'Four' }
    },
    {
      path: 'home',
      pathMatch: 'full',
      component: ClaimListComponent,
      canActivate: [AuthGuard]
    },
    // {
    //   path: 'faq',
    //   pathMatch: 'full',
    //   component: FaqComponent, 
    // },
    {
      path: 'record-upload',
      pathMatch: 'full',
      component: RecordUploadComponent,
      canActivate: [FeatureGuard('recordUpload')],
    },
  ], canActivate: [AuthGuard, RegisteredGuard], data: { isRegisteredRoute: true } },
  { path: 'unregistered', children: [ // child route components that the router renders
    {
      path: '', 
      pathMatch: 'full',
      component: HomeComponent, 
    },
    // {
    //   path: 'faq',
    //   pathMatch: 'full',
    //   component: FaqComponent, 
    // },
    {
      path: 'record-upload',
      pathMatch: 'full',
      component: RecordUploadComponent, 
    },
    {
      path: 'self-registration',
      pathMatch: 'full',
      component: SelfRegistrationFormComponent, 
    },
  ], component: UnregisteredComponent, canActivate: [AuthGuard, RegisteredGuard], data: { isRegisteredRoute: false} },
  { path: 'provider-preference', pathMatch: 'full', component: ProviderPreferenceComponent, canActivate: [AuthGuard] },
  { path: 'edit-preference', pathMatch: 'full', component: EditPreferenceComponent, canActivate: [AuthGuard] },
  { path: 'client-view', pathMatch: 'full', component: ClientViewComponent, canActivate: [AuthGuard, RolesGuard], data: { requiredRoles: ['Users.Read'] } },
  { path: 'upload', pathMatch: 'full', component: WelcomeComponent, canActivate: [AuthGuard]}

];

@NgModule({
  declarations: [ClientSelectionComponent, LogoutComponent, WelcomeComponent, ProviderPreferenceComponent, EditPreferenceComponent, ClientViewComponent, UnregisteredComponent, HomeComponent, FaqComponent, RegisteredComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    UITKDropdownModule,
    UITKFieldsetModule,
    UITKNavigationModule,
    UITKNotificationModule, 
    UITKWizardModule,
    UITKInputModule,
    UITKBreadcrumbModule,
    UITKPanelModule,
    UITKCheckboxModule,
    UITKRadioGroupModule,
    UITKTabbedPanelsModule,
    UITKFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule]
})
export class AccountModule { }