import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UITKFieldsetModule, UITKDialogModule, UITKFileUploadModule, UITKNotificationModule, UITKFormFieldService, UITKLoadingIndicatorModule } from '@uitk/angular';

import { FilesProgressComponent } from "./files-progress/files-progress.component";
import { UploadFilesComponent } from "./upload-files/upload-files.component";
import { DndDirective } from '../shared/directives/dnd.directive';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    DndDirective,
    UploadFilesComponent,
    FilesProgressComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    UITKLoadingIndicatorModule,
    ReactiveFormsModule,
    UITKFieldsetModule,
    HttpClientModule,
    CommonModule,
    UITKDialogModule,
    SharedModule,
    UITKFileUploadModule,
    UITKNotificationModule,
  ],
  providers: [UITKFormFieldService],
  exports: [RouterModule, UploadFilesComponent, FilesProgressComponent]
})
export class FilesModule { }