import { Component } from '@angular/core';

import { GlobalConstants } from '../../shared/constants/GlobalConstants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  myFooterText: string;

  constructor() {
    this.myFooterText = GlobalConstants.FOOTER_TEXT;
  }
}
