import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AlertService } from '../services/abstracts/alert.service';
import { ErrorService } from '../services/error.service';
import { GlobalConstants } from '../constants/GlobalConstants';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHelpers implements ErrorHandler {
  noUserFoundMessage: string;
  /* 
  Error Handling should be loaded first 
  so we need to manually inject the services with Injector
  */
  constructor (private readonly injector: Injector, private router: Router
    ) {
    this.noUserFoundMessage = GlobalConstants.ERROR_NO_USER_FOUND;
  }

  handleError(errorResponse): void {
    console.log('ERRORHELPER:', errorResponse);
    const errorService = this.injector.get(ErrorService);
    const alertService = this.injector.get(AlertService);
    let errorMessage: string;
    if (errorResponse && errorResponse?.error?.nameValuePairs?.error === this.noUserFoundMessage && this.router.url === '/upload') {
      this.router.navigate(['unregistered/record-upload']);
    } else if (errorResponse && errorResponse?.error?.nameValuePairs?.error === this.noUserFoundMessage) {
      errorMessage = GlobalConstants.SELF_REGISTRATION_REDIRECT;
      this.router.navigate(['unregistered']);
    } else if (errorResponse && this.isUnauthorized(errorResponse)) {
      errorMessage = `${GlobalConstants.UNAUTHORIZED_ERROR_MESSAGE}\n${GlobalConstants.REQUEST_ACCESS_MESSAGE}`;
      alertService.showWarning(GlobalConstants.UNAUTHORIZED_ERROR_TEXT, errorMessage);
    } else if (errorResponse && errorResponse.error instanceof ErrorEvent) {
      errorMessage = errorService.getClientMessage(errorResponse.error);
      alertService.showError(GlobalConstants.CLIENT_SIDE_ERROR_TEXT, errorMessage);
    }  else {
      errorMessage = errorService.getServerMessage(errorResponse);
      alertService.showError(GlobalConstants.SERVER_SIDE_ERROR_TEXT, errorMessage);
    }
  }

  private isUnauthorized(errorResponse: HttpErrorResponse): boolean {
    return (
      errorResponse &&
      errorResponse.error &&
      errorResponse.error.map &&
      errorResponse.error.map.error &&
      (errorResponse.error.map.error === GlobalConstants.UNAUTHORIZED_GUARDIAN_MESSAGE || GlobalConstants.RELATION_ID_NOT_PRESENT_MESSAGE) &&
      (errorResponse.status && errorResponse.status === 400)
    );
  }
}