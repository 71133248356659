import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

export function initializeSentinel(
  sentinel: KeycloakService
) {
  return () =>
    sentinel.init({
      config: environment.sentinel,
      initOptions: {
        checkLoginIframe: false
      }
    });
}
