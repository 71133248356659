import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../shared/constants/GlobalConstants';
import { base64toBlob, downloadBlob, formatBytes } from '../shared/helpers/FileDownloadHelper';
import Claim from '../shared/models/Claim';
import DocumentInfo from '../shared/models/DocumentInfo';
import { DocumentInfoConfig } from '../shared/models/DocumentInfoConfig';
import FileDownloadRequestData from '../shared/models/FileDownloadRequestData';
import { SearchCriteriaDocumentsInfo } from '../shared/models/SearchCriteriaDocumentsInfo';
import { AuthService } from '../shared/services/abstracts/auth.service';
import { ClientService } from '../shared/services/client.service';
import { CpMrUploadService } from '../shared/services/cp-mr-upload.service';
import { DocumentDownloadService } from '../shared/services/document-download.service';
import { DocumentInfoService } from '../shared/services/document-info.service';

@Component({
    selector: 'app-document-info',
    templateUrl: './document-info.component.html',
    styleUrls: ['./document-info.component.scss']
})

export class DocumentInfoComponent implements OnInit {
  isCloseButtonEnabled: boolean;
  patientAccountNumberText: string;
  claimNumberText: string;
  isDocInfoDialogVisible: boolean = false;
  isLoggedIn: boolean;
  selectedClaim: Claim;
  subscription: Subscription;
  tableHeader: Array<Object>;
  tableDetail: DocumentInfo[];
  searchCriteriaDocumentInfo: SearchCriteriaDocumentsInfo;
  patientName: string;
  documentsText: string;
  documentDetailsText: string;
  isCloseEnabled: boolean;
  sessionStorage: Storage;
  transferStatusKey: string;
  dataTransferringStatus: string;
  dataTranferredStatus: string;
  onDownloadClick: boolean;
  isFileDownloaded: boolean;
  isErrorStatus: boolean;
  isFileDownloading: boolean;
  fileSize: number | string;
  closeDownloadBox: boolean;
  errorMessage: string;
  errorBoxVisible: boolean;
  selectedFileName: string;
  downloadingString: string;
  downloadedString: string;
  closeButtonText: string;
  sizeText: string;
  spinnerSize: string;
  documentLoadingMessage: string;
  isLoading: boolean;
  hasDocuments: boolean;
  NoDocMessage: string;
  downloadInProgressMessage: string;
  isFileLarge: boolean;
  largeFileDownloadMessage: string;

  constructor (
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly documentInfoService: DocumentInfoService,
    private readonly authService: AuthService,
    private readonly cpMrUploadService: CpMrUploadService,
    private readonly clientService: ClientService,
    private readonly documentDownloadService: DocumentDownloadService
  ) {
    this.isCloseButtonEnabled = true;
    this.patientAccountNumberText = GlobalConstants.PATIENT_AC_NUMBER_TEXT;
    this.documentsText = GlobalConstants.DOCUMENTS_TEXT;
    this.patientName = GlobalConstants.PATIENT_AC_NAME_TEXT;
    this.claimNumberText = GlobalConstants.CLAIM_NUMBER_TEXT;
    this.isDocInfoDialogVisible = false;
    (async () => { this.isLoggedIn = await this.authService.isAuthenticated(); }) ();
    this.searchCriteriaDocumentInfo = DocumentInfoConfig.defaultSearchCriteriaDocumentInfo;
    this.tableHeader = DocumentInfoConfig.tableHeader;
    this.documentDetailsText = GlobalConstants.DOCUMENT_DETAILS_TEXT; // not displaying.. check
    this.isCloseEnabled = false;
    this.sessionStorage = window.sessionStorage;
    this.transferStatusKey = GlobalConstants.TRANSFER_STATUS_FOR_IDLE_CHECK_KEY;
    this.dataTransferringStatus = GlobalConstants.DATA_TRANSFERING_STATUS_FOR_IDLE_CHECK;
    this.dataTranferredStatus = GlobalConstants.DATA_TRANSFERRED_STATUS_FOR_IDLE_CHECK;
    this.onDownloadClick = false;
    this.isFileDownloaded = false;
    this.isErrorStatus = false;
    this.isFileDownloading = false;
    this.fileSize = GlobalConstants.FILE_SIZE_PLACEHOLDER;
    this.closeDownloadBox = true;
    this.errorMessage = GlobalConstants.DOWNLOAD_ERROR_MESSAGE;
    this.errorBoxVisible = false;
    this.selectedFileName = '';
    this.downloadingString = GlobalConstants.DOWNLOADING_STRING;
    this.downloadedString = GlobalConstants.DOWNLOADED_STRING;
    this.closeButtonText = GlobalConstants.CLOSE_BUTTON_TEXT;
    this.sizeText = GlobalConstants.SIZE_WITH_COLON_TEXT;
    this.spinnerSize = GlobalConstants.SPINNER_SIZE_SMALL;
    this.documentLoadingMessage = GlobalConstants.DOWNLOADING_LOADING_MESSAGE;
    this.isLoading = true;
    this.hasDocuments = false;
    this.NoDocMessage = GlobalConstants.NO_DOC_AVAILABLE;
    this.downloadInProgressMessage = GlobalConstants.DOWNLOADING_MESSAGE;
    this.tableDetail = [];
    this.isFileLarge = false;
    this.largeFileDownloadMessage = GlobalConstants.LARGE_FILE_DOWNLOAD_MSG;
  }

  ngOnInit(): void {

    // (09/30/2024 - jpark109) - Will be disabling this functionality temporarily and releasing the new document details in a future story.
    // The function app endpoints correlating to this component will be disabled as well.

     this.changeDetectorRef.detectChanges();
    // this.isLoading = true;
    this.subscription = this.documentInfoService.documentInfoAction$.subscribe(claim => {
      // this.isLoading = true;
      this.isDocInfoDialogVisible = true;
      this.selectedClaim = claim;

      this.documentInfoService.queryGetClaimDetails({
        clientName: this.clientService.getCurrentClient()?.clientName,
        claimId: claim.claimNumber,
        uploadId: []
      }).then(data => data.subscribe(response => {
        this.tableDetail = this.documentInfoService.sortClaimsByDate(response.claimDetailsResult);
        this.hasDocuments = (this.tableDetail && (this.tableDetail.length > 0));
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }));
    });
  }

  /**
  * Closes the document information dialog and performs cleanup.
  */
  closeDialogInfo(): void {
    this.isDocInfoDialogVisible = false;
    this.changeDetectorRef.detectChanges();
    this.tableDetail = [];
    this.closeDownloadBox = true;
    this.errorBoxVisible = false;
    this.selectedFileName = '';
  }

  /**
  * Initiates the asynchronous download of a file.
  *
  * @param item The document information of the file to be downloaded.
  */
  async downloadFile(item: DocumentInfo): Promise<void> {
    this.changeDetectorRef.detectChanges();
    this.isFileLarge = false;
    if (this.errorBoxVisible) { 
      this.errorBoxVisible = false; 
    }
    if (this.isFileDownloaded) { 
      this.isFileDownloaded = false;
      this.isFileDownloading = true;
    }
    this.changeDetectorRef.detectChanges();
    this.selectedFileName = item.documentName;
    try {
      this.onDownloadClick = true;
      this.closeDownloadBox = false;
      this.isErrorStatus = false;

      // This extracts the document and rendition ids from the downloadFileData array.
      const {
        chunkDocId: documentId,
        renditionId
      } = item.downloadFileData[0];

      // Get the client name
      const clientName = this.clientService.getCurrentClient()?.clientName;

      // Construct the request payload with the claim number AND the rendition href
      const requestData: FileDownloadRequestData = {
        claimNumber: this.selectedClaim?.claimNumber,
        href: `${clientName}/${documentId}/${renditionId}`
      };
      
      // This function returns a promise which returns an observable. So we have to await the promise
      // before we can subscribe to the observable.
      const downloadSubscription = await this.documentDownloadService.downloadFile(requestData);

      downloadSubscription.subscribe({
          next: (res) => {
            switch(res.type) {
              case HttpEventType.Response: {
                const {fileData, mimeType} = res.body;

                const blob: Blob = base64toBlob(fileData, mimeType);
                this.fileSize = formatBytes(blob.size);

                downloadBlob(blob, item.documentName);
                break;
              }
              default: {
                // We should avoid logging this type of information in production environments
                // since it is not an error and could be considered as noise.
                if (!environment.production) {
                  console.warn(`Non-Response Event: ${HttpEventType[res.type]} [${res.type}]`);
                }
              }
            }
          },
          error: (error) => {
            console.error('Error:', error);
            this.isFileLarge = false;
            this.changeDetectorRef.detectChanges();
            throw error;
          },
          complete: () => {
            this.isFileLarge = false;
            this.isFileDownloaded = true;
            this.isFileDownloading = false;
            this.sessionStorage.setItem(this.transferStatusKey, JSON.stringify(this.dataTranferredStatus));
            this.changeDetectorRef.detectChanges();
          }
        });
    } catch (error) {
      console.error('Error:', error);
      this.isFileLarge = false;
      this.changeDetectorRef.detectChanges();
      throw error;
    }
  }
    
  /**
  * Displays an error box and resets related properties after a timeout.
  */
  private showErrorBox(): void {
    this.errorBoxVisible = true;
    this.isFileLarge = false;
    this.changeDetectorRef.detectChanges();
  }
}