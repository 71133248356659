import * as _ from 'lodash';

import TinValues from '../models/TinValues';

import GuardianResponse from '../models/GuardianResponse';
import ProviderName from '../models/ProviderName';

/**
 *
 * @param guardianResponse : Guardian Response type that contains
 * the user TIN LIST.
 */

export function hasProviderDataTINList(guardianResponse: GuardianResponse[]): boolean {
  if (_.get(guardianResponse[0], 'data.tinlist', false)) {
    return true;
  }
  return false;
}

/**
 *
 * @param guardianResponse : A list of guardian response, that contains the provider name
 * to be extracted
 */
export function getProviderName(guardianResponse: GuardianResponse[]): ProviderName {
  const providerNameArray: string[] = [];
  if (guardianResponse && guardianResponse.length > 0) {
    guardianResponse.forEach((guardianData: GuardianResponse) => {
      if (guardianData &&  guardianData.data && guardianData.data.name) {
        providerNameArray.push(guardianData.data.name);
      }
    });
  }
  const providerNames: ProviderName = {
    providerName: providerNameArray
  };
  return providerNames;
}

/**
 *
 * @param guardianResponse : A list of guardian response, that contains the tinData
 * to be extracted
 */
export function extractDataTinValues(guardianResponse: GuardianResponse[]): TinValues {
  const tinValues: string[] = [];
  if (guardianResponse && guardianResponse.length > 0) {
    guardianResponse.forEach((guardianData: GuardianResponse) => {
      if (guardianData &&  guardianData.data && guardianData.data.tinlist) {
        const tinList = guardianData.data.tinlist.split(',');
        tinList.forEach(tin => {
          tinValues.push(tin.trim());
        });
      }
    });
  }
  const tinData: TinValues = {
    tin: tinValues
  };
  return tinData;
}