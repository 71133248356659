<div class="header-container">
  <uitk-header>
    <uitk-header-logo>
      <span class="uitk-c-header__product-name app__title" [innerHtml]="appTitle"></span>
    </uitk-header-logo>
    <uitk-navigation-global>
      <label class="uitk-c-global-nav__menu-label">{{ menuLabelText }}</label>
      <uitk-navigation-panel>
        <uitk-navigation-panel-item *ngIf="!isLoggedIn && showLoginNav">
          <button class="login-text" class="uitk-c-button uitk-c-button--ghost uitk-l-grid__col main__button" (click)="loginUser()">
            {{ loginText }}
          </button>
        </uitk-navigation-panel-item>
        <uitk-navigation-panel-item *ngIf="isLoggedIn" id="userDropDown">
          <button uitkNavButton>
            <span class="uitk-icon uitk-icon-add uitk-icon-person" :before></span>{{ greetingText }} {{ userButtonText }}
          </button>
          <uitk-navigation-panel>
            <uitk-navigation-panel-item *ngIf="isProviderPrefDisplayed">
              <button class="provider-preferences-btn" uitkNavRoute (click)="getProviderPreferencePage()">
                {{ providerPreferencesText }}
              </button>
            </uitk-navigation-panel-item>
            <uitk-navigation-panel-item>
              <a class="logout-btn" uitkNavRoute [href]="logoutUrl" routerLinkActive="active">
                {{ logOutText }}
              </a>
            </uitk-navigation-panel-item>
          </uitk-navigation-panel>
        </uitk-navigation-panel-item>
      </uitk-navigation-panel>
    </uitk-navigation-global>
  </uitk-header>
  <app-primary-navigation *ngIf="isLoggedIn && navBarDisplay"></app-primary-navigation>
</div>