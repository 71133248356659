import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import ChunkFile from '../models/ChunkFile';
import Claim from '../models/Claim';
import FileItem from '../models/FileItem';
import Environment from '../models/Environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import UploadRequest from '../models/UploadRequest';
import { environment } from '../../../environments/environment';
import { AuthService } from './abstracts/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  env: Environment;
  jwtToken: string;
  private uploadsubject = new Subject<Claim>();
  public uploadAction$ = this.uploadsubject.asObservable();
  
  constructor(private readonly authService: AuthService,
    private readonly httpClient: HttpClient) {
      this.env = environment;
  }

  async retrieveToken(): Promise<string> {
    await this.authService.isAuthenticated();
    await this.authService.getKeyCloakResponse();
    const code = this.authService.getToken();
    return code;
  }

  getClaimsByAccountNumber(claim: Claim): void {
    this.uploadsubject.next(claim);
  }

    /**
    * Asynchronously chunks and base64 encodes a given File.
    *
    * This method reads the input File in chunks, converts each chunk to base64 using
    * the convertFileToBase64 method, and constructs a FileItem containing the file name
    * and a list of ChunkFile objects.
    *
    * @param file The File to be chunked and base64 encoded.
    * @return A Promise resolving to a FileItem containing the file name and chunk information.
    * @throws Exception If an error occurs during the process.
    */
  async chunkAndBase64Encode(file: File): Promise<FileItem> {
    const chunkFilesList: ChunkFile[] = [];
    try {
      const fileName: string = file.name;
      const chunkSizeBytes: number = 30 * 1024 * 1024; // Set chunk size to 30 MB    
      const totalChunks: number = Math.ceil(file.size / chunkSizeBytes);
      for (let chunkNumber: number = 0; chunkNumber < totalChunks; chunkNumber++) {
        const start: number = chunkNumber * chunkSizeBytes;
        const end: number = Math.min(start + chunkSizeBytes, file.size);
        const chunk: Blob = file.slice(start, end);
        // eslint-disable-next-line no-await-in-loop
        const base64Data = await this.convertFileToBase64(chunk);
        const chunkFile: ChunkFile = {
          chunkFileNumber: chunkNumber + 1,
          chunkFileData: base64Data
        };
        chunkFilesList.push(chunkFile);
      }

      const fileData = { fileName, chunkFilesList };
      return fileData;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }
  
  /**
    * Converts a file chunk to base64 using FileReader.
    *
    * This method reads the provided file chunk using a FileReader, converts it to base64,
    * and returns the base64-encoded string. The conversion is performed asynchronously,
    * and the result is returned as a Promise.
    *
    * @param chunk The file chunk to be converted to base64.
    * @return A Promise resolving to the base64-encoded string of the file chunk.
  */
  private convertFileToBase64(chunk): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const base64Data = reader.result.toString().split(',')[1];
        resolve(base64Data);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(chunk as Blob);
    });
  }

  async uploadClaim(uploadRequest: UploadRequest, next: (res: any) => void, error: (error: Error) => void) {
    const { baseUrl, endpointClaimsUpload } = this.env.lettersInventory;
    const newFormData = new FormData();
    newFormData.append('memberLetterBarCode', uploadRequest.memberLetterBarCode);
    newFormData.append('userId', uploadRequest.userId);
    newFormData.append('uploadId', uploadRequest.uploadId);
    newFormData.append('clientId', uploadRequest.clientId);
    newFormData.append('file', uploadRequest.file, uploadRequest.file.name);
    this.jwtToken = (!this.jwtToken || this.jwtToken.length === 0) ? await this.retrieveToken() : this.jwtToken;
    return this.httpClient.post(`${baseUrl}${endpointClaimsUpload}`, newFormData, {
        headers: {
          "Authorization": `Bearer ${this.jwtToken}`
        }
      }).subscribe({
          next,
          error
        });
    return null;
  }
}