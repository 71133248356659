<div class="uitk-l-grid upload-files-container">
  <uitk-dialog class="upload-dialog-box"
  [(showModal)]="isUploadDialogVisible"
  (onModalHidden)="cancelDialog()"
  uitkId="upload_Dialog"
  triggerElementId="upload_Dialog_openBtn"
  >
    <uitk-dialog-header>
      <span class="uitk-icon uitk-icon-upload lg-icon" aria-hidden="true"></span>
      {{ uploadMedicalRecordText }}
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class = "generic-container" id = "loadingContainer" *ngIf = "fileUploadLoading; else form">
          <uitk-loading-indicator
              uitkId="spinner"
              [visibleText]="visibleLoadingText"
              spinnerSize="large"
              >
          </uitk-loading-indicator>
        </div>
    <ng-template #form>
      <uitk-notification
      uitk-id="notificationVariant"
      [variant]="notificationVariant"
      [show]="showNotification"
      [displayButton]="false"
      [buttonText]="buttonText"
      (onButtonClick)="closeNotification()"
      >
      <span>{{ notificationMessage }}
      </span>
  </uitk-notification>
    <div *ngIf = "!showNotification || notificationVariant !== 'success'">
      <p><strong>{{ patientAccountNumberText }}</strong>{{ selectedClaim?.patientAccountNumber }}</p>
      <p><strong>{{ claimNumberText }}</strong>{{ selectedClaim?.claimNumber }}</p>
      <strong>{{ fileUploadMessage }}</strong>
      <uitk-form-field-hint>{{ acceptableFileText }}
        <a aria-label="Opens in a new tab"  class="databank-hyperlink" tabindex=0 [href]="dataBankUrl" target="_blank">
          {{ hyperLinkText }}
          <span class="uitk-icon uitk-icon-new_window sm-icon" aria-hidden="true"></span>
        </a>
      </uitk-form-field-hint>
      <form [formGroup]="uploadForm">
        <uitk-form-field>
          <uitk-form-field-hint><br/><span>Please do not upload password protected documents.</span><br/>
          <span><strong>Note:</strong> If submitting a ZIP file the documentation within the zip must be TIFF, TIF, PDF, JPG, JPEG.</span>
          </uitk-form-field-hint>
          <uitk-file-upload
              #uitkFileUpload = "uitkFileUpload"
              required
              formControlName="file"
              uploadBtnText="Submit"
              [id]="'required'"
              [url]="url"
              [fileLimit]="1"
              [fileMaxSize]="200000"
              [acceptedTypes]="acceptedTypesList"
              [isUploadOnSelect]="false"
              (onError) = "updateChange()"
              (onRemove) = "removeFile()"
              (onSelectFiles) = "updateChange()"
              (onUpload)="uploadRecord()"
              hideUploadButton="true"
          ></uitk-file-upload>
          <uitk-form-field-error
          *ngIf="
              uploadForm?.controls?.file?.errors?.required &&
              uploadForm?.controls?.file?.touched
          "
              >Required
          </uitk-form-field-error>
          <uitk-form-field-error
              *ngIf="
                  uploadForm?.controls?.file?.errors?.invalidFiles &&
                  !uploadForm?.controls?.file?.errors?.fileLimitExceeded
              "
              ><span *uitkFileUploadErrorContext="let errorContext"
                  >{{ errorContext.invalidFilesCount }} of {{ errorContext.totalFiles }} file(s) have
                  errors. See the list of files for error details</span
              ></uitk-form-field-error
          >
          <uitk-form-field-error
              *ngIf="
                  uploadForm?.controls?.file?.errors?.fileLimitExceeded &&
                  !uploadForm?.controls?.file?.errors?.invalidFiles
              "
              ><span>Too many files selected. Remove files to upload</span></uitk-form-field-error
          >
          <uitk-form-field-error
              *ngIf="
                  uploadForm?.controls?.file?.errors?.fileLimitExceeded &&
                  uploadForm?.controls?.file?.errors?.invalidFiles
              "
              ><span *uitkFileUploadErrorContext="let errorContext"
                  >Only 1 file can be uploaded. {{ errorContext.invalidFilesCount }} invalid files
                  selected. Review and remove files to upload</span
              ></uitk-form-field-error
          >
          <uitk-form-field-error
              *ngIf="
                  uploadForm?.controls?.file?.errors?.noFilesSelected &&
                  uploadForm?.controls?.file?.touched
              "
              >No file selected
          </uitk-form-field-error>
      </uitk-form-field>
      </form>
    </div>
    </ng-template>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <div *ngIf = "(showNotification && notificationVariant !== 'success') || (!fileUploadLoading && notificationVariant !== 'success')">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" 
        id="default-btn"
        type="submit"
        [disabled] = "uploadForm?.controls.file?.errors?.required || uploadForm?.controls?.file?.errors?.noFilesSelected
        || uploadForm?.controls?.file?.errors?.invalidFiles || uploadForm?.controls?.file?.errors?.fileLimitExceeded
        || fileUploadLoading || uploadForm?.controls.file?.value?.length === 0"
        class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs"
        (click)="uploadRecord()">
          {{ uploadButtonText }}
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" [disabled]="false" 
          (click)="cancelDialog()">
          {{ cancelButtonText }}
        </button>
      </div>  
    </uitk-dialog-actions>
  </uitk-dialog>
</div>