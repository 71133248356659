import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

import { AuthService } from '../services/abstracts/auth.service';
import { GlobalConstants } from '../constants/GlobalConstants';
import { environment } from 'src/environments/environment';
import { LandingPageEmailConfig } from '../models/LandingPageEmailConfig';
import { BannerService } from '../services/banner.service';
import { BannerMessages } from '../models/BannerMessages';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BannerComponent implements OnInit, OnDestroy {
  notificationType: string;
  inactiveMessage: string;
  isIdleTimeOut: boolean;
  onTimerSubscription: Subscription;
  onTimeOutSubscription: Subscription;
  isLoggedIn: boolean;
  isClaimsHome: boolean;
  pdfNotificationMess: string;
  hyperLinkText: string;
  dataBankUrl: string;
  helpLineMsg: string;
  emailUserName: string;
  emailSymbol: string;
  emailDomainName: string;
  emailDomain: string;
  message: string;
  showBanner: boolean;
  isNotificationVisible: boolean;
  closeButtonText: string;
  variant: string;
  isSuccessBanner: boolean;
  isErrorBanner: boolean;
  isWarningBanner: boolean;
  isInfoBanner: boolean;

  constructor (private readonly authService: AuthService, 
    private readonly router: Router, 
    private readonly userIdleService: UserIdleService,
    private readonly bannerService: BannerService) 
  {
    this.helpLineMsg = GlobalConstants.HELP_LINE_MSG;
    this.emailUserName = LandingPageEmailConfig.EMAIL.userName;
    this.emailSymbol = LandingPageEmailConfig.EMAIL.symbol;
    this.emailDomainName = LandingPageEmailConfig.EMAIL.domainName;
    this.emailDomain = LandingPageEmailConfig.EMAIL.domain;
    this.hyperLinkText = GlobalConstants.HYPERLINK_TEXT;
    this.dataBankUrl = environment.dataBankUploadUrl;
    this.inactiveMessage = '';
    this.notificationType = '';
    this.isIdleTimeOut = false;
    this.isLoggedIn = false;
    this.isClaimsHome = false;
    this.closeButtonText = GlobalConstants.CLOSE_BUTTON_TEXT;
    this.isNotificationVisible = false;
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/logout' || event.url === '/') {
          this.isClaimsHome = false;
          this.isLoggedIn = true;
          this.userIdleService.stopWatching();
        } else {
          this.isLoggedIn = true;
          this.isClaimsHome = false;
          this.userIdleService.startWatching();
          this.onTimerSubscription = this.userIdleService.onTimerStart().subscribe(timer => timer);
          this.callTimeOut();
          if (event.url === '/registered/home') {
            this.isClaimsHome = true;
            this.notificationType = GlobalConstants.INFO_NOTIFICATION_TYPE;
            this.pdfNotificationMess = GlobalConstants.ACCEPTABLE_FILE_TEXT;
            this.isNotificationVisible = true;
          }
        }
      }
    });
    this.bannerService.messageSubject$.subscribe((messagetoDisplay: BannerMessages) => {
      this.isSuccessBanner = (messagetoDisplay.severity === GlobalConstants.SUCCESS_NOTIFICATION_TYPE);
      this.isErrorBanner = (messagetoDisplay.severity === GlobalConstants.ERROR_NOTIFICATION_TYPE);
      this.isWarningBanner = (messagetoDisplay.severity === GlobalConstants.WARNING_NOTIFICATION_TYPE);
      this.isInfoBanner = (messagetoDisplay.severity === GlobalConstants.INFO_NOTIFICATION_TYPE);
      this.isNotificationVisible = true;
      this.message = messagetoDisplay.message;
      setTimeout(() => {
        this.message = null;
        this.isSuccessBanner = false;
        this.isErrorBanner = false;
        this.isWarningBanner = false;
        this.isInfoBanner = false;
        this.isNotificationVisible = false;
      }, 5000);
    });
  }

  handleButtonClick(e) {
    this.isNotificationVisible = false;
  }

  callTimeOut(): void {
    this.onTimeOutSubscription = this.userIdleService.onTimeout().subscribe((res) => {
      this.isIdleTimeOut = true;
      this.notificationType = GlobalConstants.INFO_NOTIFICATION_TYPE;
      this.inactiveMessage = GlobalConstants.INACTIVE_MESSAGE;
      this.logout();
      this.userIdleService.stopWatching();
    });
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    if (this.onTimerSubscription) {
      this.onTimerSubscription.unsubscribe();
    }
    if (this.onTimeOutSubscription) {
      this.onTimeOutSubscription.unsubscribe();
    }
  }
}