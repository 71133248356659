import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderPreferenceService } from './provider-preference.service';
import VerificationEmailData from '../models/VerificationEmailData';
import { HttpAuthService } from './http.auth.service';

@Injectable({
  providedIn: 'root'
})
export class SendVerificationEmailService {
  emailUrl: string;
  token: string;

  constructor(
    private readonly authService: HttpAuthService,
    private readonly httpClient: HttpClient,
  ) {
    this.emailUrl = environment.consentMgmt.preferenceUrl;
  }

  async sendVerificationEmail(
    sendEmailData: VerificationEmailData,
    complete: () => void,
    errorCallback?: (err: unknown) => void
  ) {
    const accessToken = await this.authService.getUserToken();

    this.httpClient
      .post<HttpResponse<string>>(`${this.emailUrl}/SendEmailWithOTP`, sendEmailData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          Authorization: `Bearer ${accessToken}`
        },
        observe: 'response',
        reportProgress: true
      })
      .pipe(
        map(res => res),
        catchError(err => throwError(err))
      )
      .subscribe({ error: errorCallback, complete });
  }
}
