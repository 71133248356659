import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../shared/services/abstracts/auth.service';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  isLoggedIn: boolean;
  navBarDisplay: boolean;
  appTitle: string;
  menuLabelText: string;
  greetingText: string;
  loginText: string;
  logOutText: string;
  providerPreferencesText: string;
  userButtonText: string;
  showLoginNav: boolean;
  subscription: Subscription;
  logoutUrl: string;
  isProviderPrefDisplayed: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly keycloakService: KeycloakService
  ) {
    this.appTitle = GlobalConstants.APP_TITLE;
    this.menuLabelText = GlobalConstants.MENU_LABEL_TEXT;
    this.greetingText = GlobalConstants.GREETING_TEXT;
    this.loginText = GlobalConstants.LOGIN_TEXT;
    this.logOutText = GlobalConstants.LOGOUT_TEXT;
    this.providerPreferencesText = GlobalConstants.PROVIDER_PREFERENCES_TEXT;
    this.showLoginNav = false;
    this.logoutUrl = environment.sentinelLogOutUrl;
    this.isProviderPrefDisplayed = false;
    (async () => { this.isLoggedIn = await this.authService.isAuthenticated(); }) ();
  }

  ngOnInit(): void {
    this.getContactDetails();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ((event.url === '/registered/home')) {
          this.navBarDisplay = false;
          this.isProviderPrefDisplayed = true;
        } else {
          this.navBarDisplay = false;
          this.isProviderPrefDisplayed = false;
        }
        if (event.url === '/') {
          this.showLoginNav = false;
        } else {
          this.showLoginNav = true;
        }
        if (event.url === '/provider-preference') {
          this.navBarDisplay = true;
          this.isProviderPrefDisplayed = false;
        }
        if (event.url === '/logout') {
          this.isLoggedIn = false;
        }
      }
    });
  }

  private async getContactDetails(): Promise<void> {  
    if (this.authService.getUserProfile() && this.authService.getUserProfile().firstName) {
      this.setUser(this.authService.getUserProfile());
    } else {
      await this.authService.getKeyCloakResponse();
      const userProfileData = this.authService.getUserProfile();
      if (userProfileData && userProfileData.firstName) {
        this.setUser(userProfileData);
      } else {
        this.loggedOut();
      }
    }
  }

  setUser(user: KeycloakProfile): void {
    this.isLoggedIn = true;
    this.userButtonText = `${user.firstName} ${user.lastName}`;
  }

  public async loginUser(): Promise<void> {
    await this.keycloakService.login({
      redirectUri: environment.url,
      idpHint: GlobalConstants.IDP_HINT_TEXT
    });
  }

  loggedOut(): void {
    this.isLoggedIn = false;
    this.userButtonText = '';
    this.authService.logout();
  }

  getProviderPreferencePage(): void {
    this.router.navigate(['provider-preference']);
  }
}