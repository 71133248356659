import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/abstracts/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => console.log(event),
        err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            console.error('Caught 401 Unauthorized error:', err);
          }
            // Log the error message received from the server (if available)
          if (err instanceof HttpErrorResponse  && err.error) {
            console.error('Server Error Message:', err.error);
          }
        }
      )
    );
  }
}