import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import Environment from '../models/Environment';
import FileDownloadRequestData from '../models/FileDownloadRequestData';
import FileDownloadResponse from '../models/FileDownloadResponse';
import { AuthService } from './abstracts/auth.service';

@Injectable({
  providedIn: 'root'
})

export class DocumentDownloadService {
  downloadFunctionKey: string;
  downloadLabel: string;
  downloadFileEndpoint: string;
  jwtToken: string;
  env: Environment;

  constructor(
    private readonly httpClient: HttpClient, 
    private readonly authService: AuthService)
  {
    this.downloadFunctionKey = GlobalConstants.GET_DOWNLOAD_RESPONSE_KEY;
    this.downloadLabel = GlobalConstants.GET_DOWNLOAD_RESPONSE_LABEL;
    this.downloadFileEndpoint = environment.guardian.optumGuardianUrl;
    this.env = environment;
  }

  async retrieveToken(): Promise<string> {
    await this.authService.isAuthenticated();
    await this.authService.getKeyCloakResponse();
    const code = this.authService.getToken();
    return code;
  }

  /**
  * Downloads a file with progress events.
  *
  * @param {FileDownloadRequestData} fileDownloadRequestData - Data for the file download request.
  * @returns - Observable emitting download progress and response events.
  * @throws {Error} - Thrown if there's an error during configuration retrieval or HTTP request.
  */
  async downloadFile(fileDownloadRequestData: FileDownloadRequestData) {
    const { baseUrl, endpointDownloadDocuments } = this.env.lettersInventory;
    this.jwtToken = (!this.jwtToken || this.jwtToken.length === 0) ? await this.retrieveToken() : this.jwtToken;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.post<FileDownloadResponse>(
        `${baseUrl}${endpointDownloadDocuments}`,
        fileDownloadRequestData,
        { headers, reportProgress: true, observe: 'events' }
      );
  }
}