<div id = "homeContainer">
    <div id = "contentContainer">
        <img id = "contentGraphicImage" src = "assets/images/graphic.svg">
        <h2 id = "contentTitle">Welcome to the Payment Integrity Portal!</h2>
        <div id = "contentSubheading">
            <span>Self Registering will allow you to fully access the portal given you are associated with a valid client.</span><br>
        </div>
        <div id = "callToActionContainer">
            <div id = "callToActionRow">
                <button routerLink = 'self-registration' class="uitk-c-button uitk-c-button--lg uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" style = "display:inline-flex">
                    Go to Self Registration
                </button>
                <!-- <button routerLink = 'record-upload' class="uitk-c-button uitk-c-button--lg uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" style = "display: inline-flex">
                    Go to Record Upload
                </button> -->
            </div>
           
        </div>
    </div>
</div>