<div class="uitk-l-grid edit-preference-container">
    <ng-container *ngIf="!isLoading else loading">
        <div class="uitk-l-grid__row centered__shadowed">
            <div class="uitk-l-grid edit-preference">
                <div class="uitk-l-grid__row">
                    <div class="uitk-l-grid__col">
                        <div class = "providerTitle">
                            <legend><h2>{{ editPreferenceText }}</h2></legend> 
                        </div>
                        <div class="providerData">
                            <label uitkLabel class="providerNameLabel">{{ providerNameText }} </label>
                            <span class="providerName" uitkLabel>: {{ providerName }}</span>
                        </div>
                        <div class = "deliveryPref">
                            <form [formGroup]="profileForm">
                                <uitk-form-field>
                                    <uitk-fieldset aria-required="true">
                                        <span uitkLabel>{{ deliveryPreferencesText }}</span>
                                        <uitk-form-field-hint>
                                            {{ deliveryHelperText }}
                                        </uitk-form-field-hint>
                                        <uitk-form-field-error
                                            *ngIf="
                                                profileForm?.controls?.deliveryRadio?.errors?.required &&
                                                profileForm?.controls?.deliveryRadio?.touched
                                            "
                                            >
                                            <span>{{ deliveryErrorText }}</span>
                                        </uitk-form-field-error>
                                        <uitk-radio-group
                                            id="my-basic-radio-group"
                                            formControlName="deliveryRadio"
                                            [itemList]="deliveryMethod"
                                        >
                                        </uitk-radio-group>
                                    </uitk-fieldset>
                                </uitk-form-field>
                                <div class="verifyEmail" *ngIf="this.displayEmailField()">
                                    <div class="emailInput">
                                        <uitk-form-field>
                                            <label uitkLabel>{{ emailAddressText }}</label>
                                            <uitk-form-field-hint>{{ emailHelperText }}</uitk-form-field-hint>
                                            <input id="my-basic-input-reactive" uitkInput formControlName="email" required/>
                                            <uitk-form-field-error
                                                *ngIf="
                                                profileForm?.controls?.email?.errors?.required &&
                                                profileForm?.controls?.email?.dirty ||
                                                (profileForm?.controls?.email?.touched &&
                                                profileForm?.controls?.email?.invalid)
                                                "
                                            >
                                                {{ emailErrorText }}
                                            </uitk-form-field-error>
                                        </uitk-form-field>
                                    </div>
                                    <div class="verifyCodeButton">
                                        <button *ngIf="displayVerifyButton()" [disabled]="disableVerifyButton" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="sendEmail()">
                                            {{ verifyText }}
                                        </button>
                                        <button *ngIf="displayEmailField() && disableVerifyButton || isErrorInOtp" [disabled]="isOTPSuccess" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="sendEmail($event)">
                                            {{ resendEmailText }}
                                        </button>
                                    </div>
                                </div>
                                <span class="actionForNoEmailMessage" *ngIf="displayEmailField() && isVerifyOtpButtonDisplayed">
                                    {{ actionForNoEmailMessageText }}<br>{{ reachOutMessage }}
                                </span>
                                <div class = "otp" *ngIf="displayEmailField() && isVerifyOtpButtonDisplayed">
                                    <div class="otpInput">
                                        <uitk-form-field>
                                            <label uitkLabel>{{ otp }}</label>
                                            <uitk-form-field-hint>
                                                {{ enterOTPMsg }}
                                            </uitk-form-field-hint>
                                            <input id="my-basic-input-reactive" uitkInput formControlName="otp" required/>
                                            <uitk-form-field-error
                                                *ngIf="
                                                profileForm?.controls?.otp?.errors?.required &&
                                                profileForm?.controls?.otp?.dirty ||
                                                (profileForm?.controls?.otp?.touched &&
                                                profileForm?.controls?.otp?.invalid)
                                                "
                                            >
                                                {{ enterOTP }}
                                            </uitk-form-field-error>
                                        </uitk-form-field>
                                    </div>
                                    <div class="otpButton">  
                                        <button *ngIf="displayEmailField()" [disabled] = "disableOtpButton" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="verifyOtp()">
                                            {{ verifyOtpText }}
                                        </button>
                                    </div> 
                                </div>
                            </form>
                            <div class="SaveButton">
                                <button [disabled]="!isSaveButtonVisible()" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="onSave()">
                                    {{ saveText }}
                                </button>
                                <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs main__button" (click)="onCancel()">
                                    {{ cancelText }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #loading>
        <app-loading-spinner [loadingMessage]="preferenceLoadingMessage" [spinnerSize]="spinnerSize"></app-loading-spinner>
    </ng-template>
</div>