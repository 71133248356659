import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UITKNavigationModule, UITKDatePickerModule, UITKInputModule, UITKNotificationModule, UITKFooterModule, UITKPanelModule, UITKPageNotificationModule } from '@uitk/angular';
import { AppComponent } from './app.component';
import { UnauthorizedComponent } from './account/unauthorized/unauthorized.component';
import { AccountModule } from './account/account.module';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ClaimsModule } from './claims/claims.module';
import { SharedModule } from './shared/shared.module';
import { AlertService } from './shared/services/abstracts/alert.service';
import { AuthService } from './shared/services/abstracts/auth.service';
import { CpAlertService } from './shared/services/cp-alert.service';
import { CpEntitlementService } from './shared/services/cp-entitlement.service';
import { EntitlementService } from './shared/services/abstracts/entitlement.service';
import { ErrorService } from './shared/services/error.service';
import { HttpAuthService } from './shared/services/http.auth.service';
import { OptumGuardianProxyService } from './shared/services/optum.guardian.proxy.service';
import { GuardianService } from './shared/services/abstracts/guardian.service';
import { ErrorInterceptor } from './shared/middlewares/error.interceptor';
import { ErrorHelpers } from './shared/helpers/ErrorHelpers';
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializeSentinel } from './init/sentinel-init.factory';
import { AuthGuard } from './shared/guards/auth.guard';
import { RolesGuard } from './shared/guards/roles.guard';
import { TokenInterceptor } from './shared/middlewares/token-interceptor';

@NgModule({
  declarations: [AppComponent, UnauthorizedComponent, ],
  imports: [
    AccountModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    ClaimsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    UITKNavigationModule,
    ReactiveFormsModule,
    UITKInputModule,
    UITKNotificationModule,
    UITKFooterModule,
    UITKDatePickerModule,
    UITKPanelModule,
    FormsModule,
    KeycloakAngularModule,
    UITKPageNotificationModule
  ],
  providers: [
    ErrorService,
    AuthGuard,
    RolesGuard,
    { provide: AlertService, useClass: CpAlertService },
    { provide: AuthService, useClass: HttpAuthService },
    { provide: EntitlementService, useClass: CpEntitlementService },
    { provide: ErrorHandler, useClass: ErrorHelpers },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: GuardianService, useClass: OptumGuardianProxyService },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSentinel,
      multi: true,
      deps: [KeycloakService],
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }