import { Component, OnInit } from '@angular/core';
import { UITKHeadingLevel, IUITKRadioGroupItem } from '@uitk/angular';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

import { SendEmailOtpService } from '../../shared/services/send-email-otp.service';
import { ProviderPreferenceService } from '../../shared/services/provider-preference.service';
import { ClientService } from '../../shared/services/client.service';
import GetPreferenceData from '../../shared/models/GetPreferenceData';
import { GuardianService } from '../../shared/services/abstracts/guardian.service';
import LogPreferenceData from '../../shared/models/LogPreferenceData';
import { DeliveryMethodItems } from '../../shared/models/DeliveryMethodItems';  
import { BannerService } from '../../shared/services/banner.service';
import { AuthService } from '../../shared/services/abstracts/auth.service';
import { getProviderName } from '../../shared/helpers/GuardianHelper';
import GuardianResponse from '../../shared/models/GuardianResponse';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';

@Component({
  selector: 'app-edit-preference',
  templateUrl: './edit-preference.component.html',
  styleUrls: ['./edit-preference.component.scss']
})
export class EditPreferenceComponent implements OnInit {
  headingLevel = UITKHeadingLevel;
  providerDetailsContent: string;
  providerDetailsText: string;
  collapsiblePanel: boolean;
  openPanel: boolean;
  deliveryHelperText: string;
  editPreferenceText: string;
  emailAddressText: string;
  emailHelperText: string;
  emailErrorText: string;
  deliveryErrorText: string;
  deliveryPreferencesText: string;
  givenEmail: string;
  isVerifyOtpButtonDisplayed: boolean;
  isErrorInOtp: boolean;
  isEmailVerified: boolean;
  isSuccess: boolean;
  isOTPSuccess: boolean;
  loggingSuccess: boolean;
  otpFromBackend: string;
  deliveryMethod: IUITKRadioGroupItem[];
  paperText: string;
  digitalText: string;
  verifyText: string;
  otp: string;
  enterOTPMsg: string;
  enterOTP: string;
  verifyOtpText: string;
  resendEmailText: string;
  saveText: string;
  cancelText: string;
  isLoading: boolean;
  spinnerSize: string;
  preferenceLoadingMessage: string;
  providerNameText: string;
  providerName: string;

  profileForm: FormGroup = new FormGroup({
    deliveryRadio: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    otp: new FormControl('', [Validators.pattern("^[0-9]*$"),Validators.minLength(7), Validators.maxLength(7)])
  });

  isOTPExpired: boolean;
  emailFromDB: string;
  isEmailFromDBAndProvidedSame: boolean;
  deliveryOptionFromDB: string;
  disableOtpButton: boolean;
  disableVerifyButton: boolean;
  emailAlreadyValidatedMessage: string;
  emailEmptyMessage: string;
  actionForNoEmailMessageText: string;
  reachOutMessage: string;

  constructor(
    private readonly router: Router,
    private readonly sendEmailOtpService: SendEmailOtpService,
    private readonly providerPreferenceService: ProviderPreferenceService,
    private readonly clientService: ClientService,
    private readonly guardianService: GuardianService,
    private readonly bannerService: BannerService,
    private readonly authService: AuthService)
  {
    this.providerDetailsContent = GlobalConstants.PROVIDER_DETAILS_CONTENT;
    this.providerDetailsText = GlobalConstants.PROVIDER_DETAILS_TEXT;
    this.collapsiblePanel = true;
    this.openPanel = true;
    this.deliveryHelperText = GlobalConstants.DELIVERY_HELPER_TEXT;
    this.editPreferenceText = GlobalConstants.EDIT_PREFERENCES_TEXT;
    this.emailAddressText = GlobalConstants.EMAIL_ADRRESS_TEXT;
    this.emailHelperText = GlobalConstants.EMAIL_HELPER_TEXT;
    this.emailErrorText = GlobalConstants.EMAIL_ERROR_TEXT;
    this.deliveryErrorText = GlobalConstants.DELIVERY_ERROR_TEXT;
    this.deliveryPreferencesText = GlobalConstants.DELIVERY_PREFERENCES_TEXT;
    this.givenEmail = '';
    this.isVerifyOtpButtonDisplayed = false;
    this.isErrorInOtp = false;
    this.isEmailVerified = false;
    this.deliveryMethod = DeliveryMethodItems.DELIVERY_ITEMS;
    this.paperText = GlobalConstants.PAPER_TEXT;
    this.digitalText = GlobalConstants.DIGITAL_TEXT;
    this.verifyText = GlobalConstants.VERIFY_TEXT;
    this.otp = GlobalConstants.OTP_TEXT;
    this.enterOTPMsg = GlobalConstants.ENTER_OTP_MESSAGE;
    this.enterOTP = GlobalConstants.ENTER_OTP;
    this.verifyOtpText = GlobalConstants.VERIFY_OTP_TEXT;
    this.resendEmailText = GlobalConstants.RESEND_EMAIL_TEXT;
    this.saveText = GlobalConstants.SAVE_TEXT;
    this.cancelText = GlobalConstants.CANCEL_BUTTON_TEXT;
    this.isLoading = false;
    this.spinnerSize = GlobalConstants.SPINNER_SIZE_LARGE;
    this.preferenceLoadingMessage = GlobalConstants.PREFERENCE_LOADING_MESSAGE;
    this.emailAlreadyValidatedMessage = '';
    this.isOTPSuccess = false;
    this.isEmailFromDBAndProvidedSame = false;
    this.disableOtpButton = true;
    this.isOTPExpired = false;
    this.disableVerifyButton = false;
    this.emailFromDB = '';
    this.emailEmptyMessage = '';
    this.actionForNoEmailMessageText = GlobalConstants.ACTION_FOR_NO_EMAIL_MESSAGE;
    this.reachOutMessage = GlobalConstants.REACHOUT_MESSAGE;
    this.loggingSuccess = false;
    this.providerNameText = GlobalConstants.PROVIDER_NAME_TEXT;
    this.providerName = '';
  }

  async ngOnInit(): Promise<void> {
    await this.initializePreference();
  }

  async initializePreference(): Promise<void> {
    try {
      this.isLoading = true;
      const guardianData: GuardianResponse[] | null = await this.guardianService.getGuardianData();
      if  (guardianData) {
        this.providerName = getProviderName(guardianData)?.providerName[1];
        const getPreferenceData: GetPreferenceData = {
          clientName: this.clientService.getCurrentClient().clientName?.toLowerCase(),
          tin: (await this.guardianService.getTinValues())?.tin[0]
        };
        this.providerPreferenceService.getPreference(getPreferenceData, 
          res => {
            this.isLoading = false;
            this.deliveryOptionFromDB = res?.deliveryOption[0];
            let deliveryValue;
            if (this.deliveryOptionFromDB === '') { 
              deliveryValue = this.deliveryMethod.filter(re => re.value === GlobalConstants.PAPER_TEXT);
            } else {
              deliveryValue = this.deliveryMethod.filter(re => re.value === this.deliveryOptionFromDB);
            }
            this.profileForm.setValue({
              deliveryRadio: deliveryValue.find(delivery => delivery.value),
              email: res.emailAddress,
              otp: ''
            });
            this.emailFromDB = res.emailAddress;
            if (res.emailAddress && this.givenEmail !== '') {
              this.isEmailFromDBAndProvidedSame = res.emailAddress === this.givenEmail;
            }
            this.isEmailVerified = (res.isEmailVerified === true);
            this.displayEmailField();
          },
          error => { 
            this.isLoading = false;
           throw(error);
          }
        );
      }
    } catch (error) {
      console.error('Error in initializePreference:', error);
      throw error;
    }
  }
  
  displayEmailField(): boolean {
    return this.profileForm.get('deliveryRadio').value.value === this.digitalText;
  }

  displayVerifyButton(): boolean {
    const emailControl = this.profileForm.get('email');
    if (this.displayEmailField()
      && !this.isEmailFromDBAndProvidedSame 
      && emailControl.dirty 
      && emailControl.valid 
      && !this.isVerifyOtpButtonDisplayed) {
        return true;
    } 
    return false;
  }

  private callSendEmailService(messageToShow: string): void {
    this.sendEmailOtpService.sendEmailWithOtp({email: this.givenEmail}, res => {
      if (res.status === 200) {         
        this.isVerifyOtpButtonDisplayed = true;
        this.isSuccess = true;
        this.disableVerifyButton = true;
        this.disableOtpButton = false;
        this.emailAlreadyValidatedMessage = '';
        this.emailEmptyMessage = '';
        this.profileForm.get('email').disable();
        this.bannerService.showSuccessBanner(messageToShow);
      } else {
        this.isVerifyOtpButtonDisplayed = false;
        this.isSuccess = false;
        this.emailEmptyMessage = '';
        this.bannerService.showErrorBanner(GlobalConstants.FAILED_TO_SEND_OTP_MESSAGE);
      }
    }, err => { 
      this.isVerifyOtpButtonDisplayed = false;
      this.isSuccess = false;
      this.emailEmptyMessage = GlobalConstants.ERROR_MSG_SENDING_EMAIL;
      this.bannerService.showErrorBanner(GlobalConstants.ERROR_MSG_SENDING_EMAIL);
    });
  }

  sendEmail(event?: Event): void {
    this.givenEmail = this.profileForm.get('email').value;
    const messageToShow = event ? GlobalConstants.NEW_OTP_SENT_MESSAGE : GlobalConstants.EMAIL_SENT_SUCCESS_MESSAGE;
    if (this.givenEmail !== '') {
      if (this.givenEmail !== this.emailFromDB) {
        this.callSendEmailService(messageToShow);
      } else {
        this.emailAlreadyValidatedMessage = GlobalConstants.EMAIL_ALREADY_VALIDATED; 
        this.bannerService.showWarningBanner(GlobalConstants.EMAIL_ALREADY_VALIDATED);
        this.isVerifyOtpButtonDisplayed = false;
        this.isSuccess = false;
        this.emailEmptyMessage = '';
      }
    } else {
      this.isVerifyOtpButtonDisplayed = false;
      this.isSuccess = false;
      this.emailEmptyMessage = GlobalConstants.EMAIL_FIELD_EMPTY_MSG;
      this.bannerService.showErrorBanner(GlobalConstants.EMAIL_FIELD_EMPTY_MSG);
    }
  }

  verifyOtp(): void {
    if (this.givenEmail !== '' && this.profileForm.controls.otp.valid) {
      const verifyData = { email: this.givenEmail, otp: this.profileForm.get('otp').value };
      this.sendEmailOtpService.verifyOtp(verifyData).subscribe(response => {
        if (response === GlobalConstants.OTP_SUCCESS_MESSAGE) {
          this.isOTPSuccess = true;
          this.isErrorInOtp = false;
          this.disableVerifyButton = true;
          this.disableOtpButton = true;
          this.profileForm.get('otp').disable();
          this.bannerService.showSuccessBanner(GlobalConstants.OTP_VALIDATED_MSG);

        } else if (response === GlobalConstants.OTP_EXPIRED_MSG) {
          this.isOTPSuccess = false;
          this.isOTPExpired = true;
          this.isVerifyOtpButtonDisplayed = false;
          this.disableVerifyButton = true;
          this.disableOtpButton = false;
          this.profileForm.get('otp').reset();      
          this.bannerService.showErrorBanner(GlobalConstants.OTP_EXPIRED_MSG);
        } else {
          this.isErrorInOtp = true;
          this.isVerifyOtpButtonDisplayed = false;
          this.disableVerifyButton = false;
          this.disableOtpButton = false;
          this.profileForm.get('otp').reset();  
          this.bannerService.showErrorBanner(GlobalConstants.OTP_NOT_MATCHING_MSG);
        }
      },
      (error) => {
        throwError(error);
        this.isErrorInOtp = true;
        this.isOTPSuccess = false;
        this.profileForm.get('otp').reset();  
        this.bannerService.showErrorBanner(GlobalConstants.OTP_NOT_MATCHING_MSG);
      });
    }
  }

  getActionTaken(): string {
    if (this.emailFromDB === '') {
      return this.givenEmail !== '' ? GlobalConstants.CREATED_TEXT : GlobalConstants.DELETED_TEXT;
    } 
      return this.givenEmail !== '' ? GlobalConstants.MODIFIED_TEXT : GlobalConstants.DELETED_TEXT;
    
  }

  isSaveButtonVisible(): boolean {
    const deliveryRadioValue = this.profileForm.get('deliveryRadio').value.value;
    if (deliveryRadioValue === GlobalConstants.DIGITAL_TEXT && this.givenEmail && this.isOTPSuccess) {
      return true;
    }
    if (this.deliveryOptionFromDB === GlobalConstants.DIGITAL_TEXT && deliveryRadioValue === GlobalConstants.PAPER_TEXT) {
      return true;
    }
    return false;
  }
  
  async onSave(): Promise<void> {
    if (((this.profileForm.get('deliveryRadio').value.value === GlobalConstants.DIGITAL_TEXT) && this.givenEmail && this.isOTPSuccess) || (this.profileForm.get('deliveryRadio').value.value === GlobalConstants.PAPER_TEXT)) {
      if (this.authService.getUserProfile() && 
      this.authService.getUserProfile().firstName &&
      this.authService.getUserProfile().lastName) {
        const logPreferenceData: LogPreferenceData = {
          clientName: this.clientService.getCurrentClient().clientName.toLowerCase(),
          claimType: GlobalConstants.PREPAY_TEXT.toString(),
          deliveryOption: [this.profileForm.get('deliveryRadio').value.value],
          emailAddress: this.givenEmail.toString(),
          updatedBy: this.authService.getUserProfile().email.toString(),
          tin: (await this.guardianService.getTinValues())?.tin,
          emailNotificationsFrequency: GlobalConstants.EMAIL_NOTIFICATION_FREQUENCY,
          firstName: this.authService.getUserProfile().firstName,
          lastName: this.authService.getUserProfile().lastName,
          actionTaken: this.getActionTaken(),
          isEmailVerified: this.isOTPSuccess
        };
        this.providerPreferenceService.logPreference(logPreferenceData).subscribe(res => {
            this.loggingSuccess = true;
            this.bannerService.showSuccessBanner(GlobalConstants.DELIVERY_PREFERENCE_UPDATED);
        }, error => {
          this.bannerService.showErrorBanner(GlobalConstants.FAILED_TO_UPDATE_MESSAGE);
        });
        setTimeout(() => {
          this.onCancel();
        }, 10000);
      }
    }
  }

  onCancel(): void {
    this.profileForm.reset();
    this.router.navigate(['provider-preference']);
  }
}