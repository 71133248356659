<div id = "registeredPageContainer">
    <div id = "verticalNavContainer">
        <uitk-navigation-primary-vertical>
            <label class="uitk-c-primary-vertical-nav__menu-label">Primary Vertical Menu Label</label>
            <uitk-navigation-panel>
                <uitk-navigation-panel-item>
                    <button routerLink = './client-selection' uitkNavButton>
                        Home
                    </button>
                </uitk-navigation-panel-item>
                <uitk-navigation-panel-item *ngIf="featureFlags.recordUploadEnabled">
                    <button routerLink = 'record-upload' uitkNavButton>
                        Record Upload
                    </button>
                </uitk-navigation-panel-item>
                <uitk-navigation-panel-item *ngIf="featureFlags.faqEnabled">
                    <button routerLink = 'faq' uitkNavButton>
                        Frequently Asked Questions
                    </button>
                </uitk-navigation-panel-item>
            </uitk-navigation-panel>
        </uitk-navigation-primary-vertical>
    </div>
        
        <main id = "registeredMainContainer" tabindex="-1">
            <div id = "routerContainer">
                <app-banner></app-banner>
                <router-outlet></router-outlet>
            </div>
        </main>
</div> 