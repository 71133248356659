import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { AuthService } from 'src/app/shared/services/abstracts/auth.service';
import { GuardianService } from '../../shared/services/abstracts/guardian.service';
import { KeycloakService } from 'keycloak-angular';
import { hasProviderDataTINList } from '../../shared/helpers/GuardianHelper';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';
import { LandingPageEmailConfig } from 'src/app/shared/models/LandingPageEmailConfig';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})

export class WelcomeComponent implements OnInit {
  isLoading: boolean;
  authLoadingMessage: string;
  title: string;
  signInText: string;
  isProduction: boolean;
  isLocal: boolean;
  spinnerSize: string;
  isSentinelLoggedIn: boolean;
  taglineMsg: string;
  helpLineMsg: string;
  emailUserName: string;
  emailSymbol: string;
  emailDomainName: string;
  emailDomain: string;

  constructor(
    private readonly authService: AuthService,
    private readonly keycloakService: KeycloakService,
    private readonly guardianService: GuardianService,
    private readonly router: Router
  ) {
    this.isProduction = environment.production;
    this.isLocal = environment.isLocal;
    this.isLoading = false;
    this.authLoadingMessage = GlobalConstants.AUTHENTICATION_LOADING_MESSAGE;
    this.title = GlobalConstants.WELCOME_MESSAGE;
    this.signInText = GlobalConstants.SIGNIN_TEXT;
    this.taglineMsg = GlobalConstants.TAGLINE_MSG;
    this.helpLineMsg = GlobalConstants.HELP_LINE_MSG;
    this.emailUserName = LandingPageEmailConfig.EMAIL.userName;
    this.emailSymbol = LandingPageEmailConfig.EMAIL.symbol;
    this.emailDomainName = LandingPageEmailConfig.EMAIL.domainName;
    this.emailDomain = LandingPageEmailConfig.EMAIL.domain;
    this.spinnerSize = GlobalConstants.SPINNER_SIZE_LARGE;
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.isSentinelLoggedIn = await this.authService.isAuthenticated();
    await this.authService.getKeyCloakResponse();
    this.isLoading = true;
    const code = this.authService.getToken();
    this.isLoading = false;
    this.guardianService.guardianDataAction$.subscribe(res => {
      if (this.router.url === '/upload') {
        if (res && hasProviderDataTINList(res)) {
          this.isLoading = false;
          this.router.navigate(['registered/record-upload']);
        }
        else {
          this.isLoading = false;
          this.router.navigate(['unregistered/record-upload']);
        }
      }
      else if (res && hasProviderDataTINList(res)) {
        this.isLoading = false;
        this.router.navigate(['registered/client-selection']);
      } else {
        this.isLoading = false;
        this.router.navigate(['unauthorized']);
      }
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
      console.log(error);
      throw (error);
    });
    if (code) {
      this.isLoading = true;
      this.guardianService.setGuardianToken(code);
    }
  }

  public async loginUser(): Promise<void> {
    await this.keycloakService.login({
      redirectUri: environment.url,
      idpHint: GlobalConstants.IDP_HINT_TEXT
    });
  }
}
