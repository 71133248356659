import { IUITKRadioGroupItem } from '@uitk/angular';

export class DeliveryMethodItems {
  public static DELIVERY_ITEMS: IUITKRadioGroupItem[] = [
        { 
            label: 'Paper Delivery',
            value: 'paper'
        },
        {
            label: 'Digital Delivery',
            value: 'digital'
        }
    ];
}