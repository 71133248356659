import { Component, OnInit } from '@angular/core';

import { GlobalConstants } from '../../shared/constants/GlobalConstants';
import { AuthService } from '../../shared/services/abstracts/auth.service';

@Component({
  selector: 'app-primary-navigation',
  templateUrl: './primary-navigation.component.html',
  styleUrls: []
})

export class PrimaryNavigationComponent implements OnInit {
  displayNavBar: boolean;
  homeNavName: string;
  uploadDocumentNavName: string;
  faqsNavName: string;
  contactNavName: string;
  /** Harcoded list to only display desired tabs, to be change in a future
   * and to be moved to a config file or enviroment variable (to be discussed)
   * possible values: home, upload-document, faqs, contact */
  enabledTabs: string[] = ['home'];

  constructor(private readonly authService: AuthService) {
    this.displayNavBar = false;
    this.homeNavName = GlobalConstants.HOME_PRIMARY_NAV_ELEMENT_VALUE;
    this.uploadDocumentNavName = GlobalConstants.UPLOAD_DOCUMENTS_PRIMARY_NAV_ELEMENT_VALUE;
    this.faqsNavName = GlobalConstants.FAQS_PRIMARY_NAV_ELEMENT_VALUE;
    this.contactNavName = GlobalConstants.CONTACT_PRIMARY_NAV_ELEMENT_VALUE;
  }

  async ngOnInit(): Promise<void> {
    this.displayNavBar = await this.authService.isAuthenticated();
  }
  
  isTabEnabled(tabName: string): boolean {
    return this.enabledTabs.indexOf(tabName) !== -1;
  }
}