import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BannerMessageType } from '../models/MessageType';
import { BannerMessages } from '../models/BannerMessages';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private readonly messageSourceSubject: Subject<BannerMessages> = new Subject<BannerMessages>();
  public messageSubject$ = this.messageSourceSubject.asObservable();

  private broadcast(severity: BannerMessageType, message: string): void {
    this.messageSourceSubject.next({ severity, message });
  }

  showSuccessBanner(message: string): void {
    this.broadcast(BannerMessageType.Success, message);
  }

  showErrorBanner(message: string): void {
    this.broadcast(BannerMessageType.Error, message);
  }

  showWarningBanner(message: string): void {
    this.broadcast(BannerMessageType.Warning, message);
  }

  showInfoBanner(message: string): void {
    this.broadcast(BannerMessageType.Info, message);
  }
}