import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import GetPreferenceData from '../models/GetPreferenceData';
import GetPreferenceResponse from '../models/GetPreferenceResponse';
import LogPreferenceData from '../models/LogPreferenceData';
import { AppConfigService } from './app-config.service';
import { HttpAuthService } from './http.auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderPreferenceService {
  preferenceUrl: string;
  private token: string;
  authUrl: string;
  oauth_key: string;
  oauth_label: string;
  jwtToken: any;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfigService: AppConfigService,
    private readonly authService: HttpAuthService
  ) {
    this.preferenceUrl = environment.consentMgmt.preferenceUrl;
    this.authUrl = environment.guardian.optumGuardianUrl;
    this.oauth_key = GlobalConstants.OAUTH_TOKEN_KEY;
    this.oauth_label = GlobalConstants.OAUTH_TOKEN_LABEL;
  }

  public getToken(): string {
    return this.token;
  }

  async retrieveToken(): Promise<string> {
    await this.authService.isAuthenticated();
    await this.authService.getKeyCloakResponse();
    const code = this.authService.getToken();
    return code;
  }

  logPreference(logPreferenceData: LogPreferenceData) {
    return from(new Promise<string>(async (resolve, reject) => {
      const accessToken = await this.authService.getUserToken();

      this.httpClient.post<string>(`${this.preferenceUrl}/logConsentMgmntPrefs`, logPreferenceData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          'Authorization': `Bearer ${accessToken}`
        }
      }).pipe(
        map(data => data),
        catchError((error) => throwError(error))
      )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err)
        });
    }));
  }

  async getPreference(getPreferenceData: GetPreferenceData, next: (res: GetPreferenceResponse) => void, error: (err: Error) => void): Promise<void> {
    const accessToken = await this.authService.getUserToken();
    this.httpClient.post<GetPreferenceResponse>(`${this.preferenceUrl}/getConsentMgmntPrefs`, getPreferenceData, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
        'Authorization': `Bearer ${accessToken}`
      }
    }).pipe(
      map(preferenceData => preferenceData),
      catchError((err) => throwError(err))
    )
      .subscribe({
        next,
        error
      });
  }

  getBearerToken(): Promise<Observable<string>> {
    return this.appConfigService.getConfiguration(this.oauth_key, this.oauth_label).then(res =>
      this.httpClient.get<string>(`${this.authUrl}/getBearerApiResponse?code=${res}`)
        .pipe(
          map(token => {
            this.token = token;
            return token;
          }),
          catchError((error) => throwError(error))
        )
    ).catch(error => throwError(error));
  }
}