<div id = "unregisteredPageContainer">
    <div id = "verticalNavContainer">
        <uitk-navigation-primary-vertical>
            <label class="uitk-c-primary-vertical-nav__menu-label">Primary Vertical Menu Label</label>
            <uitk-navigation-panel>
                <uitk-navigation-panel-item>
                    <button routerLink = './' uitkNavButton>
                        Home
                    </button>
                </uitk-navigation-panel-item>
                <uitk-navigation-panel-item>
                    <button routerLink = 'record-upload' uitkNavButton>
                        Record Upload
                    </button>
                </uitk-navigation-panel-item>
                <uitk-navigation-panel-item>
                    <button routerLink = 'self-registration' uitkNavButton>
                        Self Registration
                    </button>
                </uitk-navigation-panel-item>
                <!-- <uitk-navigation-panel-item>
                    <button routerLink = 'faq' uitkNavButton>
                        Frequently Asked Questions
                    </button>
                </uitk-navigation-panel-item> -->
            </uitk-navigation-panel>
        </uitk-navigation-primary-vertical>
    </div>
        
        <main id = "unregisteredMainContainer" tabindex="-1">
            <div id = "routerContainer">
                <router-outlet></router-outlet>
            </div>
        </main>
</div> 