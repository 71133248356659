import { Observable } from 'rxjs';

import TinValues from '../../models/TinValues';
import GuardianResponse from '../../models/GuardianResponse';

export abstract class GuardianService {
  guardianDataAction$: Observable<GuardianResponse[]>;
  abstract getGuardianData(): Promise<GuardianResponse[] | null>;
  abstract setGuardianToken(code: string): void;
  abstract clearGuardianSession(): void;
  abstract getTinValues(): Promise<TinValues | null>;
}