import FilterKey from '../models/FilterKey';
import SearchKey from '../models/SearchKey';

export class GlobalConstants {
  public static APP_TITLE: string = 'Payment Integrity Portal';
  public static ANIMATION_STATE_NAME = 'animationState';
  public static LOGGEDOUT_TEXT: string = 'Logged Out!';
  public static CLAIMS_HOME_TITLE: string = 'Payment Integrity Portal Home';
  public static WFMR_CLAIMS_HOME_TABLE_TITLE: string = 'Claims With Outstanding Medical Record Requests';
  public static ALL_CLAIMS_HOME_TABLE_TITLE: string = 'All Cases';
  public static WFMR_FILTER_CLAIMS_LABEL: string = 'Waiting for Medical Record';
  public static ALL_FILTER_CLAIMS_LABEL: string = 'All Cases';
  public static CONTACT_PRIMARY_NAV_ELEMENT_VALUE: string = 'Contact';
  public static UPLOAD_DOCUMENTS_PRIMARY_NAV_ELEMENT_VALUE: string = 'Upload Documents';
  public static UPLOAD_HISTORY_FOR_CLAIM_TEXT: string = 'Upload History for Claim';
  public static FAQS_PRIMARY_NAV_ELEMENT_VALUE: string = 'FAQs';
  public static GREETING_TEXT: string = 'Welcome,';
  public static HOME_PRIMARY_NAV_ELEMENT_VALUE: string = 'Home';
  public static LOGIN_TEXT: string = 'Login';
  public static LOGOUT_TEXT: string = 'Logout';
  public static MENU_LABEL_TEXT: string = 'Menu Label';
  public static NO_CLAIMS_STATUS_MESSAGE: string = 'There are no claims with outstanding medical record requests. To view all cases, kindly select the \'All Cases\' option from the filter.';
  public static SIGNIN_TEXT: string = 'Sign In';
  public static WELCOME_MESSAGE: string = 'Welcome to Payment Integrity Portal';
  public static CLIENT_SELECT_LABEL: string = 'Please select a client';
  public static VIEW_CLIENT_BUTTON_TEXT: string = 'View Client';
  public static NO_CLIENTS_STATUS_MESSAGE: string = 'Your account is not associated with a client';
  public static ACCESS_DENIED_START_TEXT: string = 'Access Denied.';
  public static ACCESS_DENIED_END_TEXT: string = 'You don\'t have permission to view this page.';
  public static FOOTER_TEXT: string = 'Your use of this product is governed by the terms of your company agreement. You may not use or disclose this product or allow others to use it or disclose it, except as permitted by your agreement with Optum.';
  public static ERROR_MESSAGE: string = 'Application unavailable. Please try again later.';
  public static CLIENT_SIDE_ERROR_TEXT: string = 'Clientside Error';
  public static SERVER_SIDE_ERROR_TEXT: string = 'Server Error';
  public static UNAUTHORIZED_ERROR_TEXT: string = 'Unauthorized Error';
  public static ERROR_TEXT: string = 'Error';
  public static UNAUTHORIZED_ERROR_MESSAGE: string = 'User does not have access to the application.';
  public static REQUEST_ACCESS_MESSAGE: string = 'Please reach out to PI_Portal_Support@optum.com for access.';
  public static GUARDIAN_DATA_KEY: string = 'USER_GUARDIAN_DATA_OBJ';
  public static UNAUTHORIZED_GUARDIAN_MESSAGE: string = 'User not found';
  public static RELATION_ID_NOT_PRESENT_MESSAGE: string = 'Required request parameter \'relationIds\' for method parameter type List is not present';
  public static CLIENT_KEY: string = 'ID_CLIENT_OBJ';
  public static TIN_VALUES_KEY: string = 'TIN_VALUES_OBJ';
  public static AUTHENTICATION_LOADING_MESSAGE: string = 'Authentication in progress...';
  public static CLIENTS_LOADING_MESSAGE: string = 'Retrieving client data...';
  public static CLAIMS_LOADING_MESSAGE: string = 'Loading claims...';
  public static INFO_NOTIFICATION_TYPE: string = 'info';
  public static SUCCESS_NOTIFICATION_TYPE: string = 'success';
  public static ERROR_NOTIFICATION_TYPE: string = 'error';
  public static WARNING_NOTIFICATION_TYPE: string = 'warning';
  public static INACTIVE_MESSAGE: string = 'You have been logged out of the application after 30 minutes of inactivity';
  public static UPLOAD_MEDICAL_RECORD_TEXT: string = 'Upload medical record';
  public static ACCEPTABLE_FILE_TEXT: string = 'You may upload one PDF medical record at a time with a maximum file size of 200MB. If you would like to upload a file that doesn\'t meet this specification, you may upload through  ';
  public static UPLOAD_BUTTON_TEXT: string = 'Upload';
  public static CANCEL_BUTTON_TEXT: string = 'Cancel';
  public static CLOSE_BUTTON_TEXT: string = 'Close';
  public static PATIENT_AC_NUMBER_TEXT: string = 'Patient Account Number: ';
  public static CLAIM_NUMBER_TEXT: string = 'Claim Number: ';
  public static FILE_UPLOAD_MSG: string = 'Please upload files below: ';
  public static NOT_SUPPORTED_FILE: string = 'This file type is not supported';
  public static DROP_FILE_TEXT: string = 'Drop Single file here or  ';
  public static BROWSE_FILES: string = 'Browse for file';
  public static READY_TO_UPLOAD_TEXT: string = 'ready to upload';
  public static NO_FILES_TEXT: string = 'No files';
  public static NO_OF_FILES_READY_TO_UPLOAD_TEXT: string = 'Ready to upload';
  public static FILES_TEXT: string = 'file(s)';
  public static FILES_UPLOADED_MSG: string = 'uploaded';
  public static ERROR_MSG_FILE_UPLOAD: string = 'Please remove the file and try again later.';
  public static FILE_UPLOAD_FAILURE_TEXT: string = 'File upload failure.';
  public static UPLOADING_TEXT: string = 'Uploading...';
  public static UPLOADED_TEXT: string = 'Your document has been successfully uploaded';
  public static DONE_TEXT: string = 'done';
  public static PROGRESS_TEXT: string = 'progress';
  public static HYPERLINK_TEXT: string = 'Databank.';
  public static MR_UPLOAD_STATUS_NOT_STARTED_TEXT: string = 'Not Started';
  public static ZERO_BYTES_TEXT: string = '0 Bytes';
  public static GET_LETTERS_INVENTORY_JWT_TOKEN_KEY: string = 'JWT_TOKEN_KEY';
  public static GET_LETTERS_INVENTORY_JWT_TOKEN_LABEL: string = 'jwt_token_label';
  public static LOG_MRUPLOAD_FUNCTIONS_KEY: string = 'FUNCTION_KEY_LOG_MRUPLOAD_STATUS';
  public static LOG_MRUPLOAD_FUNCTIONS_KEY_LABEL: string = 'log_mrupload_status_key';
  public static REQUIRED_ERROR_TEXT: string = 'Input is required';
  public static SPINNER_SIZE_LARGE: string = 'large';
  public static TRANSFER_STATUS_FOR_IDLE_CHECK_KEY: string = 'TRANSFER_STATUS_FOR_IDLE_CHECK';
  public static NO_DATA_TRANSFER_STATUS_FOR_IDLE_CHECK: string = 'notTransferring';
  public static DATA_TRANSFERING_STATUS_FOR_IDLE_CHECK: string = 'dataTransferring';
  public static DATA_TRANSFERRED_STATUS_FOR_IDLE_CHECK: string = 'dataTransferred';
  public static EASE_OUTTEXT: string = '1s ease-out';
  public static EXPORT_CLAIMS_TEXT: string = 'Export Claims';
  public static EPORT_DIALOG_HEADER_TEXT: string = 'Export Claims';
  public static EXPORT_CLAIMS_USER_MESSAGE_TEXT: string = 'This will export currently filtered cases. Please click download to continue.';
  public static DOWNLOAD_BUTTON_TEXT: string = 'Download';
  public static CSV_EXTENSION: string = '.csv';
  public static CSV_TYPE: string = 'text/plain;charset=utf-8';
  public static EXPORT_DEFAULT_PAGE_NO: number = 0;
  public static EXPORT_DEFAULT_PAGINATION_Size: number = 0;
  public static CSV_FILE_NAME: string = "Exported Claims List";
  public static NO_DATA_DISPLAYED_TEXT: string = 'No data to display';
  public static SEARCH_BY_TEXT: string = 'Search by ';
  public static PATIENT_ACCOUNT_NO_TEXT: string = 'Patient Account Number ';
  public static CLAIM_ID_TEXT: string = 'Claim Number ';
  public static PATIENT_NAME_TEXT: string = 'Patient Name ';
  public static TIN_TEXT: string = 'TIN';
  public static SEARCH_DROPDOWN_DEFAULT_VALUE: SearchKey = { label: 'Select', value: 'Select' };
  public static FILTER_DROPDOWN_DEFAULT_VALUE: FilterKey = { label: 'Waiting for Medical Record', value: '[0]' };
  public static SEARCH_PREPAY_TEXT: string = 'Search Claims';
  public static FILTER_TEXT: string = 'Filter Claims';
  public static SEARCH_HEADER_TEXT: string = 'Please select an option to search on';
  public static FILTER_HEADER_TEXT: string = 'Choose a status to filter on';
  public static INVALID_INPUT_ERROR_MESSAGE: string = 'Please provide valid Input';
  public static TIN_ERROR_MESSAGE: string = 'Please provide valid TIN';
  public static CLAIM_NUMBER_ERROR_MESSAGE: string = 'Please provide valid Claim number';
  public static PATIENT_ACCOUNT_NUMBER_ERROR_MESSAGE: string = 'Please provide valid Patient Account number';
  public static CLEAR_SEARCH_TEXT: string = 'Clear Search';
  public static CSV_COLUMN_HEADERS: string[] = ["claimNumber", "patientAccountNumber", "recipientLastName", "recipientFirstName", "recipientMiddleInitial",
   "tin",  "medicalRecordRequestDate", "patientDoB", "startDateOfService", "providerName", "mrStatus"];

  public static LETTER_TYPE_MRR = "I";
  public static TAGLINE_MSG: string = 'Web portal for authorized clients and providers, enabling them to view claims which require medical record submissions.';
  public static HELP_LINE_MSG: string = 'For questions or support please email';
  public static PREPAY_TEXT: string = 'prepay';
  public static DOCUMENT_CLASSIFIER_TEXT: string = 'MEDICAL RECORD';
  public static DOCUMENT_MIME_TYPE_TEXT: string = 'application/pdf';
  public static DOCUMENT_CREATED_BY_TEXT: string = 'Clinical Portal';
  public static EXTERNAL_SYSTEM_TEXT: string = 'DMS';
  public static DOCUMENT_TYPE_CODE_TEXT: string = '00';
  public static ST_AC_HTTPOPTIONS_HEADER_XMS_BLOB_TYPE_KEY: string = 'x-ms-blob-type';
  public static ST_AC_HTTPOPTIONS_HEADER_XMS_BLOB_TYPE: string = 'BlockBlob';
  public static ST_AC_HTTPOPTIONS_HEADER_CONTENT_TYPE_KEY: string = 'Content-Type';
  public static ST_AC_HTTPOPTIONS_HEADER_CONTENT_TYPE_VALUE: string = 'application/pdf';
  public static RECORD_NUMBER: string = '1';
  public static STATE_OF_AUDIT_TEXT: string = 'F1';
  public static USER_PROFILE_DATA_KEY: string = 'USER_PROFILE';
  public static GET_GUARDIAN_FUNCTION_KEY: string = 'FUNCTION_KEY_GET_GUARDIAN_USERINFO';
  public static GET_GUARDIAN_FUNCTION_LABEL: string = 'get_guardian_user_info_key';
  public static NO_CLAIMS_MATCH_MESSAGE: string = '0 Claims match your criteria';
  public static PAGINATION_PAGE_ONE: number = 1;
  public static IDP_HINT_TEXT: string = 'optum-id';
  public static PROVIDER_PREFERENCES_TEXT: string = 'Provider Preferences';
  public static PROVIDER_DETAILS_TEXT: string = 'Provider Details';
  public static PROVIDER_DETAILS_CONTENT: string = '';
  public static PROVIDER_NAME_TEXT: string = 'Provider Name';
  public static DELIVERY_HELPER_TEXT: string = 'Please choose a delivery method to proceed further.';
  public static PAPER_TEXT: string = 'paper';
  public static DIGITAL_TEXT: string = 'digital';
  public static EDIT_PREFERENCES_TEXT: string = 'Edit Preferences';
  public static EMAIL_ADRRESS_TEXT: string = 'Email Address';
  public static EMAIL_HELPER_TEXT: string = 'This email address will be used for digital delivery. Only one email address is allowed per provider, and all digital deliveries will be sent to this address.';
  public static DELIVERY_ERROR_TEXT: string = 'Please select at least one delivery option.';
  public static DELIVERY_PREFERENCES_TEXT: string = 'Delivery Preferences';
  public static EMAIL_NOTIFICATION_FREQUENCY: string = 'daily';
  public static EMAIL_SENT_SUCCESS_MESSAGE: string = 'An OTP has been sent to the provided email address. Please check your email.';
  public static VERIFY_TEXT: string = 'Verify';
  public static ENTER_OTP_MESSAGE: string = 'Please enter the OTP received in the email.';
  public static ENTER_OTP: string = 'Please enter a valid OTP.';
  public static OTP_VALIDATED_MSG: string = 'OTP is validated. Please click Save to update your preferences';
  public static OTP_NOT_MATCHING_MSG: string = 'The OTP entered is invalid. Please provide a valid OTP.';
  public static VERIFY_OTP_TEXT: string = 'Verify OTP';
  public static RESEND_EMAIL_TEXT: string = 'Resend Email';
  public static SAVE_TEXT: string = 'Save';
  public static DELIVERY_PREFERENCE_UPDATED: string = 'Your delivery preference has been updated successfully.';
  public static OTP_TEXT: string = 'OTP';
  public static DELIVERY_METHOD_HELPER_TEXT: string = 'The delivery method you preferred is:';
  public static EMAIL_DISPLAY_HELPER_TEXT: string = 'This email address will serve as the designated recipient for the delivery.';
  public static PAPER_DELIVERY_TEXT: string = 'Paper delivery';
  public static DIGITAL_DELIVERY_TEXT: string = 'Digital delivery';
  public static OAUTH_TOKEN_KEY: string = 'OAUTH_TOKEN_FUNCTION_KEY';
  public static OAUTH_TOKEN_LABEL: string = 'oauth_token_key';
  public static OTP_SUCCESS_MESSAGE: string = 'OTP verification has passed';
  public static PREFERENCE_LOADING_MESSAGE: string = 'Loading preferences...';
  public static OTP_INVALID_MSG: string = 'The OTP provided is not valid.';
  public static OTP_EXPIRED_MSG: string = 'The OTP has expired.';
  public static EMAIL_ALREADY_VALIDATED: string = 'The email address has already been validated and currently exists in the existing record.';
  public static CREATED_TEXT: string = 'created';
  public static DELETED_TEXT: string = 'deleted';
  public static MODIFIED_TEXT: string = 'modified';
  public static EMAIL_FIELD_EMPTY_MSG: string = 'Please provide the valid email Address';
  public static ERROR_MSG_SENDING_EMAIL: string = 'Error occured when sending OTP to your email address. Please try again later';
  public static ACTION_FOR_NO_EMAIL_MESSAGE: string = 'If you haven\'t received an email within 15 minutes, please check your junk mail.';
  public static REACHOUT_MESSAGE: string = 'If it\'s not there, click \'Resend Email\' or reach out to PI_Portal_Support@optum.com for assistance.';
  public static FAILED_TO_UPDATE_MESSAGE: string = 'Failed to update preferences. Please try again.';
  public static FAILED_TO_SEND_OTP_MESSAGE: string = 'Failed to send OTP. Please try again.';
  public static NEW_OTP_SENT_MESSAGE: string = 'New OTP has been sent to your email successfully. Please check your email.';
  public static EMAIL_ERROR_TEXT: string = 'Please enter a valid email address';
  public static FILE_UPLOAD_FUNCTION_KEY: string = 'FUNCTION_KEY_GET_ODI_FILE_UPLOAD';
  public static FILE_UPLOAD_FUNCTION_LABEL: string = 'get_odi_api_file_upload_label';
  public static GET_ODI_DOC_DETAILS_KEY: string = 'FUNCTION_KEY_GET_ODI_DOC_DETAILS';
  public static GET_ODI_DOC_DETAILS_LABEL: string = 'getODIDocDetails_label';
  public static DOCUMENT_INFO_TABLE_HEADING_CLASSIFIER: string = 'Document Classifier';
  public static DOCUMENT_INFO_TABLE_HEADING_DATE_ADDED: string = 'Date Added';
  public static DOCUMENT_INFO_TABLE_HEADING_URL: string = 'Download URL';
  public static DOCUMENT_INFO_TABLE_HEADING_FILE_NAME: string = 'File Name';
  public static DOCUMENT_INFO_TABLE_HEADING_DOWNLOAD: string = 'Download';
  public static PATIENT_AC_NAME_TEXT: string = 'Patient Name: ';
  public static DOCUMENTS_TEXT: string = 'Documents';
  public static DOCUMENT_DETAILS_TEXT: string = 'Document Details';
  public static DOWNLOAD_ERROR_MESSAGE: string = 'Error Downloading File: ';
  public static FILE_SIZE_PLACEHOLDER: string = 'calculating...';
  public static DOWNLOADING_STRING: string = 'Downloading... ';
  public static DOWNLOADED_STRING: string = 'File Downloaded! ';
  public static DIALOG_BOX_TRIGGER_ELEMENT_ID_TEXT: string = 'doc_info_Dialog_closeBtn';
  public static SIZE_WITH_COLON_TEXT: string = 'Size: ';
  public static GET_MRUPLOAD_FUNCTIONS_KEY: string = 'FUNCTION_KEY_GET_MRUPLOAD_STATUS';
  public static GET_MRUPLOAD_FUNCTIONS_KEY_LABEL: string = 'get_mrupload_status_key';
  public static GET_DOWNLOAD_RESPONSE_KEY: string = 'FUNCTION_KEY_GET_ODI_FILE_DOWNLOAD_RESPONSE_KEY';
  public static GET_DOWNLOAD_RESPONSE_LABEL: string = 'getODIAPIFileDownloadResponse_label';
  public static NO_DOC_AVAILABLE: string = 'No Documents available.';
  public static DOWNLOADING_MESSAGE: string = 'Please wait, downloading is in progress.....';
  public static DOWNLOADING_LOADING_MESSAGE: string = 'Loading Document...';
  public static SPINNER_SIZE_SMALL: string = 'small';
  public static FILE_DELETE_FUNCTION_KEY: string = 'FUNCTION_KEY_DELETE_ODI_FILE';
  public static FILE_DELETE_FUNCTION_LABEL: string = 'delete_odi_api_file_label';
  public static MAX_FILE_LIMIT: number = 214958080;
  public static MB_50_SIZE: number = 50000000;
  public static EXCEEDED_FILE_SIZE_MSG: string = 'File size limit exceeded';
  public static LARGE_FILE_UPLOAD_MSG: string = 'Large documents may take some time to upload.'
  public static LARGE_FILE_DOWNLOAD_MSG: string = 'Large documents may take some time to download.'
  public static ERROR_NO_USER_FOUND: string = 'User not found'
  public static SELF_REGISTRATION_REDIRECT: string = `User is currently unauthorized. Redirecting to self-registration.`
  public static SELF_REGISTRATION_ACTIVATION_NOTICE: string = `Your One Healthcare ID has not been fully registered with the Payment Integrity Portal.`
  public static SELF_REGISTRATION_REDIRECT_NOTICE: string = `Please validate the information below to continue to Self Registration:`
  public static SELF_REGISTRATION_APPROVAL_NOTICE: string = `If you have completed the self registration form already, your registration for the PI Portal is awaiting approval by Optum Operations personnel, you can reach out to PI_Portal_Support@optum.com for any questions.`
  public static AUTHORIZATION_LABEL_TEXT: string = 'Authorization Code';
  public static RECORD_BARCODE_LABEL_TEXT: string = 'Record Bar Code';
  public static FIRST_DATE_OF_SERVICE_LABEL_TEXT: string  = 'First Date Of Service';
  public static AUTHORIZATION_CODE_REQUIRED_TEXT: string = 'Authorization Code is required';
  public static RECORD_BARCODE_REQUIRED_TEXT: string = 'Record Bar Code is required';
  public static FIRST_DATE_OF_SERVICE_REQUIRED_TEXT = 'First Date of Service is required';
  public static TIN_REQUIRED_TEXT: string = 'Tin is required';
  public static TIN_LABEL_TEXT: string = 'TIN';
  public static DATE_FORMAT_TEXT: string = 'Date format is invalid';
  public static TIN_HELP_TEXT: string = 'Numeric value to identify your organization';
  public static AUTHORIZATION_HELP_TEXT: string = 'Alpha-numeric value found on the instruction sheet provided with your letter';
  public static BAR_CODE_HELP_TEXT: string = 'Alpha-numeric value found below the barcode on the letter barcode cover sheet';
  public static DATE_OF_SERVICE_HELP_TEXT: string = 'First date of service from your letter';
  public static VERIFICATION_LOADING_TEXT: string = 'Verifying form...';
  public static VERIFICATION_ERROR_MESSAGE: string = 'The information you have submitted has returned a failed verification.';
  public static EMAIL_VERIFICATION_ERROR_MESSAGE: string = 'There has been an internal error with the verification process. Please try again.';
  public static UPLOAD_ERROR_MESSAGE: string = 'Upload request has been unsuccessful.';
  public static MANUAL_UPLOAD_SUCCESS_MESSAGE: string = 'Upload request has been successfully submitted.';
  public static CLAIM_UPLOAD_SUCCESS_MESSAGE: string = 'The upload request has been successfully submitted, and the document is being processed. Please allow a few minutes for changes to be fully reflected on the list of claims with outstanding medical record requests. The page will now be refreshing...'
  public static UPLOAD_TOOLTIP_TEXT: string = 'Your upload is in progress...';
}