export function formatDateString(date: string | Date): string {
  if (typeof date === 'string' && /^\d{8}$/.test(date)) {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${month}-${day}-${year}`;
  }
  const strDateValue = date instanceof Date ? date.toISOString().split('T')[0] : date.toString();
  const [year, month, dateValue] = strDateValue.split(/[-T]/);
  return `${month}-${dateValue}-${year}`;
}

function getDateMonthFormat(n: number): string | number {
  return n < 10 ? `0${n}` : n;
}

export function formatFileCreationDate(date: Date): string {
  const year = date.getFullYear();
  const month = getDateMonthFormat(date.getMonth()+1);
  const dateValue =  getDateMonthFormat(date.getDate());
  return `${year}-${month}-${dateValue}`;
}

export function formatFileCreationDateNoHyphen(date: Date): string {
  const year = date.getFullYear();
  const month = getDateMonthFormat(date.getMonth()+1);
  const dateValue =  getDateMonthFormat(date.getDate());
  return `${year}${month}${dateValue}`;
}

export function formatFileToYYYYMMDD(date: Date | string): string {
  const strDateValue = date.toString();
  const month = strDateValue.split('-')[0];
  const dateValue = strDateValue.split('-')[1];
  const year =  strDateValue.split('-')[2];
  return `${year}-${month}-${dateValue}`;
}