<body>
  <div class="uitk-l-grid client-select-container">

    <div class="uitk-l-grid__row centered__shadowed">
      <ng-container *ngIf="!isLoading else loading">
        <div *ngIf="hasMultipleClients" class="uitk-l-grid__col uitk-u-flex__align--center">
          <legend>
            <h1>{{ greetingText }} {{ userName }}</h1>
          </legend>
          <div class="uitk-l-grid__col">
            <form [formGroup]="clientSelectForm">
              <uitk-form-field>
                <label uitkLabel>{{ clientSelectLabel }}</label>
                <uitk-dropdown class="client-select-dropdown" formControlName="clientDropdownControl" uitkId="clientSelectionDropdown"
                  name="clientSelectionList"
                  (onChange)="onClientSelectChange($event)" required [isMultiSelect]="false" [items]="clientOptionsList"
                  [enableSelectFlag]="false">
                </uitk-dropdown>
              </uitk-form-field>
            </form>
            <button id="ghost-btn" type="button" [disabled]="!clientSelectForm.valid"
              class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
              (click)="selectClient(selectedClientOption)">
              {{ viewClientButton }}
            </button>
          </div>
        </div>
        <div *ngIf="isClientListEmpty" class="uitk-l-grid__col centered__shadowed">
          <legend><h1>{{ noClientsStatusMessage }}</h1></legend>
        </div>
      </ng-container>
      <ng-template #loading>
        <app-loading-spinner [loadingMessage]="clientsLoadingMessage" [spinnerSize]="spinnerSize"></app-loading-spinner>
      </ng-template>
    </div>
  </div>
  <script type="text/javascript">
        (function (window, document, config) {
        var scriptElement = document.createElement('script');
        var scriptUrl = "https://js.monitor.azure.com/scripts/b/ai.2.min.js";

        function handleSdkLoadFailure(message) {
            sendTelemetry("ExceptionData", message);
        }

        function handleOnError(message, url, lineNumber, columnNumber, error) {
            var handled = originalOnError && originalOnError(message, url, lineNumber, columnNumber, error);
            if (!handled) sendTelemetry("_onerror", message);
            return handled;
        }

        function onLoadScript() {
            var appInsights = window[config.name];
            if (appInsights && appInsights.queue && !appInsights.queue.length) {
                appInsights.queue.push(() => {
                    config.onInit && config.onInit(appInsights);
                    appInsights.trackPageView({});
                });
            } else config.onInit && config.onInit(appInsights);
        }

        function onErrorScript() {
            handleSdkLoadFailure(`SDK LOAD Failure: Failed to load Application Insights SDK script (See stack for details) (${scriptUrl})`);
        }

        function appendScriptToHead() {
            var head = document.head || document.getElementsByTagName("head")[0];
            if (!config.ld || config.ld < 0) head.appendChild(scriptElement);
            else setTimeout(() => head.appendChild(scriptElement), config.ld || 0);
        }

        function setSdkFunctions(functions) {
            functions.forEach(func => {
                appInsights[func] = function () {
                    if (!config.disableExceptionTracking || func !== "_onerror") {
                        appInsights.queue.push(() => appInsights[func].apply(appInsights, arguments));
                    }
                };
            });
        }

        // Initial setup
        var originalOnError = window.onerror;
        window.onerror = handleOnError;

        scriptElement.src = scriptUrl;
        scriptElement.onload = onLoadScript;
        scriptElement.onerror = onErrorScript;

        config.crossOrigin = config.crossOrigin || "anonymous";
        appendScriptToHead();

        setSdkFunctions([
            "trackEvent", "trackPageView", "trackException", "trackTrace", "trackDependency",
            "trackMetric", "trackPageViewPerformance", "startTrackPage", "stopTrackPage",
            "startTrackEvent", "stopTrackEvent", "addTelemetryInitializer",
            "setAuthenticatedUserContext", "clearAuthenticatedUserContext", "flush"
        ]);

    }(window, document, {
        name: "appInsights",
        ld: 0,
        useXhr: 1,
        crossOrigin: "anonymous",
        onInit: null,
        cfg: {
            connectionString: "InstrumentationKey=fb006c0a-fd84-459b-abeb-692e39b8942e;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/"
        }
    }));
  </script>
</body>