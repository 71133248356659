<div class="uitk-l-grid welcome-container" *ngIf="!isLoading else loading">
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col main">
      <div class="main__form">
        <legend class="main__form__title">{{ title }}</legend>
        <span *ngIf="isProduction || isLocal" class="main__form__tagline">{{ taglineMsg }}<div></div><br>
          <span class="obfuscate"> {{ helpLineMsg }} <span>null</span>{{ emailUserName }}<span>
            null</span>{{ emailSymbol }}<span>null</span>{{ emailDomainName }}<span>null</span>{{ emailDomain }}</span>
        </span>
      </div>
      <button class="uitk-c-button uitk-c-button--default uitk-l-grid__col main__button" (click)="loginUser()">{{ signInText }}</button>
    </div>
  </div>
</div>
<ng-template #loading>
  <app-loading-spinner class="loading-spinner" [loadingMessage]="authLoadingMessage" [spinnerSize]="spinnerSize"></app-loading-spinner>
</ng-template>