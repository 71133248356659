import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-files-progress',
  templateUrl: './files-progress.component.html',
  styleUrls: ['./files-progress.component.scss']
})

export class FilesProgressComponent {

  @Input() progress = 0;
}