import { Component } from '@angular/core';

@Component({
  selector: 'app-unregistered',
  templateUrl: './unregistered.component.html',
  styleUrls: ['./unregistered.component.scss']
})
export class UnregisteredComponent {

}

