import { Component, OnInit } from '@angular/core';
import { UITKHeadingLevel } from '@uitk/angular';
import { Router } from '@angular/router';

import GuardianResponse from '../../shared/models/GuardianResponse';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';
import GetPreferenceData from '../../shared/models/GetPreferenceData';
import { ProviderPreferenceService } from '../../shared/services/provider-preference.service';
import { GuardianService } from '../../shared/services/abstracts/guardian.service';
import { ClientService } from '../../shared/services/client.service';
import { getProviderName } from '../../shared/helpers/GuardianHelper';

@Component({
  selector: 'app-provider-preference',
  templateUrl: './provider-preference.component.html',
  styleUrls: ['./provider-preference.component.scss']
})
export class ProviderPreferenceComponent implements OnInit {
  headingLevel = UITKHeadingLevel;
  providerDetailsContent: string;
  providerDetailsText: string;
  collapsiblePanel: boolean;
  openPanel: boolean;
  deliveryHelperText: string;
  paperDeliveryText: string;
  digitalDeliveryText: string;
  editPreferenceText: string;
  providerPreferenceText: string;
  emailAddressText: string;
  emailHelperText: string;
  emailErrorText: string;
  deliveryErrorText: string;
  deliveryPreferencesText: string;
  isPaperDeliverySelected: boolean;
  isDigitalDeliverySelected: boolean;
  email: string;
  isLoading: boolean;
  spinnerSize: string;
  preferenceLoadingMessage: string;
  providerNameText: string;
  providerName: string;

  constructor(
    private readonly router: Router,
    private readonly providerPreferenceService: ProviderPreferenceService,
    private readonly guardianService: GuardianService,
    private readonly clientService: ClientService
  ) {
    this.providerDetailsContent = GlobalConstants.PROVIDER_DETAILS_CONTENT;
    this.providerDetailsText = GlobalConstants.PROVIDER_DETAILS_TEXT;
    this.collapsiblePanel = true;
    this.openPanel = true;
    this.deliveryHelperText = GlobalConstants.DELIVERY_METHOD_HELPER_TEXT;
    this.editPreferenceText = GlobalConstants.EDIT_PREFERENCES_TEXT;
    this.providerPreferenceText = GlobalConstants.PROVIDER_PREFERENCES_TEXT;
    this.emailAddressText = GlobalConstants.EMAIL_ADRRESS_TEXT;
    this.emailHelperText = GlobalConstants.EMAIL_DISPLAY_HELPER_TEXT;
    this.deliveryErrorText = GlobalConstants.DELIVERY_ERROR_TEXT;
    this.deliveryPreferencesText = GlobalConstants.DELIVERY_PREFERENCES_TEXT;
    this.isPaperDeliverySelected = false;
    this.isDigitalDeliverySelected = false;
    this.paperDeliveryText = GlobalConstants.PAPER_DELIVERY_TEXT;
    this.digitalDeliveryText = GlobalConstants.DIGITAL_DELIVERY_TEXT;
    this.isLoading = false;
    this.spinnerSize = GlobalConstants.SPINNER_SIZE_LARGE;
    this.preferenceLoadingMessage = GlobalConstants.PREFERENCE_LOADING_MESSAGE;
    this.providerNameText = GlobalConstants.PROVIDER_NAME_TEXT;
    this.providerName = '';
  }
   
  async ngOnInit(): Promise<void> {
    await this.initializePreference();
  }

  async initializePreference(): Promise<void> {
    try {
      this.isLoading = true;
      const guardianData: GuardianResponse[] | null = await this.guardianService.getGuardianData();
      if (guardianData) {
        this.providerName = getProviderName(guardianData)?.providerName[1];
        const getPreferenceData: GetPreferenceData = {
          clientName: this.clientService.getCurrentClient().clientName?.toLowerCase(),
          tin: (await this.guardianService.getTinValues())?.tin[0]
        };
        this.providerPreferenceService.getPreference(getPreferenceData, res => {
          if (res && res.deliveryOption) {
            this.isLoading = false;
            this.isPaperDeliverySelected = (res.deliveryOption[0] !== GlobalConstants.DIGITAL_TEXT);
            this.isDigitalDeliverySelected = (res.deliveryOption[0] === GlobalConstants.DIGITAL_TEXT);
            this.email = res.emailAddress;
          }
        }, error => {
          this.isLoading = false;
          throw error;
        });
      }
    } catch (error) {
      console.error('Error in initializePreference:', error);
      throw error;
    }
  }

  getEditPreferencePage(): void {
    this.router.navigate(['edit-preference']);
  }
}