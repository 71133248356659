import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import Environment from '../models/Environment';
import Claim from '../models/Claim';
import { formatDateString } from '../helpers/DateHelper';
import DocumentInfo from '../models/DocumentInfo';
import { AppConfigService } from './app-config.service';
import { SearchCriteriaDocumentsInfo } from '../models/SearchCriteriaDocumentsInfo';
import { ClaimDetailsResponseEnteries } from '../models/ClaimDetailsResponseEnteries';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import ChunkDocList from '../models/ChunkDocList';
import { AuthService } from './abstracts/auth.service';

/**
 * Service responsible for handling document-related operations.
 *
 * This service communicates with the server to fetch document details and processes the response
 * to provide a formatted list of documents. It also supports publishing documents to subscribers
 * through the `documentInfoAction$` observable.
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentInfoService {
  env: Environment;
  documentDetailsEndpoint: string;
  label: string;
  functionKey: string;
  downloadLabel: string;
  downloadFunctionKey: string;
  jwtToken: string;

  private documentInfoSubject = new Subject<Claim>();
  public documentInfoAction$ = this.documentInfoSubject.asObservable();

  constructor(
    private readonly httpClient: HttpClient, 
    private readonly appConfigService: AppConfigService,
    private readonly authService: AuthService
  ) {
    this.env = environment;
    this.functionKey = GlobalConstants.GET_ODI_DOC_DETAILS_KEY;
    this.label = GlobalConstants.GET_ODI_DOC_DETAILS_LABEL;
    this.documentDetailsEndpoint = environment.guardian.optumGuardianUrl;
  }

  async retrieveToken(): Promise<string> {
    await this.authService.isAuthenticated();
    await this.authService.getKeyCloakResponse();
    const code = this.authService.getToken();
    return code;
  }
  
  /**
  * Queries the claim details based on the provided search criteria documents.
  *
  * @param searchCriteriaDocuments The search criteria for fetching claim details.
  * @return A Promise that resolves to an Observable of ClaimDetailsResponseEnteries.
  */
  async queryGetClaimDetails(searchCriteriaDocuments: SearchCriteriaDocumentsInfo): Promise<Observable<ClaimDetailsResponseEnteries>> {
    const { baseUrl, endpointGetClaimDocuments } = this.env.lettersInventory;
    this.jwtToken = (!this.jwtToken || this.jwtToken.length === 0) ? await this.retrieveToken() : this.jwtToken;
      return this.httpClient
        .post<ClaimDetailsResponseEnteries>(
          `${baseUrl}${endpointGetClaimDocuments}legacy=true`,
          searchCriteriaDocuments,
          {headers: {
            "Authorization": `Bearer ${this.jwtToken}`
          }}
        )
        .pipe(
          map((response) => {
            console.log("docdetails");
            console.log(response);
            if (response.chunkDocList && response.chunkDocList.length !== 0 && response.otherDocList[0]?.chunkData?.length !== 0) {
              this.updateChunkData(response.chunkDocList);
              this.updateOtherDocList(response.otherDocList);
            } else {
              response.claimDetailsResult = [];
            }
            return { ...response };
          }),
          map((response) => {
            const formattedDocumentInfo: DocumentInfo[] = this.formatDocumentInfo(response);
            return { ...response, claimDetailsResult: formattedDocumentInfo };
          }),
          catchError((error) => throwError(error))
        );
  }
  
  /**
  * Updates the receiptDate property for each chunkData in the provided chunkDocList.
  *
  * @param chunkDocList The list of ChunkDocList containing chunkData to be updated.
  */
  private updateChunkData(chunkDocList: ChunkDocList[]): void {
    chunkDocList.forEach((chunkDoc) => {
      if (chunkDoc.chunkData && chunkDoc.chunkData.length !== 0) {
        chunkDoc.chunkData.forEach((chunkData, index) => {
          chunkDoc.chunkData[index] = {
            ...chunkData,
            receiptDate: chunkData.receiptDate
          };
        });
      }
    });
  }
  
  /**
  * Updates the receiptDate property for each chunkData in the provided otherDocList, if the property exists.
  *
  * @param otherDocList The list of ChunkDocList containing chunkData to be updated.
  */
  private updateOtherDocList(otherDocList: ChunkDocList[]): void {
    otherDocList.forEach((otherDoc) => {
      if (otherDoc.chunkData && otherDoc.chunkData.length !== 0) {
        otherDoc.chunkData.forEach((otherDocData, index) => {
          if (otherDocData && otherDocData.receiptDate) {
            otherDoc.chunkData[index] = {
              ...otherDocData,
              receiptDate: otherDocData.receiptDate
            };
          }
        });
      }
    });
  }
  
  /**
  * Formats the document information from the provided response, combining multi-part documents into single entries.
  *
  * @param response The response containing chunkDocList and otherDocList.
  * @return An array of DocumentInfo objects representing the formatted document information.
  * Each DocumentInfo object includes the document name, classifier, added date, and an array of download file data.
  * The download file data array includes information about each chunk, such as chunk number, chunk document name, chunk document ID, and rendition ID.
  * If a document is multi-part, the chunks are combined into a single entry using the document's base filename.
  */
  private formatDocumentInfo(response: ClaimDetailsResponseEnteries): DocumentInfo[] {
    const formattedChunkDocumentInfo: DocumentInfo[] = response.chunkDocList.reduce((documentsArray, chunkDoc) => {
      const documents: DocumentInfo[] = chunkDoc.chunkData.reduce((fileArray, chunkData) => {
        const isMultiPart: RegExpMatchArray | null = chunkData.chunkDocName.match(/\s\d+ of \d+(\.\w+)?$/);
        let baseFilename: string;
        let extractedExtension: string;
        if (isMultiPart) {
          extractedExtension = chunkData.chunkDocName.match(/(\.\w+)$/) ? chunkData.chunkDocName.match(/(\.\w+)$/)[1] : '.pdf';
          baseFilename = chunkData.chunkDocName.replace(/\s\d+ of \d+(\.\w+)?$/, '') + extractedExtension;
        } else {
          extractedExtension = '.pdf';
          const hasExtension = chunkData.chunkDocName.match(/(\.\w+)$/);
          baseFilename = hasExtension ? chunkData.chunkDocName : chunkData.chunkDocName + extractedExtension;
        }
        const existingFile = fileArray.find(file => file.documentName === baseFilename);
        if (existingFile) {
          existingFile.downloadFileData.push({
            chunkNo: chunkData.chunkNo,
            chunkDocName: chunkData.chunkDocName,
            chunkDocId: chunkData.chunkDocId,
            renditionId: chunkData.renditionId
          });
        } else {
          fileArray.push({
            documentName: baseFilename,
            documentClassifier: chunkData.documentClassifier,
            documentAddedDate: formatDateString(chunkData.receiptDate),
            downloadFileData: [
              {
                chunkNo: chunkData.chunkNo,
                chunkDocName: chunkData.chunkDocName,
                chunkDocId: chunkData.chunkDocId,
                renditionId: chunkData.renditionId
              }
            ]
          });
        }
        return fileArray;
      }, []);
      return documentsArray.concat(documents);
    }, []);
    const formattedOtherDocumentInfo: DocumentInfo[] = response.otherDocList.reduce((documentsArray, otherDoc) => {
      const documents: DocumentInfo[] = otherDoc.chunkData.map((chunkData) => {
        const baseFilename = chunkData.chunkDocName.replace(/\s\d+ of \d+$/, '');
        return {
          documentName: baseFilename,
          documentClassifier: chunkData.documentClassifier,
          documentAddedDate: formatDateString(chunkData.receiptDate),
          downloadFileData: [
            {
              chunkNo: chunkData.chunkNo,
              chunkDocName: chunkData.chunkDocName,
              chunkDocId: chunkData.chunkDocId,
              renditionId: chunkData.renditionId
            }
          ]
        };
      }).filter(Boolean);
      return documentsArray.concat(documents);
    }, []);
    return [...formattedChunkDocumentInfo, ...formattedOtherDocumentInfo];
  }

  /**
  * Publishes the provided claim to subscribers of the documentInfoAction$ observable.
  *
  * @param claim The claim to be published to subscribers.
  */
  getClaimsByAccountNumber(claim: Claim): void {
    this.documentInfoSubject.next(claim);
  }

  sortClaimsByDate(claims: DocumentInfo[], dir: 'asc' | 'desc' = 'desc') {
    return claims.sort((a, b) => {
      if (dir === 'asc') {
        return new Date(a.documentAddedDate).valueOf() - new Date(b.documentAddedDate).valueOf();
      }
      return new Date(b.documentAddedDate).valueOf() - new Date(a.documentAddedDate).valueOf();
    });
  }
}
