import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GlobalConstants } from '../constants/GlobalConstants';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    getClientMessage(error: ErrorEvent): string {
        if (error) {
            return error.message ? error.message : error.toString();
        }
    }

    getServerMessage(error: HttpErrorResponse): string {
        if (error) {
            return (error.message && error.status)
                ? `${GlobalConstants.ERROR_MESSAGE} <br> ${GlobalConstants.ERROR_TEXT} ${error.status} ${error.statusText}`
                : `${GlobalConstants.ERROR_MESSAGE}`;
        }
    }
}