import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, of, throwError, from } from 'rxjs';
import { switchMap, map, catchError, shareReplay, mergeMap } from 'rxjs/operators';
import { GuardianService } from './abstracts/guardian.service';
import Environment from '../models/Environment';
import TinValues from '../models/TinValues';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import * as Crypto from 'crypto-js';
import GuardianResponse from '../models/GuardianResponse';
import { extractDataTinValues } from '../helpers/GuardianHelper';
import { MockGuardianResponse } from 'src/test/mocks/MockGuardianReponse';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})

export class OptumGuardianProxyService implements GuardianService {
  userUUID: string;
  guardianUrl: string;
  noUserFoundMessage: string;
  private readonly authCodeSubject = new Subject<string>();
  authCodeAction$ = this.authCodeSubject.asObservable();
  sessionStorage: Storage;
  guardianDataKey: string;
  tinValuesKey: string;
  env: Environment;
  toPublishText: string;
  getGuardianKey: string;
  getGuardianLabel: string;

  constructor (
    private readonly httpClient: HttpClient, private readonly appConfigService: AppConfigService) {
    this.env = environment;
    this.sessionStorage = window.sessionStorage;
    this.guardianUrl = environment.guardian.optumGuardianUrl;
    this.noUserFoundMessage = GlobalConstants.ERROR_NO_USER_FOUND;
    this.guardianDataKey = GlobalConstants.GUARDIAN_DATA_KEY;
    this.tinValuesKey = GlobalConstants.TIN_VALUES_KEY;
    this.getGuardianKey = GlobalConstants.GET_GUARDIAN_FUNCTION_KEY;
    this.getGuardianLabel = GlobalConstants.GET_GUARDIAN_FUNCTION_LABEL;
  }

  guardianDataAction$: Observable<GuardianResponse[]> = this.authCodeAction$.pipe(
    switchMap(authCode => {
      return from(this.appConfigService.getPublishData()).pipe(
        mergeMap(publishData => {
          this.toPublishText = publishData;
          return from(this.appConfigService.getConfiguration(this.getGuardianKey, this.getGuardianLabel)).pipe(
            switchMap(res => {
              return this.httpClient.post<GuardianResponse[]>(`${this.guardianUrl}/getGuardianUserInfo?code=${res}`, { 'token': authCode }).pipe(
                map(guardianData => {
                  this.sessionStorage.setItem(this.guardianDataKey, Crypto.AES.encrypt(JSON.stringify(guardianData), this.toPublishText).toString());
                  this.sessionStorage.setItem(this.tinValuesKey, Crypto.AES.encrypt(JSON.stringify(extractDataTinValues(guardianData)), this.toPublishText).toString());
                  return guardianData;
                }),
                catchError((error: HttpErrorResponse) => throwError(error))
              );
            })
          );
        })
      );
    }),
    shareReplay()
  );

  // guardianDataAction$: Observable<GuardianResponse[]> = this.getMockGuardianData().pipe(
  //   mergeMap(guardianData => {
  //     return from(this.appConfigService.getPublishData()).pipe(
  //       map(publishData => {
  //         const toPublishText = publishData;
  //         this.sessionStorage.setItem(this.guardianDataKey, Crypto.AES.encrypt(JSON.stringify(guardianData), toPublishText).toString());
  //         this.sessionStorage.setItem(this.tinValuesKey, Crypto.AES.encrypt(JSON.stringify(extractDataTinValues(guardianData)), toPublishText).toString());
  //         return guardianData;
  //       }),
  //       catchError((error: HttpErrorResponse) => throwError(error))
  //     );
  //   }),
  //   shareReplay()
  // );

  setGuardianToken(code: string): void {
    if (code) {
      this.authCodeSubject.next(code);
    }
  }

  async getGuardianData(): Promise<GuardianResponse[] | null> {
    try {
      const publishData = await this.appConfigService.getPublishData();
      const encryptedData = this.sessionStorage.getItem(this.guardianDataKey);
      return encryptedData
        ? JSON.parse(Crypto.AES.decrypt(encryptedData, publishData).toString(Crypto.enc.Utf8))
        : null;
    } catch (error) {
      console.error('Error getting guardian values:', error);
      throw error;
    }
  }

  async getTinValues(): Promise<TinValues | null> {
    try {
      const publishData = await this.appConfigService.getPublishData();
      const encryptedData = this.sessionStorage.getItem(this.tinValuesKey);
      return encryptedData
        ? JSON.parse(Crypto.AES.decrypt(encryptedData, publishData).toString(Crypto.enc.Utf8))
        : null;
    } catch (error) {
      console.error('Error getting tin values:', error);
      throw error;
    }
  }

  clearGuardianSession(): void {
    this.sessionStorage.clear();
  }

 private getMockGuardianData(): Observable<GuardianResponse[]> {
    const guardianData: GuardianResponse[] = MockGuardianResponse;
    return of(guardianData);
  }
}