import { Injectable } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as FileSaver from 'file-saver';

import { GlobalConstants } from '../constants/GlobalConstants';
import Claim from '../models/Claim';

@Injectable({
  providedIn: 'root'
})

export class ExportService {
  csvExtension : string ;
  csvType: string;
  constructor( ) {
    this.csvExtension = GlobalConstants.CSV_EXTENSION ;
    this.csvType = GlobalConstants.CSV_TYPE ;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  public exportToCsv(claims: Claim[], fileName: string, columnHeaders?: string[]) {
    if (!claims || !claims.length) {
      return;
    }
    const separator = ',';
    const claimDataHeaders = Object.keys(claims[0]).filter(item => {
      if (columnHeaders?.length) {
        return columnHeaders.indexOf(item) !== -1;
      } 
        return true;
    });
    const csvContent =
      `${claimDataHeaders.join(separator) 
      }\n${ 
        claims.map((claim: Claim) => claimDataHeaders.map(item => {
          let claimData = claim[item] === null || claim[item] === undefined ? '' : claim[item];
          claimData = claimData instanceof Date
            ? claimData.toLocaleString()
            : claimData.toString().replace(/"/g, '""');
          if (claimData.search(/["\n,]/g) >= 0) {
            claimData = `"${claimData}"`;
          }
          return claimData;
        }).join(separator)).join('\n')}`;
    this.saveAsFile(csvContent, `${fileName}${this.csvExtension}`, this.csvType);
  }
}