import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isFeatureEnabled } from 'src/app/shared/guards/feature.guard';


@Component({
  selector: 'app-registered',
  templateUrl: './registered.component.html',
  styleUrls: ['./registered.component.scss']
})
export class RegisteredComponent {
  featureFlags = {
    recordUploadEnabled: true,
    faqEnabled: false
  };

  
  ngOnInit() {
    this.featureFlags.recordUploadEnabled = isFeatureEnabled(environment, 'recordUpload')
  }
}
