<div class="uitk-l-grid provider-preference-container">
    <ng-container *ngIf="!isLoading else loading">
        <div class="uitk-l-grid__row centered__shadowed">
            <div class="uitk-l-grid provider-preference">
                <div class="uitk-l-grid__row">
                    <div class="uitk-l-grid__col">
                        <div class = "providerTitle">
                            <legend><h2>{{ providerPreferenceText }}</h2></legend> 
                            <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs editButton" 
                            (click)="getEditPreferencePage()">
                                <span class="uitk-icon uitk-icon-edit sm-icon"></span>
                                {{ editPreferenceText }}
                            </button>
                        </div>
                        <div class="providerData">
                            <label uitkLabel class="providerNameLabel">{{ providerNameText }} </label>
                            <span class="providerName" uitkLabel>: {{ providerName }}</span>
                        </div>
                        <div class = "deliveryPref">
                            <uitk-form-field>
                                <uitk-fieldset>
                                    <span uitkLabel>{{ deliveryPreferencesText }}</span>
                                    <uitk-form-field-hint>{{ deliveryHelperText }}</uitk-form-field-hint>
                                    <ul uitkCheckboxGroup class= "deliveryPref">
                                        <li class="paper-delivery">
                                            <span *ngIf = "isPaperDeliverySelected" 
                                            class="uitk-icon uitk-icon-checkbox_filled md-icon"></span>
                                            <span *ngIf = "!isPaperDeliverySelected" 
                                            class="uitk-icon uitk-icon-checkbox_empty md-icon"></span>
                                            <span uitkLabel>{{ paperDeliveryText }}</span>
                                        </li>
                                        <li class= "digital-delivery">
                                            <span *ngIf = "isDigitalDeliverySelected" 
                                            class="uitk-icon uitk-icon-checkbox_filled md-icon"></span>
                                            <span *ngIf = "!isDigitalDeliverySelected" 
                                            class="uitk-icon uitk-icon-checkbox_empty md-icon"></span>
                                            <span uitkLabel>{{ digitalDeliveryText }}</span>
                                        </li>
                                        <li *ngIf = "isDigitalDeliverySelected" class="emailPref" >
                                            <label uitkLabel>{{ emailAddressText }}</label>
                                            <uitk-form-field-hint>{{ emailHelperText }}</uitk-form-field-hint>
                                            <span class="emailData" uitkLabel>{{email}}</span>
                                        </li>
                                    </ul>
                                </uitk-fieldset>
                            </uitk-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #loading>
        <app-loading-spinner [loadingMessage]="preferenceLoadingMessage" [spinnerSize]="spinnerSize"></app-loading-spinner>
    </ng-template>
</div>