<div id="selfRegistrationContainer" class="selfRegistrationFormContainer">
    <h2>Self Registration</h2>
    <uitk-loading-indicator *ngIf="verificationLoading; else form" uitkId="spinner" [visibleText]="visibleLoadingText"
        spinnerSize="large">
    </uitk-loading-indicator>

    <ng-template #form>
        <div>
            <span>{{selfRegistrationActivatedNotice}}</span><br><br>
            <span>{{selfRegistrationApprovalNotice}}</span><br><br>
            <span>{{selfRegistrationRedirectNotice}}</span><br>
            <form [formGroup]="inputForm">
                <uitk-form-field-error *ngIf="formError">
                    {{ verificationErrorMessage }}
                </uitk-form-field-error><br>
                <div class="formContainer">
                    <uitk-form-field>
                        <label uitkLabel>{{ tinLabelText }}</label>
                        <uitk-form-field-hint>
                            {{ tinHelpText }}
                        </uitk-form-field-hint>
                        <input id="my-required-input-reactive" uitkInput required formControlName="tin" />
                        <uitk-form-field-error *ngIf="
                            inputForm?.controls?.tin?.errors?.required &&
                            inputForm?.controls?.tin?.touched
                        ">
                            {{ tinRequiredText }}
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div><br />

                <div class="formContainer">
                    <uitk-form-field>
                        <label uitkLabel>{{ authorizationLabelText }}</label>
                        <uitk-form-field-hint>
                            {{ authorizationHelpText }}
                        </uitk-form-field-hint>
                        <input id="my-required-input-reactive" uitkInput required formControlName="authorizationCode" />
                        <uitk-form-field-error *ngIf="
                            inputForm?.controls?.authorizationCode?.errors?.required &&
                            inputForm?.controls?.authorizationCode?.touched
                        ">
                            {{ authorizationCodeRequiredText }}
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div><br />

                <div class="formContainer">
                    <uitk-form-field>
                        <label uitkLabel>{{ recordBarCodeLabelText }}</label>
                        <uitk-form-field-hint>
                            {{ barCodeHelpText }}
                        </uitk-form-field-hint>
                        <input id="my-required-input-reactive" uitkInput formControlName="memberLetterBarCode"
                            required />
                        <uitk-form-field-error *ngIf="
                            inputForm?.controls?.memberLetterBarCode?.errors?.required &&
                            inputForm?.controls?.memberLetterBarCode?.touched
                        ">
                            {{ recordBarCodeRequiredText }}
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div><br />

                <div class="formContainer">
                    <uitk-form-field>
                        <label uitkLabel>{{ firstDateOfServiceLabelText }}</label>
                        <uitk-form-field-hint>
                            - {{ dateOfServiceHelpText }}
                        </uitk-form-field-hint>
                        <uitk-date-picker id="my-required-date-picker" formControlName="startDateOfService"
                            [required]="true"></uitk-date-picker>
                        <uitk-form-field-error *ngIf="
                            inputForm?.controls?.startDateOfService?.errors?.required &&
                            inputForm?.controls?.startDateOfService?.touched
                        ">
                            {{ firstDateOfServiceRequiredText }}
                        </uitk-form-field-error>
                        <uitk-form-field-error *ngIf="
                            inputForm?.controls?.startDateOfService?.errors?.invalidFormat &&
                            inputForm?.controls?.startDateOfService?.touched
                        ">
                            {{ dateInvalidText }}
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div><br />
                <div id="continueContainer">
                    <button id="formValidationButton" [disabled]="inputForm?.controls.startDateOfService?.status === 'INVALID' ||
                inputForm?.controls.authorizationCode?.status === 'INVALID' ||
                inputForm?.controls.tin?.status === 'INVALID' ||
                inputForm?.controls.memberLetterBarCode?.status === 'INVALID'" type="submit"
                        class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                        (click)="handleSelfRegistrationProcess()">Continue</button>
                </div>
            </form>
        </div>
    </ng-template>

</div>