import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UITKFileUploadComponent } from '@uitk/angular';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import { AuthService } from '../services/abstracts/auth.service';
import ManualUploadForm from '../models/ManualUploadForm';
import { ClaimService } from '../services/claim.service';
import Environment from '../models/Environment';

@Component({
  selector: 'app-record-upload',
  templateUrl: './record-upload.component.html',
  styleUrls: ['./record-upload.component.scss']})
export class RecordUploadComponent {
  @ViewChild('uitkFileUpload') uitkFileUpload: UITKFileUploadComponent;
  env: Environment;
  url: string;
  acceptedTypesList = ['tiff', 'tif', 'pdf', 'jpg', 'jpeg', 'zip'];
  authorizationLabelText: string;
  recordBarCodeLabelText: string;
  firstDateOfServiceLabelText: string;
  visibleLoadingText: string;
  showNotification: boolean = false;
  buttonText: string = "Close";
  notificationVariant: string;
  notificationMessage: string;

  helperText = 'My Helper Text';
  authCodeHelperText : string;
  recordBarCodeHelperText: string;
  authorizationCodeRequiredText: string;
  recordBarCodeRequiredText: string;
  firstDateOfServiceRequiredText: string;

  invalidText = GlobalConstants.DATE_FORMAT_TEXT;
  manualUploadErrorMessage: string;
  manualUploadSuccessMessage: string;
  formError = false;
  fileUploadLoading = false;
  userEmail: string;

  constructor(
    private claimService: ClaimService,
    private authService: AuthService,
  ) {
    this.authorizationLabelText = GlobalConstants.AUTHORIZATION_LABEL_TEXT;
    this.recordBarCodeLabelText = GlobalConstants.RECORD_BARCODE_LABEL_TEXT;
    this.firstDateOfServiceLabelText = GlobalConstants.FIRST_DATE_OF_SERVICE_LABEL_TEXT;
    this.authorizationCodeRequiredText = GlobalConstants.AUTHORIZATION_CODE_REQUIRED_TEXT;
    this.recordBarCodeRequiredText = GlobalConstants.RECORD_BARCODE_REQUIRED_TEXT;
    this.firstDateOfServiceRequiredText = GlobalConstants.FIRST_DATE_OF_SERVICE_REQUIRED_TEXT;
    this.authCodeHelperText = GlobalConstants.AUTHORIZATION_HELP_TEXT;
    this.recordBarCodeHelperText = GlobalConstants.BAR_CODE_HELP_TEXT;
    this.env = environment;
    this.visibleLoadingText = GlobalConstants.UPLOADING_TEXT;
    this.manualUploadErrorMessage = GlobalConstants.UPLOAD_ERROR_MESSAGE;
    this.manualUploadSuccessMessage = GlobalConstants.MANUAL_UPLOAD_SUCCESS_MESSAGE;
  }

  inputForm = new FormGroup({
      authorizationCode: new FormControl('', Validators.required),
      memberLetterBarCode: new FormControl('', Validators.required),
      startDateOfService: new FormControl(''),
      file: new FormControl(),
      userEmail: new FormControl('')
  });

  ngOnInit() {
    const user = this.authService.getUserProfile();
    if (user) {
      this.userEmail = user.email;
    }
    const { baseUrl, endpointManualUpload } = this.env.lettersInventory;
    this.url = `${baseUrl}${endpointManualUpload}`;
  }

  closeNotification() {
    this.showNotification = false;
  }

  openNotification(notificationVariant: string, message: string) {
    this.fileUploadLoading = false;
    this.notificationVariant = notificationVariant;
    this.notificationMessage = message;
    this.showNotification = true;
  }

  uploadRecord() {
    this.fileUploadLoading = true;
    this.inputForm.get('userEmail').setValue(this.userEmail);
    const manualForm: ManualUploadForm = this.inputForm.value;
    let fileToUpload: File;
    if (this.uitkFileUpload.filesItems.length > 0) {
      fileToUpload = this.uitkFileUpload.filesItems[0].file;
    }
    manualForm.file = fileToUpload;

    this.claimService.uploadAndVerifyManualRecord(manualForm, res => {
      if (res) {
        this.inputForm.reset();
        this.openNotification('success', this.manualUploadSuccessMessage);
        setTimeout(() => {
          this.closeNotification();
        }, 5000);
      } 
    }, error => {
        console.log(error);
        this.openNotification('error', this.manualUploadErrorMessage);
      });
  }
}
