import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UnauthorizedComponent } from './account/unauthorized/unauthorized.component';
import { WelcomeComponent } from './account/welcome/welcome.component';

const routes: Routes = [
  { path: '', component: WelcomeComponent }, 
  { path: 'unauthorized', component: UnauthorizedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}