/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  IUITKPaginationConfig,
  IUITKPaginationEntriesPerPage,
  IUITKPaginationEvent,
  PAGINATION_EVENTS,
  UITKTableDataSource,
  UITKTableSortDirective,
  IUITKColumnState,
  UITKSortDirection,
  UITKPaginationComponent
} from '@uitk/angular';

import ClaimUpload from 'src/app/shared/models/ClaimUpload';
import { DocumentInfoService } from '../../shared/services/document-info.service';
import { ClaimService } from '../../shared/services/claim.service';
import { ClientService } from '../../shared/services/client.service';
import { UploadService } from '../../shared/services/upload.service';
import { GuardianService } from '../../shared/services/abstracts/guardian.service';
import { ClaimStore } from '../../shared/stores/claim.store';
import Claim from '../../shared/models/Claim';
import { ClaimListPaginationConfig } from '../../shared/models/ClaimListPaginationConfig';
import Client from '../../shared/models/Client';
import { SearchCriteria } from '../../shared/models/SearchCriteria';
import SearchClaimPrepay from '../../shared/models/SearchClaimPrepay';
import SearchKey from '../../shared/models/SearchKey';
import FilterKey from '../../shared/models/FilterKey';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';
import { ExportService } from '../../shared/services/export.service';
import { ClaimListResponse } from '../../shared/models/ClaimListResponse';

@Component({
  selector: 'app-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss']
})

export class ClaimListComponent implements OnInit, AfterViewInit {
  totalRecordsCount: number;
  totalSearchRecordsCount: number;
  title: string;
  tableTitle: string;
  noClaimsStatusMessage: string;
  hasClaimsData: boolean;
  hasSearchClaimsResult: boolean;
  isSearchClaimsEnabled: boolean;
  isClearSearchEnabled: boolean;
  tableHeader: Array<Object>;
  paginationConfig: IUITKPaginationConfig;
  entriesPerPage: IUITKPaginationEntriesPerPage;
  selectedClaim: Claim;
  currentClient: Client;
  searchCriteria: SearchCriteria;
  searchRequestData: SearchClaimPrepay;
  isLoading: boolean;
  claimsLoadingMessage: string;
  claims$: Observable<Claim[]>;
  exportClaims$: Observable<Claim[]>;
  isDialogEnabled: boolean;
  exportClaimsButtonText: string;
  exportDialogHeaderText: string;
  exportClaimsUserMessage: string;
  downloadButtonText: string;
  cancelButtonText: string;
  closeButtonText: string;
  isExportDialogModalEnabled: boolean;
  hiText: string;
  userName: string;
  spinnerSize: string;
  showSearch: boolean;
  initialData: Claim[];
  claims: Claim;
  isDataPresent: boolean;
  paginationPageOne: number;
  selectedTinSearchValue: string;

  // UITK Pagination component instance
  @ViewChild(UITKPaginationComponent, { static: true })
  uitkTablePagination: UITKPaginationComponent;

  dataSource: UITKTableDataSource<Claim> = new UITKTableDataSource<Claim>([]);
  @ViewChild('sortTable') uitkTableSort: UITKTableSortDirective;
  @ViewChild('applyFiltersBtn') applyFiltersBtn: ElementRef;
  tooltipText: string;
  noDataMessagePrepay: string;
  searchByTextPrepay: string;
  claimNumberTextPrepay: string;
  patientAccountNoTextPrepay: string;
  patientNameTextPrepay: string;
  tinTextPrepay: string;
  requiredErrorText: string;
  searchPrepayText: string;
  filterText: string;
  selectedSearchKey: SearchKey;
  selectedFilterKey: FilterKey;
  clearSearchPrepayText: string;
  closeSearchKeyDropdown: boolean;
  closeFilterKeyDropdown: boolean;
  closeTinSelectDropdown: boolean;
  noClaimsMatchMessage: string;
  searchHeaderText: string;
  filterHeaderText: string;
  isSearchResultZero: boolean;
  selectedFilterValue: FilterKey = { label: 'Waiting for Medical Record', value: '[0]' };

  searchClaimsForm = new UntypedFormGroup({
    searchDropdown: new UntypedFormControl([], Validators.required),
    searchValue: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9A-Za-z]*$')])
  });

  searchKeyList: SearchKey[] = [
    { label: 'Patient Account Number', value: 'patientAccountNumber' },
    { label: 'Claim Number', value: 'claimNumber' },
    { label: 'TIN', value: 'tins' }
  ];

  filterClaimsForm = new UntypedFormGroup({
    filterDropdown: new UntypedFormControl([], Validators.required)
  });

  filterKeyList: FilterKey[] = [
    { label: 'Waiting for Medical Record', value: '[0]' },
    { label: 'All Cases', value: '[0,1]' }
  ];

  tinList: FilterKey[] = [];

  showNoClaimsStatusMessage: boolean;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly claimStore: ClaimStore,
    private readonly clientService: ClientService,
    private readonly uploadService: UploadService,
    private readonly claimService: ClaimService,
    private readonly exportService: ExportService,
    private readonly documentInfoService: DocumentInfoService,
    private readonly guardianService: GuardianService
  ) {
    this.paginationConfig = ClaimListPaginationConfig.paginationConfig;
    this.entriesPerPage = ClaimListPaginationConfig.entriesPerPage;
    this.tableHeader = ClaimListPaginationConfig.tableHeader;
    this.searchCriteria = ClaimListPaginationConfig.defaultSearchCriteria;
    this.searchRequestData = ClaimListPaginationConfig.defaultSearchRequestData;
    this.claimsLoadingMessage = GlobalConstants.CLAIMS_LOADING_MESSAGE;
    this.noClaimsStatusMessage = GlobalConstants.NO_CLAIMS_STATUS_MESSAGE;
    this.title = GlobalConstants.CLAIMS_HOME_TITLE;
    this.tableTitle = '';
    this.tableTitle = '';
    this.isLoading = false;
    this.hasClaimsData = false;
    this.hasSearchClaimsResult = false;
    this.isSearchClaimsEnabled = false;
    this.isClearSearchEnabled = false;
    this.noClaimsMatchMessage = GlobalConstants.NO_CLAIMS_MATCH_MESSAGE;
    this.isDialogEnabled = false;
    this.userName = '';
    this.filterClaimsForm.controls.filterDropdown.setValue({ label: 'Waiting for Medical Record', value: '[0]' });
    this.selectedSearchKey = GlobalConstants.SEARCH_DROPDOWN_DEFAULT_VALUE;
    this.selectedFilterKey = GlobalConstants.FILTER_DROPDOWN_DEFAULT_VALUE;
    this.spinnerSize = GlobalConstants.SPINNER_SIZE_LARGE;
    this.noDataMessagePrepay = GlobalConstants.NO_DATA_DISPLAYED_TEXT;
    this.searchByTextPrepay = GlobalConstants.SEARCH_BY_TEXT;
    this.claimNumberTextPrepay = GlobalConstants.CLAIM_ID_TEXT;
    this.patientAccountNoTextPrepay = GlobalConstants.PATIENT_ACCOUNT_NO_TEXT;
    this.patientNameTextPrepay = GlobalConstants.PATIENT_NAME_TEXT;
    this.tinTextPrepay = GlobalConstants.TIN_TEXT;
    this.requiredErrorText = GlobalConstants.REQUIRED_ERROR_TEXT;
    this.searchPrepayText = GlobalConstants.SEARCH_PREPAY_TEXT;
    this.filterText = GlobalConstants.FILTER_TEXT;
    this.clearSearchPrepayText = GlobalConstants.CLEAR_SEARCH_TEXT;
    this.searchHeaderText = GlobalConstants.SEARCH_HEADER_TEXT;
    this.filterHeaderText = GlobalConstants.FILTER_HEADER_TEXT;
    this.exportDialogHeaderText = GlobalConstants.EPORT_DIALOG_HEADER_TEXT;
    this.exportClaimsUserMessage = GlobalConstants.EXPORT_CLAIMS_USER_MESSAGE_TEXT;
    this.downloadButtonText = GlobalConstants.DOWNLOAD_BUTTON_TEXT;
    this.cancelButtonText = GlobalConstants.CANCEL_BUTTON_TEXT;
    this.closeButtonText = GlobalConstants.CLOSE_BUTTON_TEXT;
    this.tooltipText = GlobalConstants.UPLOAD_TOOLTIP_TEXT;

    this.isExportDialogModalEnabled = false;
    this.showSearch = true;
    this.isSearchResultZero = true;
    this.exportClaimsButtonText = GlobalConstants.EXPORT_CLAIMS_TEXT;
    this.isDataPresent = false;
    this.paginationPageOne = GlobalConstants.PAGINATION_PAGE_ONE;
    this.showNoClaimsStatusMessage = true;

    this.selectedTinSearchValue = '';
  }

  async ngOnInit() {
    this.getClaimsResponse();
    this.currentClient = this.clientService.getCurrentClient();
    this.searchClaimsForm.controls.searchValue.disable();
    await this.getUserTins();
  }

  async getUserTins(): Promise<void> {
    if (this.tinList.length === 0) {
      let tinValues = await this.guardianService.getTinValues();
      if (tinValues !== null && tinValues.tin.length > 0) {
        this.tinList = tinValues.tin.map((tin) => {
          return { label: tin, value: tin };
        });
      };
    }
  }

  getClaimsResponse(): void {
    this.isSearchClaimsEnabled = false;
    this.claimStore.queryClaims(this.searchCriteria);
    this.claims$ = this.claimStore.claims$.pipe(
      // eslint-disable-next-line consistent-return
      map((res : ClaimListResponse)=> {
        this.isLoading = true;
        if (res && res.result && res.result.data && res.result.data.claimResponse) {
          this.totalRecordsCount = res.result.data.claimResponse.totalRecordSize;
          this.paginationConfig.currentPageNumber = res.result.data.claimResponse.pageNumber;
          this.tableTitle = this.selectedFilterKey.label === 'Waiting for Medical Record' ? GlobalConstants.WFMR_CLAIMS_HOME_TABLE_TITLE : GlobalConstants.ALL_CLAIMS_HOME_TABLE_TITLE;
          this.hasClaimsData = res.result.data.claimResponse.claims.length > 0;
          this.isDataPresent = !!this.hasClaimsData;
          this.isDialogEnabled = (!this.claimService.doesPrepayClaimExists);
          this.isLoading = false;
          this.dataSource.data = this.initialData = [...res.result.data.claimResponse.claims];
          return res.result.data.claimResponse.claims;
        } 
      })
    );
    this.claims$.subscribe({
      error: () => {
        this.isLoading = false;
        this.isDialogEnabled = false;
      }
    });
  }

  isUploading(claim: Claim): boolean {
    if (claim && claim.uploads.length > 0) {
      const foundUploadingStatus = claim.uploads.some((claim: ClaimUpload) => (claim.status === 'Uploading' || claim.status === 'Scanning'));
      return foundUploadingStatus;
    }
    return false;
  }

  onCloseSearchKeyDropdown(): void {
    this.closeSearchKeyDropdown = true;
    this.searchClaimsForm.controls.searchValue.reset();
    this.hasSearchClaimsResult = false;
    this.isSearchClaimsEnabled = false;
    this.isClearSearchEnabled = true;
    if (this.selectedSearchKey.value !== 'Select') {
      this.searchClaimsForm.controls.searchValue.enable();
    }
    this.isDataPresent = true;
  }

  setValidationRules(): void {
    if (this.selectedSearchKey.value === 'tins') {
      this.searchClaimsForm.controls.searchValue.clearValidators();
      this.searchClaimsForm.controls.searchValue.setValidators(Validators.required);
      this.searchClaimsForm.controls.searchValue.updateValueAndValidity();
      this.requiredErrorText = GlobalConstants.TIN_ERROR_MESSAGE;
    } else if (this.selectedSearchKey.value === 'patientAccountNumber') {
      this.searchClaimsForm.controls.searchValue.clearValidators();
      this.searchClaimsForm.controls.searchValue.setValidators([Validators.required, Validators.maxLength(14), Validators.pattern('^[a-zA-Z0-9-_]*$')]);
      this.searchClaimsForm.controls.searchValue.updateValueAndValidity();
      this.requiredErrorText = GlobalConstants.PATIENT_ACCOUNT_NUMBER_ERROR_MESSAGE;
    } else if (this.selectedSearchKey.value === 'claimNumber') {
      this.searchClaimsForm.controls.searchValue.clearValidators();
      this.searchClaimsForm.controls.searchValue.setValidators([Validators.required, Validators.maxLength(17), Validators.pattern('^[0-9A-Za-z-_]*$')]);
      this.searchClaimsForm.controls.searchValue.updateValueAndValidity();
      this.requiredErrorText = GlobalConstants.CLAIM_NUMBER_ERROR_MESSAGE;
    } else if (this.selectedSearchKey.value === 'Select') {
      this.searchClaimsForm.controls.searchValue.disable();
      this.searchClaimsForm.controls.searchDropdown.setValidators(Validators.required);
      this.requiredErrorText = GlobalConstants.INVALID_INPUT_ERROR_MESSAGE;
    } 
  }

  onSearchKeySelectChange(event): void {
    this.selectedSearchKey = event.value;
  }

  onFilterKeySelectChange(event): void {
    this.searchClaimsForm.controls.searchValue.reset();
    this.searchClaimsForm.controls.searchValue.disable();
    this.searchClaimsForm.controls.searchDropdown.setValue('');
    this.isClearSearchEnabled = false;
    this.isSearchClaimsEnabled = false;
    this.showNoClaimsStatusMessage = false;
    this.selectedFilterKey = event.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTextBoxChange(event): void {
    this.isSearchClaimsEnabled = (event.target.value !== '');
    this.setValidationRules();
    if (this.searchClaimsForm.controls.searchValue.errors != null) {
      this.isSearchClaimsEnabled = false;
    }
    this.isDataPresent = true;
  }

  onTinSelectChange(event): void {
    this.isSearchClaimsEnabled = (event.value.value !== '');
    this.setValidationRules();
    this.searchClaimsForm.controls.searchValue.setValue(event.value.value);
    this.selectedTinSearchValue = event.value.value;
    this.isDataPresent = true;
  }

  onSortChange(sortState: IUITKColumnState): void {
    const { column, direction } = sortState;
    this.sortColumn(column, direction);
    this.dataSource.setSortState({ column, direction });
  }

  sortColumn(column: string, direction: UITKSortDirection): void {
    // eslint-disable-next-line no-constant-condition
    if (column === 'medicalRecordRequestDate' || column === 'patientDoB' || column === 'startDateOfService') {
      this.dataSource.data.sort((a, b) => {
        let aValue: number = new Date(a[column]).getTime();
        let bValue: number = new Date(b[column]).getTime();
        // eslint-disable-next-line no-constant-condition
        aValue = aValue || 0;
        bValue = bValue || 0;
        if (direction === 0) {
          // eslint-disable-next-line no-param-reassign
          direction = 1;
        }
        return this.returnSortedOrder(aValue, bValue, direction);
      });
    } else {
      this.dataSource.data.sort((a, b) => {
        let aValue: string | number = a[column];
        let bValue: string | number = b[column];
        // eslint-disable-next-line no-constant-condition
        aValue = aValue || '';
        bValue = bValue || '';
        if (direction === 0) {
          // eslint-disable-next-line no-param-reassign
          direction = 1;
        }
        return this.returnSortedOrder(aValue, bValue, direction);
      });
    }
    this.dataSource.data = [...this.dataSource.data];
  }

  returnSortedOrder(aValue: string | number, bValue: string | number, direction: number): number {
    if (aValue < bValue) { return -1 * direction; }
    if (aValue > bValue) { return 1 * direction; }
    return 0;
  }

  getClaimsData(searchCriteria: SearchCriteria): void {
    this.claimStore.queryClaims(searchCriteria);
  }

  onPaginationChangeHandler(pageEvent: IUITKPaginationEvent): void {
    switch (pageEvent.event) {
      case PAGINATION_EVENTS.PREVIOUS_PAGE:
      case PAGINATION_EVENTS.NEXT_PAGE:
      case PAGINATION_EVENTS.GO_TO_PAGE:
      case PAGINATION_EVENTS.ENTRIES_PER_PAGE:
        if(this.isSearchClaimsEnabled) {
          this.updateSearchReqDataPagination(pageEvent.state.currentPageNumber, pageEvent.entriesPerPage);
          this.searchPrepayClaims(this.selectedSearchKey);
        } else {
          this.updateSearchCriteria(pageEvent.state.currentPageNumber, pageEvent.entriesPerPage);
          this.getClaimsData(this.searchCriteria);
        }
        this.isLoading = true;
        break;
      default:
        this.isLoading = false;
    }
  }

  ngAfterViewInit(): void {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();
  }

  private updateSearchCriteria(pageNumber: number, paginationSize: number): void {
    this.searchCriteria.pageNumber = pageNumber;
    this.searchCriteria.paginationSize = paginationSize;
  }

  private updateSearchReqDataPagination(pageNumber: number, paginationSize: number): void {
    this.searchRequestData.pageNumber = pageNumber;
    this.searchRequestData.paginationSize = paginationSize;
  }

  showUploadDialog(claim: Claim): void {
    this.uploadService.getClaimsByAccountNumber(this.claimStore.getClaimsByNumber(claim.claimNumber));
  }
 
  showClaimInfoDialog(claim: Claim): void {
    this.documentInfoService.getClaimsByAccountNumber(this.claimStore.getClaimsByNumber(claim.claimNumber));
  }

  updateClaimsPage(): void {
    this.claimStore.queryClaims(this.searchCriteria);
    this.isLoading = true;
  }

  showExportClaimDialog(): void {
    this.isExportDialogModalEnabled = true;
  }

  exportPrepayClaims(filterKey: FilterKey): void {
    this.searchCriteria = filterKey.label === GlobalConstants.WFMR_FILTER_CLAIMS_LABEL ? ClaimListPaginationConfig.defaultSearchCriteria : ClaimListPaginationConfig.defaultAllClaimsSearchCriteria;
    this.claimStore.exportClaimsPrepay(this.searchCriteria);
    this.exportClaims$ = this.claimStore.exportClaims$.pipe(
      // eslint-disable-next-line consistent-return
      map((res: ClaimListResponse) => {
        this.isLoading = true;
        if (res && res.result && res.result.data && res.result.data.claimResponse) {
          return res.result.data.claimResponse.claims;
        }
      })
    );
    this.exportClaims$.subscribe({
      next: (response) => {
        if (response.length !==0) {
          this.isLoading = false;
          this.exportService.exportToCsv(response,GlobalConstants.CSV_FILE_NAME, GlobalConstants.CSV_COLUMN_HEADERS);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.isExportDialogModalEnabled = false;
        throw (error);
      }
    });
    this.isExportDialogModalEnabled = false;
  }

  showSearchPrepay(): void {
    this.isSearchClaimsEnabled = false;
    this.searchClaimsForm.controls.searchValue.reset();
    this.showSearch = !this.showSearch;
  }

  searchPrepayClaims(searchKey: SearchKey): void {
    if (this.searchRequestData !== ClaimListPaginationConfig.defaultSearchRequestData) {
      this.searchRequestData = ClaimListPaginationConfig.defaultSearchRequestData;
    }
    let searchKeyName = searchKey.value;
    let searchKeyValue = this.searchClaimsForm.get('searchValue').value.toString().trim();
    
    if (searchKeyName === 'tins') {
      searchKeyValue = [this.selectedTinSearchValue];
    }

    this.isSearchClaimsEnabled = true;
    this.searchRequestData = {
      ...this.searchRequestData,
      [searchKeyName]: searchKeyValue
    };

    if ( this.selectedFilterKey.label === GlobalConstants.WFMR_FILTER_CLAIMS_LABEL){
      this.searchRequestData = {
        ...this.searchRequestData,
        letterType: 'I',
      };
    } else if (this.selectedFilterKey.label === GlobalConstants.ALL_FILTER_CLAIMS_LABEL){
      this.searchRequestData.mrStatus = [0, 1];
      if ('letterType' in this.searchRequestData) {
        const { letterType, ...searchRequestDataUpdated } = this.searchRequestData;
        this.searchRequestData = searchRequestDataUpdated;
      }
    }

    this.claimStore.searchClaimsPrepay(this.searchRequestData);
    this.claims$ = this.claimStore.searchClaimsPrepay$.pipe(

      map((res: ClaimListResponse) => {
        this.isLoading = true;
        if (res && res.result && res.result.data && res.result.data.claimResponse) {
          this.totalRecordsCount = res.result.data.claimResponse.totalRecordSize;
          this.paginationConfig.currentPageNumber = res.result.data.claimResponse.pageNumber;
          this.hasSearchClaimsResult = res.result.data.claimResponse.claims.length > 0;
          this.isDataPresent = !!this.hasSearchClaimsResult;
          this.isSearchResultZero =   (!this.claimService.doesPrepayClaimExists);
          if (this.isSearchResultZero) {
            this.isLoading = false;
          }
          if(this.hasSearchClaimsResult) {
            this.isLoading = false;
          }
          // eslint-disable-next-line no-multi-assign
          this.dataSource.data = this.initialData = [...res.result.data.claimResponse.claims];
        }
        return res.result.data.claimResponse.claims;
      })
    );

    this.claims$.subscribe({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      next: (res) => {
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  filterPrepayClaims(filterKey: FilterKey): void {
    this.searchClaimsForm.controls.searchValue.reset();
    this.searchClaimsForm.controls.searchValue.disable();
    this.searchClaimsForm.controls.searchDropdown.setValue('');
    this.isClearSearchEnabled = false;
    this.showNoClaimsStatusMessage = true;
    this.searchCriteria = filterKey.label === GlobalConstants.WFMR_FILTER_CLAIMS_LABEL ? ClaimListPaginationConfig.defaultSearchCriteria : ClaimListPaginationConfig.defaultAllClaimsSearchCriteria;
    this.getClaimsData(this.searchCriteria);
    this.isLoading = true;
  }

  clearSearchPrepay(): void {
    this.searchCriteria = {
      ...this.searchCriteria,
      pageNumber: this.paginationPageOne,
      paginationSize: ClaimListPaginationConfig.defaultSearchCriteria.paginationSize
    };
    this.isClearSearchEnabled = false;
    this.isSearchClaimsEnabled = false;
    this.isDialogEnabled = false;
    this.getClaimsData(this.searchCriteria);
    this.searchClaimsForm.controls.searchValue.reset();
    this.searchClaimsForm.controls.searchValue.disable();
    this.searchClaimsForm.controls.searchDropdown.setValue('');
    this.selectedSearchKey = GlobalConstants.SEARCH_DROPDOWN_DEFAULT_VALUE;
    this.isLoading = true;
    this.searchRequestData.pageNumber = this.paginationPageOne;
  }
}