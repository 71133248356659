import GuardianResponse from '../../app/shared/models/GuardianResponse';
 
export const MockGuardianResponse: GuardianResponse[] = [
    {
        relationId: 34091900,
        id: 1972177,
        level: 1,
        data: {
            key: null,
            name: "Medical Mutual of Ohio",
            tinlist: "1",
            permissions: ["ViewClientClaims.Read", "Roles.Read", "Users.Read"]
        },
    },
    {
        relationId: 34062436,
        id: 917049,
        level: 2,
        data: {
            key: "O0ETgike",
            name: "Northern Ohio Medical Services",
            tinlist: "340714593, 341933718, 830638696, 341720429, 364795636, 450924455, 461382538, 341567805, 340827442",
            permissions: ["Approvals.Read", "Roles.Read", "Users.Read"]
        }
    }
];