import { Component, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '../../shared/services/abstracts/auth.service';
import { ClientService } from '../../shared/services/client.service';
import { UploadService } from '../../shared/services/upload.service';
import Claim from '../../shared/models/Claim';
import LogMedicalRecordData from '../../shared/models/LogMedicalRecordData';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';
import { UITKFileUploadComponent } from '@uitk/angular';
import UploadRequest from 'src/app/shared/models/UploadRequest';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  @ViewChild('uitkFileUpload') uitkFileUpload: UITKFileUploadComponent;

  acceptedTypesList = ['tiff', 'tif', 'pdf', 'jpg', 'jpeg', 'zip'];
  url: string;
  userId: string
  uploadErrorMessage: string;
  uploadSuccessMessage: string;
  formError = false;
  fileUploadLoading = false;
  showNotification: boolean = false;
  notificationVariant: string;
  notificationMessage: string;
  buttonText: string = "Close";
  visibleLoadingText: string;
  hyperLinkText: string;
  dataBankUrl: string;
  uploadMedicalRecordText: string;
  acceptableFileText: string;
  uploadButtonText: string;
  cancelButtonText: string;
  patientAccountNumberText: string;
  claimNumberText: string;
  fileUploadMessage: string;
  isUploadDialogVisible: boolean = false;
  isLoggedIn: boolean;
  selectedClaim: Claim;
  subscription: Subscription;
  logMedicalRecordData: LogMedicalRecordData;
  sessionStorage: Storage;
  uniqueUploadID: string;

  @Output() updatePage = new EventEmitter();
  uploadForm = new FormGroup({
    file: new FormControl(),
});
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly uploadService: UploadService,
    private readonly clientService: ClientService,
    private readonly authService: AuthService
  ) {
    this.uploadErrorMessage = GlobalConstants.UPLOAD_ERROR_MESSAGE;
    this.uploadSuccessMessage = GlobalConstants.CLAIM_UPLOAD_SUCCESS_MESSAGE;
    this.visibleLoadingText = GlobalConstants.UPLOADING_TEXT;
    this.hyperLinkText = GlobalConstants.HYPERLINK_TEXT;
    this.dataBankUrl = environment.dataBankUploadUrl;
    this.uploadMedicalRecordText = GlobalConstants.UPLOAD_MEDICAL_RECORD_TEXT;
    this.acceptableFileText = GlobalConstants.ACCEPTABLE_FILE_TEXT;
    this.uploadButtonText = GlobalConstants.UPLOAD_BUTTON_TEXT;
    this.cancelButtonText = GlobalConstants.CANCEL_BUTTON_TEXT;
    this.patientAccountNumberText = GlobalConstants.PATIENT_AC_NUMBER_TEXT;
    this.claimNumberText = GlobalConstants.CLAIM_NUMBER_TEXT;
    this.fileUploadMessage = GlobalConstants.FILE_UPLOAD_MSG;
    this.isUploadDialogVisible = false;
    this.sessionStorage = window.sessionStorage;
    this.uniqueUploadID = `${new Date().getTime()}-${uuidv4()}`;
  }

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.authService.isAuthenticated();
    const user = this.authService.getUserProfile();
    if (user) {
      this.userId = user.username;
    }
    this.subscription = this.uploadService.uploadAction$.subscribe(claim => {
      this.changeDetectorRef.detectChanges();
      this.selectedClaim = claim;
      this.isUploadDialogVisible = true;
    });
  }

  updateChange() {
    this.changeDetectorRef.detectChanges();
  }

  closeNotification() {
    this.showNotification = false;
  }

  openNotification(notificationVariant: string, message: string) {
    this.fileUploadLoading = false;
    this.notificationVariant = notificationVariant;
    this.notificationMessage = message;
    this.showNotification = true;
  }

  uploadRecord() {
    this.changeDetectorRef.detectChanges();
    this.fileUploadLoading = true;
    let fileToUpload: File;
    if (this.uploadForm.controls.file.value.length > 0) {
      fileToUpload = this.uploadForm.controls.file.value[0].file;
      const uploadRequest: UploadRequest = {
        userId: this.userId,
        memberLetterBarCode: this.selectedClaim.memberLetterBarCode,
        clientId: this.clientService.getCurrentClient().clientName.toString().toUpperCase(),
        uploadId: this.uniqueUploadID,
        file: fileToUpload
      };
  
      this.uploadService.uploadClaim(uploadRequest, res => {
        if (res) {
          this.uploadForm.reset();
          this.openNotification('success', this.uploadSuccessMessage);
          setTimeout(() => {
            this.cancelDialog();
          }, 10000);
        } 
      }, error => {
          console.log(error);
          this.openNotification('error', this.uploadErrorMessage);
        });
    }
  }

  removeFile() {
    this.uploadForm.reset();
    if (this.uitkFileUpload?.filesItems) { //this will handle the uitk bug where the filesItems is not getting reset
      this.uitkFileUpload.filesItems = [];
      this.uitkFileUpload.readyToUploadStatusText = 'No files selected';
    }
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Resets all the values and empties the files array
   */
  cancelDialog(): void {
    this.isUploadDialogVisible = false;
    this.showNotification = false;
    this.removeFile();
    this.updateClaimsPage();
  }

  /**
    * Performs cleanup actions when the component is about to be destroyed.
    *
    * This method is part of the Angular lifecycle hooks and is automatically called
    * when the component is being destroyed. It unsubscribes from any subscriptions
    * to prevent memory leaks.
  */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

/**
 * Notifies listeners to update the claims page.
 *
 * This method emits an update event using the updatePage emitter. It is typically used
 * to notify components or services that the claims page should be updated.
 */
  updateClaimsPage() {
    this.updatePage.emit();
  }

}