import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { EntitlementService } from './abstracts/entitlement.service';
import Environment from '../models/Environment';
import ProviderClientData from '../models/ProviderClientData';
import { environment } from '../../../environments/environment';
import { LettersInventoryService } from "./letter-inventory.service"
import { GlobalConstants } from '../constants/GlobalConstants';
import { GuardianService } from './abstracts/guardian.service';
import { AuthService } from './abstracts/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CpEntitlementService implements EntitlementService {
  sessionStorage: Storage;
  clientUrl: string;
  env: Environment;
  tinData: string[];
  functionKey: string;
  label: string;
  getCpAPiResponseKeyData: string;
  jwtTokenData;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly guardianService: GuardianService,
    private readonly authService: AuthService
  ) {
    this.clientUrl = environment.guardian.optumGuardianUrl;
    this.env = environment;
    this.sessionStorage = window.sessionStorage;
    this.tinData = [];
  }

  async retrieveToken(): Promise<string> {
    await this.authService.isAuthenticated();
    await this.authService.getKeyCloakResponse();
    const code = this.authService.getToken();
    return code;
  }

  async getClientsByTinValues(next: (res: ProviderClientData) => void, error: (error: Error) => void) {
      this.tinData = (await this.guardianService.getTinValues())?.tin || [];
      this.jwtTokenData = await this.retrieveToken();
      this.httpClient.post<ProviderClientData>(`${this.env.lettersInventory.baseUrl}${this.env.lettersInventory.endpointGetClients}`,
        {
          "tins": this.tinData,
          "letterType": GlobalConstants.LETTER_TYPE_MRR
        }, {
        headers: {
          "Authorization": `Bearer ${this.jwtTokenData}`
        }
      })
        .pipe(
          map(clientData => {
            clientData.result.data.clientList.sort((clientA, clientB) => clientA.clientName > clientB.clientName ? 1 : -1);
            return clientData;
          }),
          catchError((error) => {
            return throwError(error)
          })
        ).subscribe({
          next,
          error
        })
  }

  getToken(): string {
    return this.jwtTokenData;
  }
}