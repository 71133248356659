import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../shared/services/abstracts/auth.service';
import { ClientService } from '../../shared/services/client.service';
import { EntitlementService } from '../../shared/services/abstracts/entitlement.service';
import Client from '../../shared/models/Client';
import ClientOptionsList from '../../shared/models/ClientOptionsList';
import ProviderClientData from '../../shared/models/ProviderClientData';
import { generateClientListOptions } from '../../shared/helpers/DropdownOptionsHelper';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss']
})

export class ClientSelectionComponent implements OnInit {
  clientList: Client[];
  clientOptionsList: ClientOptionsList[];
  userName: string;
  greetingText: string;
  clientSelectLabel: string;
  viewClientButton: string;
  noClientsStatusMessage: string;
  hasMultipleClients: boolean;
  selectedClientOption: string;
  isLoading: boolean;
  clientsLoadingMessage: string;
  subscription: Subscription;
  isClientListEmpty: boolean;
  timeOutDuration: number;
  isLoggedIn: boolean;
  spinnerSize: string;

  clientSelectForm = new FormGroup({
    clientDropdownControl: new FormControl('', Validators.required)
  });

  constructor(
    private readonly entitlementService: EntitlementService,
    private readonly clientService: ClientService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
    this.hasMultipleClients = false;
    this.isLoading = false;
    this.clientsLoadingMessage = GlobalConstants.CLIENTS_LOADING_MESSAGE;
    this.greetingText = GlobalConstants.GREETING_TEXT;
    this.clientSelectLabel = GlobalConstants.CLIENT_SELECT_LABEL;
    this.viewClientButton = GlobalConstants.VIEW_CLIENT_BUTTON_TEXT;
    this.noClientsStatusMessage = GlobalConstants.NO_CLIENTS_STATUS_MESSAGE;
    this.clientOptionsList = [];
    this.userName = '';
    this.selectedClientOption = '';
    this.isClientListEmpty = false;
    this.timeOutDuration = environment.timeOutDuration;
    this.spinnerSize = GlobalConstants.SPINNER_SIZE_LARGE;
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.isLoggedIn = await this.authService.isAuthenticated();
    await this.authService.getKeyCloakResponse();
    this.isLoading = true;
    const userProfileData: KeycloakProfile = this.authService.getUserProfile();
    if  (userProfileData && userProfileData.firstName) {
      this.setUserName(userProfileData);
      this.getClientData();
    } else {
      this.router.navigate(['unauthorized']);
    }
  }
  
  setUserName(userProfile): void {
    this.userName = `${userProfile.firstName} ${userProfile.lastName}`;
  }

  async getClientData() {
    try {
      const res: ProviderClientData = await new Promise((resolve, reject) => {
        this.entitlementService.getClientsByTinValues(resolve, reject);
      });
      this.isLoading = true;
      this.getResponse(res);
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  onClientSelectChange(event): void {
    this.selectedClientOption = event.value;
  }

  selectClient(client): void {
    this.clientService.setCurrentClient(client.value);
    this.router.navigate(['registered/home']);
  }

  logout(): void {
    this.authService.logout();
  }

  private getResponse(res: ProviderClientData): void {
    if (res && res.result && res.result.data && res.result.data.clientList) {
      this.clientList = res.result.data.clientList;
      this.isLoading = false;
      this.hasMultipleClients = this.clientList && this.clientList.length > 0;
      this.isClientListEmpty = this.clientList && this.clientList.length === 0;
      if (this.isClientListEmpty && this.isLoggedIn) {
        setTimeout(() => this.logout(), this.timeOutDuration);
      } 
      if (this.clientList && this.clientList.length === 1) {
        const dropDownDataEntry = {
          value: this.clientList[0]
        };
        this.selectClient(dropDownDataEntry);
      } else {
        this.clientSelectForm.get('clientDropdownControl').setValue('');
        this.clientOptionsList = generateClientListOptions(res.result.data.clientList);
      }
    }
  }
}