import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../shared/services/abstracts/auth.service';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})

export class UnauthorizedComponent implements OnInit {
  isLoggedIn: boolean;
  accessDeniedStartText: string;
  accessDeniedEndText: string;
  timeOutDuration: number;

  constructor(private readonly authService: AuthService) {
    this.accessDeniedStartText = GlobalConstants.ACCESS_DENIED_START_TEXT;
    this.accessDeniedEndText = GlobalConstants.ACCESS_DENIED_END_TEXT;
    this.timeOutDuration = environment.timeOutDuration;
    this.isLoggedIn = false;
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.isLoggedIn = true;
      setTimeout(() => this.logout(), this.timeOutDuration);
    }
  }

  logout(): void {
    this.authService.logout();
  }
}