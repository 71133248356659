<div style = "padding: 2%;">
    <h2>Frequently Asked Questions</h2>
    <uitk-panels [headingLevel]="headingLevel.h2">
        <uitk-panel uitkId="TestPanel1" [collapsible]="collapsiblePanel1" [open]="openPanel1">
            <uitk-panel-header-title>
                <span uitkPanelTitleText>{{ panelHeader1 }}</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
                {{ panelContent1 }}
            </uitk-panel-content>
        </uitk-panel>

        <uitk-panel uitkId="TestPanel2" [collapsible]="collapsiblePanel2" [open]="openPanel2">
            <uitk-panel-header-title>
                <span uitkPanelTitleText>{{ panelHeader2 }}</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
                {{ panelContent2 }}
            </uitk-panel-content>
        </uitk-panel>

        <uitk-panel uitkId="TestPanelDetail" [collapsible]="collapsiblePanel3" [open]="openPanel3">
            <uitk-panel-header-title>
                <span uitkPanelTitleText>{{ panelHeader3 }}</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
                {{ panelContent3 }}
            </uitk-panel-content>
        </uitk-panel>

        <uitk-panel uitkId="TestPanel1" [collapsible]="collapsiblePanel4" [open]="openPanel4">
            <uitk-panel-header-title>
                <span uitkPanelTitleText>{{ panelHeader4 }}</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
                {{ panelContent4 }}
            </uitk-panel-content>
        </uitk-panel>

        <uitk-panel uitkId="TestPanel2" [collapsible]="collapsiblePanel5" [open]="openPanel5">
            <uitk-panel-header-title>
                <span uitkPanelTitleText>{{ panelHeader5 }}</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
                {{ panelContent5 }}
            </uitk-panel-content>
        </uitk-panel>

        <uitk-panel uitkId="TestPanelDetail" [collapsible]="collapsiblePanel6" [open]="openPanel6">
            <uitk-panel-header-title>
                <span uitkPanelTitleText>{{ panelHeader6 }}</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
                {{ panelContent6 }}
            </uitk-panel-content>
        </uitk-panel>


        <uitk-panel uitkId="TestPanelDetail" [collapsible]="collapsiblePanel7" [open]="openPanel7">
            <uitk-panel-header-title>
                <span uitkPanelTitleText>{{ panelHeader7 }}</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
                {{ panelContent7 }}
            </uitk-panel-content>
        </uitk-panel>

    </uitk-panels>
</div>
