import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationClient } from '@azure/app-configuration';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfigClient: AppConfigurationClient;
  private connectionString: string;
  private app_Text: string;
  authUrl: string;

  constructor(private http: HttpClient) {
    this.connectionString = '';
    this.app_Text = '';
    this.authUrl = `${environment.guardian.optumGuardianUrl}`;
  }

  async fetchConnectionString(): Promise<{ endpoint: string, publishData: string }> {
    try {
      const response = await this.http.get(`${this.authUrl}/getAppConfig`).toPromise();
      const responseString = response.toString();
  
      const endpointMatch = responseString.match(/Endpoint=([^;]+)/);
      const idMatch = responseString.match(/Id=([^;]+)/);
      const secretMatch = responseString.match(/Secret=([^;]+)(?:;|Key=|$)/);
  
      if (endpointMatch && idMatch && secretMatch) {
        const endpoint = endpointMatch[1];
        const id = idMatch[1];
        const secret = secretMatch[1].replace(/Key=([^;]+)/, '');
  
        const keyMatch = responseString.match(/Key=([^;]+)/);
        const publishData = keyMatch ? keyMatch[1] : '';
  
        const connectionString = `Endpoint=${endpoint};Id=${id};Secret=${secret}`;
        return { endpoint: connectionString, publishData: publishData };
      } else {
        throw new Error('Invalid connection string format: Missing required components');
      }
    } catch (error) {
      console.error('Error fetching connection string:', error);
      throw error;
    }
  }

  private async initialize(): Promise<void> {
    try {
      const connectionInfo = await this.fetchConnectionString();
      this.connectionString = connectionInfo.endpoint;
      this.app_Text = connectionInfo.publishData;
      this.appConfigClient = new AppConfigurationClient(this.connectionString);
    } catch (error) {
      console.error('Error during initialization:', error);
      throw error;
    }
  }

  async getConfiguration(keyValue: string, labelValue: string): Promise<string> {
    if (!this.appConfigClient) {
      await this.initialize();
    }
    const getResponse = await this.appConfigClient.getConfigurationSetting({ key: keyValue, label: labelValue });
    return getResponse.value;
  }

  async getPublishData(): Promise<string> {
    try {
      if (!this.app_Text) {
        const { publishData } = await this.fetchConnectionString();
        this.app_Text = publishData;
      }
      return this.app_Text;
    } catch (error) {
      console.error('Error fetching publishData:', error);
      throw error;
    }
  }
}