import { Component } from '@angular/core';

import { AuthService } from '../../shared/services/abstracts/auth.service';
import { GlobalConstants } from '../../shared/constants/GlobalConstants';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})

export class LogoutComponent {
  loggedoutText: string;

  constructor(private readonly authService: AuthService) {
    this.loggedoutText = GlobalConstants.LOGGEDOUT_TEXT;
  }
}