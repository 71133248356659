<div class = "recordUploadMainContainer">
    <h2>Record Upload Service</h2>
    <div class = "uitk-l-grid">
        <div class = "uitk-l-grid__row uitk-l-grid__row--no-gutter uitk-u-flex__align--center" *ngIf = "fileUploadLoading; else form">
            <uitk-loading-indicator
                uitkId="spinner"
                [visibleText]="visibleLoadingText"
                spinnerSize="large"
                >
            </uitk-loading-indicator>
        </div>
    </div>

    <ng-template #form>
    <span>You have received a letter requesting specific document(s).</span><span> Please enter the information below which can be found in your letter, then attach the requested document(s).</span><br/>
    <span>Please note, upload document(s) for only one claim at a time.</span><br/>
    <span>If you have any questions, please call the phone number on the letter you received.</span><br/><br/>
    <div>
        <form [formGroup]="inputForm">
            <div class = "formContainer">
                <uitk-form-field>
                    <label uitkLabel>{{ authorizationLabelText }}</label>
                    <uitk-form-field-hint>
                        {{ authCodeHelperText }}
                    </uitk-form-field-hint>
                    <input id="my-required-input-reactive" uitkInput required formControlName="authorizationCode" />
                    <uitk-form-field-error
                        *ngIf="
                            inputForm?.controls?.authorizationCode?.errors?.required &&
                            inputForm?.controls?.authorizationCode?.touched
                        "
                    >
                        {{ authorizationCodeRequiredText }}
                    </uitk-form-field-error>
                </uitk-form-field>
            </div><br/>

            <div class = "formContainer">
                <uitk-form-field>
                    <label uitkLabel>{{ recordBarCodeLabelText }}</label>
                    <uitk-form-field-hint>
                        {{ recordBarCodeHelperText }}
                    </uitk-form-field-hint>
                    <input id="my-required-input-reactive" uitkInput formControlName="memberLetterBarCode" required />
                    <uitk-form-field-error
                        *ngIf="
                            inputForm?.controls?.memberLetterBarCode?.errors?.required &&
                            inputForm?.controls?.memberLetterBarCode?.touched
                        "
                    >
                        {{ recordBarCodeRequiredText }}
                    </uitk-form-field-error>
                </uitk-form-field>
            </div><br/>

            <div class = "formContainer">
                <uitk-form-field>
                    <label uitkLabel>{{ firstDateOfServiceLabelText }}</label>
                    <uitk-form-field-hint> </uitk-form-field-hint>
                    <uitk-date-picker
                        id="my-required-date-picker"
                        formControlName="startDateOfService"
                        [required]="true"
                    ></uitk-date-picker>
                    <uitk-form-field-error
                        *ngIf="
                            inputForm?.controls?.startDateOfService?.errors?.required &&
                            inputForm?.controls?.startDateOfService?.touched
                        "
                    >
                        {{ firstDateOfServiceRequiredText }}
                    </uitk-form-field-error>
                    <uitk-form-field-error
                        *ngIf="
                            inputForm?.controls?.startDateOfService?.errors?.invalidFormat &&
                            inputForm?.controls?.startDateOfService?.touched
                        "
                    >
                        {{ invalidText }}
                    </uitk-form-field-error>
                </uitk-form-field>
            </div><br/>

            <uitk-form-field>
                <label uitkLabel>Upload File</label>
                <uitk-form-field-hint><br/><span>Please do not upload password protected documents.</span><br/>
                <span><strong>Note:</strong> If submitting a ZIP file the documentation within the zip must be TIFF, TIF, PDF, JPG, JPEG.</span>
                </uitk-form-field-hint>
                <uitk-file-upload
                    #uitkFileUpload = "uitkFileUpload"
                    required
                    formControlName="file"
                    uploadBtnText="Submit"
                    [id]="'required'"
                    [url]="url"
                    [fileLimit]="1"
                    [fileMaxSize]="200000"
                    [acceptedTypes]="acceptedTypesList"
                    [isUploadOnSelect]="false"
                    (onUpload)="uploadRecord()"
                    hideUploadButton="true"
                ></uitk-file-upload>
                <uitk-form-field-error
                *ngIf="
                    inputForm?.controls?.file?.errors?.required &&
                    inputForm?.controls?.file?.touched
                "
                    >Required
                </uitk-form-field-error>
                <uitk-form-field-error
                    *ngIf="
                        inputForm?.controls?.file?.errors?.invalidFiles &&
                        !inputForm?.controls?.file?.errors?.fileLimitExceeded
                    "
                    ><span *uitkFileUploadErrorContext="let errorContext"
                        >{{ errorContext.invalidFilesCount }} of {{ errorContext.totalFiles }} file(s) have
                        errors. See the list of files for error details</span
                    ></uitk-form-field-error
                >
                <uitk-form-field-error
                    *ngIf="
                        inputForm?.controls?.file?.errors?.fileLimitExceeded &&
                        !inputForm?.controls?.file?.errors?.invalidFiles
                    "
                    ><span>Too many files selected. Remove files to upload</span></uitk-form-field-error
                >
                <uitk-form-field-error
                    *ngIf="
                        inputForm?.controls?.file?.errors?.fileLimitExceeded &&
                        inputForm?.controls?.file?.errors?.invalidFiles
                    "
                    ><span *uitkFileUploadErrorContext="let errorContext"
                        >Only 1 file can be uploaded. {{ errorContext.invalidFilesCount }} invalid files
                        selected. Review and remove files to upload</span
                    ></uitk-form-field-error
                >
                <uitk-form-field-error
                    *ngIf="
                        inputForm?.controls?.file?.errors?.noFilesSelected &&
                        inputForm?.controls?.file?.touched
                    "
                    >No file selected
                </uitk-form-field-error>
            </uitk-form-field>
            <uitk-notification
                class="uitk-u-margin-bottom--lg"
                uitk-id="notificationVariant"
                [variant]="notificationVariant"
                [show]="showNotification"
                [displayButton]="true"
                [buttonText]="buttonText"
                (onButtonClick)="closeNotification()"
                >
                <span>{{ notificationMessage }}
                </span>
            </uitk-notification>
            <button
                id="default-btn"
                type="submit"
                [disabled] ="inputForm?.controls.startDateOfService?.status === 'INVALID' ||
                            inputForm?.controls.authorizationCode?.status === 'INVALID' ||
                            inputForm?.controls.memberLetterBarCode?.status === 'INVALID' ||
                            inputForm?.controls.file?.errors?.required ||
                            inputForm?.controls.file?.value?.length === 0 ||
                            inputForm?.controls.file?.errors?.fileLimitExceeded ||
                            inputForm?.controls.file?.errors?.invalidFiles"
                class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                (click)="uploadRecord()"
            >
                Submit
            </button>
        </form>
    </div>
    </ng-template>
    <div>
    </div>
</div>
