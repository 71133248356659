<div class="uitk-l-grid doc-info-files-container">
  <uitk-dialog class="doc-info-dialog-box" [(showModal)]="isDocInfoDialogVisible" uitkId="doc_info_Dialog"
      triggerElementId="doc_info_Dialog_openBtn" [(closeBtn)]="isCloseEnabled">
    <uitk-dialog-header class="doc-info-header">
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs close-button" (click)="closeDialogInfo()">
        {{closeButtonText}}
      </button>
      <span class="uitk-icon uitk-icon-download lg-icon" aria-hidden="true"></span>
        {{ documentDetailsText }}
    </uitk-dialog-header>
    <uitk-dialog-content>
      <ng-container *ngIf="!isLoading else loading">
        <div>
          <p><strong>{{ patientAccountNumberText }}</strong>{{ selectedClaim?.patientAccountNumber }}</p>
          <p><strong>{{ patientName }}</strong>{{ selectedClaim?.recipientFirstName }} {{ selectedClaim?.recipientLastName }}</p>
          <p><strong>{{ claimNumberText }}</strong>{{ selectedClaim?.claimNumber }}</p>
        </div>
        <div class="doc-info-container" *ngIf="hasDocuments">
          <div class="doc_info__table">
            <div class="uitk-c-table__caption">
              <h3>{{ documentsText }}</h3>
            </div>
            <div class="uitk-c-table--responsive">
              <table uitkTable id="basic-table" class="uitk-c-table" aria-labelledby="table-paginate-example"
                  aria-describedby="row-data">
                <thead class="uitk-c-table__head">
                  <tr>
                    <th *ngFor="let headerName of tableHeader" scope="col"
                      [ngClass]="headerName.className ? headerName.className : ''">
                        {{ headerName.name}}
                    </th>
                  </tr>
                </thead>
                <tbody id="row-data" class="uitk-c-table__body">
                  <tr *ngFor="let item of tableDetail" class="uitk-c-table__body">
                    <td>{{ item.documentClassifier }}</td>
                    <td>{{ item.documentName }}</td>
                    <td>{{ item.documentAddedDate }}</td>
                    <td class= "download-column"><span class="uitk-icon uitk-icon-document_download md-icon" 
                      (click)="downloadFile(item)"></span></td>
                  </tr>
                  <tr>
                    <th id="downloadBoxId" colspan="5" class="download-box" *ngIf="!closeDownloadBox && !isErrorStatus">
                      <div class="padding-box">                    
                        <h4 class="name" *ngIf="!closeDownloadBox" >
                          <span class = "uitk-icon uitk-icon-document lg-icon" *ngIf="onDownloadClick"  aria-hidden="true"></span>
                          {{ selectedFileName }}
                        </h4>
                        <div class="requestTypeDownloading" >
                          <span *ngIf="!isFileDownloaded" >{{ downloadInProgressMessage }}</span>
                          <span *ngIf="isFileDownloaded" >{{ downloadedString }}</span>
                          <span *ngIf="isFileDownloaded"> {{ sizeText }} {{ this.fileSize }}</span>
                        </div>
                      </div>
                    </th>
                    <th id="errorBoxId" colspan="5" class="error-box" *ngIf="onDownloadClick && errorBoxVisible">
                      <p class="requestTypeError">
                        <span>{{ errorMessage }}</span>
                        <span>{{ selectedFileName }}</span>
                      </p>
                    </th> 
                  </tr>
 
                </tbody> 
              </table>
              <div class="largeFile" *ngIf="isFileLarge && !isFileDownloaded && !errorBoxVisible">
                <span>{{ largeFileDownloadMessage }}</span>
              </div> 
            </div>
          </div>
        </div>
        <div *ngIf="!hasDocuments" class="uitk-l-grid__col centered__shadowed">
          <legend>{{ NoDocMessage }}</legend>
        </div>
      </ng-container>
      <ng-template #loading>
        <app-loading-spinner [loadingMessage]="documentLoadingMessage" [spinnerSize]="spinnerSize"></app-loading-spinner>
      </ng-template>
    </uitk-dialog-content>
  </uitk-dialog>
</div>