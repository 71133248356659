import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

import Client from '../models/Client';
import { GlobalConstants } from '../constants/GlobalConstants';

@Injectable({
  providedIn: 'root'
})

export class ClientService {
  private readonly currentClientSubject = new BehaviorSubject<Client>(null);
  currentClientData$ = this.currentClientSubject.asObservable().pipe(
    shareReplay(1)
  );

  sessionStorage: Storage;
  clientKey: string;

  constructor() {
    this.sessionStorage = window.sessionStorage;
    this.clientKey = GlobalConstants.CLIENT_KEY;
  }

  setCurrentClient(client: Client): void {
    const modClient = this.enrichClientsDetails(client);
    this.sessionStorage.setItem(this.clientKey, JSON.stringify(modClient));
    this.currentClientSubject.next(modClient);
  }

  getCurrentClient(): Client {
    return this.sessionStorage.getItem(this.clientKey)
      ? JSON.parse(this.sessionStorage.getItem(this.clientKey))
      : null;
  }

  private enrichClientsDetails(client: Client): Client {
    // eslint-disable-next-line no-param-reassign
    client = {
      ...client,
      clientLogo: `assets/images/client-logos/${client.clientId}.png`
    };
    return client;
  }
}