import { Injectable } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Router } from '@angular/router';
import { AuthService } from './abstracts/auth.service';
import Environment from '../models/Environment';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import { AppConfigService } from './app-config.service';

@Injectable({ providedIn: 'root' })
export class HttpAuthService extends AuthService {
  env: Environment;
  sessionStorage: Storage;
  userDataKey: string;
  token: string;
  isSentinelLoggedIn: boolean;

  constructor(private readonly keycloakService: KeycloakService, private readonly router: Router, private readonly appConfigService: AppConfigService) {
    super();
    this.sessionStorage = window.sessionStorage;
    this.env = environment;
    this.userDataKey = GlobalConstants.USER_PROFILE_DATA_KEY;
    this.isSentinelLoggedIn = false;
  }

  logout(logoutAndNotRedirect: boolean = false): void {
    this.clearSession();
    if (this.isSentinelLoggedIn === true) {
      if (logoutAndNotRedirect === false) {
        this.redirectTo(environment.sentinelLogOutUrl);
      } else {
        this.router.navigate(['logout']);
        setTimeout(() => {
          this.redirectTo(environment.sentinelLogOutUrl);
        }, 5000);
      }
    }
  }

  redirectTo(url: string): void {
    window.location.href = url;
  }

  /**
   * Checks if user is authenticated through sentinel.
   * @returns Promise<boolean>
   */
  public async isAuthenticated(): Promise<boolean> {
    this.isSentinelLoggedIn = await this.keycloakService.isLoggedIn();
    return await this.keycloakService.isLoggedIn();
  }

  public async getKeyCloakResponse(): Promise<void> {
    const response = await Promise.all([
      this.keycloakService.loadUserProfile(),
      this.keycloakService.getToken()
    ]).catch(error => error);
    this.token = response[1];
    this.setUserProfile(response[0]);
}

  private setUserProfile(userProfile: KeycloakProfile): void {
    if (userProfile && userProfile.firstName) {
      this.sessionStorage.setItem(this.userDataKey, JSON.stringify(userProfile).toString());
    }
  }

  public getToken(): string {
    return this.token ? this.token : null;
  }

  /**
   * Ensures that the user is authenticated and that keycloak is setup
   * before trying to get the users access token.
   * @returns Promise<string>
   */
  async getUserToken() {
    await this.isAuthenticated();
    await this.getKeyCloakResponse();

    return this.getToken();
  }

  public getUserProfile(): KeycloakProfile {
    return this.sessionStorage.getItem(this.userDataKey)
    ?  JSON.parse(this.sessionStorage.getItem(this.userDataKey).toString())
    : null;
  }

  clearSession(): void {
    this.sessionStorage.clear();
  }
}